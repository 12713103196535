









































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import Modal from './BaseModal.vue';
import ApiClient from '../services/ApiClient';
import common from '../services/common';
import { VueLoading } from 'vue-loading-template';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T,  R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T,  R>(val: T): ChainedWhen<T,  R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T,  A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T,  A>(fn()) : chain<T,  A>(val),
});
// when使用のための処理 ------ end

export default {
  title: 'カードリーダー一覧',
  name: 'cardReader',
  components: {
    VueGoodTable,
    Modal,
    VueLoading,
  },
  data() {
    return {
      deviceIdOptions: [],
      deviceIdCondition: [],
      companyNameOptions: [],
      companyNameCondition: [],
      deleteModal: false,
      bulkDeleteModal: false,
      getLodingFlg: false,
      deleteLodingFlg: false,
      getErrorFlg: false,
      deleteErrorFlg: false,
      csvDlFlg: false,
      errorMessage: '',
      columns: [
        {
          label: '内部ID',
          field: 'id',
          hidden: true,
        },
        {
          label: '機器ID',
          field: 'deviceId',
          sortable: true,
          width: '125px',
        },
        {
          label: '事業者',
          field: 'companyName',
          sortable: true,
          width: '200px',
        },
        {
          label: '事業者ID',
          field: 'companyId',
          hidden: true,
        },
        {
          label: '現場',
          field: 'siteName',
          sortable: true,
        },
        {
          label: '現場ID',
          field: 'ccusSiteId',
          hidden: true,
        },
        {
          label: 'モードID',
          field: 'modeId',
          hidden: true,
        },
        {
          label: 'モード',
          field: 'modeName',
          sortable: true,
          width: '80px',
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
          width: '145px',
        },
      ],
      conditionRow: [],
      // 選択行のデータを詰める
      selectRow: {},
      // チェックボックス選択行データを詰める
      deleteSelectRows: [],
      displayingModal: 0,
      // 削除カードリーダーテーブル列
      deleteCardReaderColumns: [
        {
          label: '機器ID',
          field: 'deviceId',
        },
        {
          label: '現場',
          field: 'siteName',
          sortable: true,
        },
      ],
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // CSV取得上限
      csvLimit: 10000,
    };
  },
  // 初期処理
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // カードリーダー一覧取得API呼出
      this.rows = await this.callGetCardReadersList();
      // 初期テーブルデータをセット
      this.conditionRow = this.rows;
      // 事業者リスト取得API呼出
      this.companyNameOptions = await this.callGetCompaniesNameList();
      // 検索条件を取得
      this.deviceIdCondition = common.getUrlParam('deviceId', 0);
      this.companyNameCondition = common.getUrlParam('companyName', 0);
      // 検索処理呼出
      this.search();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // カードリーダー一覧取得API呼び出し
    async callGetCardReadersList() {
      try {
        const restResourceService = new ApiClient();
        const resultRows = await restResourceService.getCardReadersList();
        return resultRows;
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 事業者リスト取得API呼び出し
    async callGetCompaniesNameList() {
      try {
        switch (this.role) {
          case 'admin':
            const restResourceService = new ApiClient();
            return await restResourceService.getCompaniesNameList();
            break;
          case 'business':
            break;
          case 'branch':
            break;
          case 'worker':
            break;
          default:
            break;
        }
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 検索条件：機器ID追加
    addDeviceIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.deviceIdCondition.push(tag);
      this.deviceIdOptions.push(tag);
    },
    // 検索条件：事業者追加
    addBusinessTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.companyNameCondition.push(tag);
      this.companyNameOptions.push(tag);
    },
    // 権限によって表示列を非表示にする処理
    roleCheck(val) {
      // 運営管理者の場合は全て表示
      // computedの変数はこの時点では参照できないため、gettersで直接参照する
      if (this.$store.getters.session.role === 'admin') {
        return false;
      // 事業者・技能者の場合
      } else {
        if (val <= 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    // 検索ボタン押下時
    search() {
      this.setCondition(this.rows);
      // 検索条件をURLに詰める
      common.setUrlParam('deviceId', this.deviceIdCondition, 0);
      common.setUrlParam('companyName', this.companyNameCondition, 0);
      // テーブルが空の場合CSVダウンロードを非活性
      this.csvDlFlg  = (this.conditionRow.length > 0) ? true : false;
    },
    // 一覧上部の検索条件を一覧へ反映
    setCondition(rows) {
      // 検索条件がない場合、初期データを書き戻す
      if (this.deviceIdCondition.length === 0 && this.companyNameCondition.length === 0) {
        this.conditionRow = rows;
        return;
      }
      let newRows = [];
      let tempRows = rows;
      this.conditionRow = [];
      // 検索条件：機器ID(部分一致)
      if (this.deviceIdCondition.length !== 0) {
        newRows = [];
        // 検索用タグ配列分ループ
        this.deviceIdCondition.forEach(
          (deviceId) => {
            // 表示中のテーブル列の中からタグ
            const tempRow = tempRows.filter((v) =>
              v.deviceId.match(deviceId.name),
            );
            if (tempRow.length !== 0) {
              Array.prototype.push.apply(newRows, tempRow);
            }
          },
        );
        // 重複チェック
        this.conditionRow = Array.from(
          new Map(newRows.map((row) => [row.id, row])).values()
        );
        tempRows = this.conditionRow;
      }
      // 検索条件：事業者（選択）
      if (this.companyNameCondition.length !== 0) {
        // 検索条件に合うレコードを取得
        this.conditionRow = common.searchConditionName(this.companyNameCondition, tempRows, 'companyName');
        tempRows = this.conditionRow;
      }
    },
    // カードリーダー新規登録画面へ遷移
    add() {
      this.$router.push('/cardReader/add');
    },
    // カードリーダー一括登録画面へ遷移
    bulkAdd() {
      this.$router.push('/cardReader/bulkAdd');
    },
    // カードリーダー編集画面へ遷移
    edit(params) {
      this.$router.push({
        name: 'CardReaderEdit',
        params: {
          id: params,
        },
      });
    },
    // 削除実行
    async deleteExecution(value) {
      try {
        // ローディング表示
        this.getLodingFlg = true;
        // エラーフラグ初期化
        this.deleteErrorFlg = false;
        // カードリーダ削除API呼び出し
        const restResourceService = new ApiClient();
        await restResourceService.deleteCardReader(value);
        // カードリーダ一覧再取得
        this.rows = await this.callGetCardReadersList();
        this.conditionRow = this.rows;
      } catch (error) {
        this.errorMessage = error.message;
        this.deleteErrorFlg = true;
      } finally {
        // 削除モーダルを閉じる
        this.closeModal(0);
        // ローディング非表示
        this.getLodingFlg = false;
      }
    },
    // モーダルを開く
    openModal(num, params) {
      window.history.pushState(null, null, null);
      if (num === 0) {
        this.displayingModal = 0;
        window.addEventListener('popstate', this.modalBack);
        this.selectRow = params;
        this.deleteModal = true; // 削除
      } else if (num === 1) {
        this.displayingModal = 1;
        window.addEventListener('popstate', this.modalBack);
        this.bulkDeleteModal = true; // 一括削除
      }
    },
    // モーダルを閉じる
    closeModal(num) {
      window.removeEventListener('popstate', this.modalBack);
      this.deleteModal = false;
      when(num)
        .on((v) => v === 0, () => this.deleteModal = false)        // 詳細
        .on((v) => v === 1, () => this.bulkDeleteModal = false)    // 一括削除
    },
    // モーダル表示中ブラウザバック処理
    modalBack() {
      this.closeModal(this.displayingModal);
    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      const columns = this.columns;
      const rows = this.conditionRow;
      // ファイル名用の現在日時
      const currentDate = new Date().getTime();
      const date = await common.getToday(currentDate);
      const fileName = 'カードリーダー一覧'+date;
      common.outputCsv(columns, rows, fileName);
    },
    // チェックボックス選択行取得
    selectionChanged(checkRows) {
      this.deleteSelectRows = checkRows.selectedRows;
    },
    // チェックボックス選択行を一括削除
    async bulkDelete() {
      try {
        // ローディング表示
        this.deleteLodingFlg = true;
        // エラーフラグ初期化
        this.deleteErrorFlg = false;
        // チェックボックス選択行からIDを配列で取得する
        const cardReaders = common.getSelectRowIds(this.deleteSelectRows);
        if (cardReaders.length !== 0) {
          // カードリーダ削除API呼び出し
          const restResourceService = new ApiClient();
          await restResourceService.deleteCardReaderList(cardReaders);
          // カードリーダ一覧再取得
          this.rows = await this.callGetCardReadersList();
          this.conditionRow = this.rows;
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.deleteErrorFlg = true;
      } finally {
        // 削除モーダルを閉じる
        this.closeModal(1);
        // ローディング非表示
        this.deleteLodingFlg = false;
      }
    },
    // 現在ページ更新
    onPageChange(params) {
      this.currentPage = params.currentPage;
      common.scrollTop();
    },
  },
};
