export default {
  async translateMessage(code){
    let message = '';
    switch (code) {
      case "UserNotFoundException":
      message = "ユーザーが見つかりませんでした。";
      break;
      case "UsernameExistsException":
      message = "ユーザー名が既に存在しています。";
      break;
      case "CodeMismatchException":
      message = "コードが一致しませんでした。";
      break;
      case "ExpiredCodeException":
      message = "コードの有効期限が切れています。";
      break;
      case "InvalidParameterException":
      message = "パラメータが無効です。";
      break;
      case "NotAuthorizedException":
      message = "権限がありません。";
      break;
      case "PasswordResetRequiredException":
      message = "パスワードをリセットする必要があります。";
      break;
      case "UserNotConfirmedException":
      message = "ユーザーが確認されていません。";
      break;
      case "TooManyRequestsException":
      message = "リクエストが多すぎます。";
      break;
      case "InvalidPasswordException":
      message = "パスワードが無効です。";
      break;
      case "UserLambdaValidationException":
      message = "ユーザーバリデーションエラーが発生しました。";
      break;
      case "AliasExistsException":
      message = "エイリアスが既に存在しています。";
      break;
      case "InternalErrorException":
      message = "内部エラーが発生しました。";
      break;
      case "ResourceNotFoundException":
      message = "リソースが見つかりませんでした。";
      break;
      case "UserNotConfirmedException":
      message = "ユーザーが確認されていません。";
      break;
      case "UserNotFoundException":
      message = "ユーザーが見つかりませんでした。";
      break;
      case "InvalidParameterException":
      message = "パラメータが無効です。";
      break;
      case "LimitExceededException":
      message = "制限を超えました。";
      break;
      default:
      break;
    }
    return message;
  }
}
