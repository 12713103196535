












































































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import Modal from './BaseModal.vue';
import common from '../services/common';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

const defaultSettings = {
  placeholder: '',
  selectLabel: '',
  deselectLabel: '',
  selectedLabel: '',
  noDataLabel: '対象データがありません',
};

export default {
  title: '支店一覧',
  name: 'branch',
  components: {
    VueGoodTable,
    Modal,
    VueLoading,
  },
  data() {
    return {
      companyId: '',
      branchNameOptions: [],
      branchNameCondition: [],
      emailOptions: [],
      emailCondition: [],
      modal: false,
      getLodingFlg: false,
      getErrorFlg: false,
      deleteLodingFlg: false,
      deleteErrorFlg: false,
      csvDlFlg: false,
      errorMessage: '',
      columns: [
        {
          label: 'ID',
          field: 'branchId',
          sortable: false,
          hidden: true,
        },
        {
          label: '支店名',
          field: 'branchName',
          sortable: true,
          width: '250px',
        },
        {
          label: 'ログインID',
          field: 'loginId',
          sortable: true,
          width: '170px',
        },
        {
          label: 'メールアドレス',
          field: 'email',
          sortable: true,
          width: '250px',
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
          width: '145px',
        },
      ],
      rows: [],
      conditionRow: [],
      selectRow: {},
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // CSV取得上限
      csvLimit: 10000,
    };
  },
  // 初期処理
  async mounted() {
    // セッション情報からログイン事業者IDを取得
    this.companyId = this.$store.getters.session.companyId;
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // 支店一覧取得
      this.rows = await this.callGetBranches();
      // 初期データをセット
      this.conditionRow = this.rows;
      // テーブルが空の場合CSVダウンロードを非活性
      this.csvDlFlg  = (this.conditionRow.length > 0) ? true : false;
      // 支店名一覧取得
      this.branchNameOptions = await this.callGetBranchNameList();
      // URL検索条件確認
      this.branchNameCondition = common.getUrlParam('branchName', 0);
      this.emailCondition = common.getUrlParam('email', 0);
      // 検索処理呼出
      this.search();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 支店一括取得API呼出
    async callGetBranches() {
      try {
        const restResourceService = new ApiClient();
        const resultRows = await restResourceService.getBranches(this.companyId);
        return resultRows;
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // 支店名リスト取得API呼出
    async callGetBranchNameList() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getBranchesNameList(this.companyId);
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // Email検索条件追加
    addEmailTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.emailCondition.push(tag);
      this.emailOptions.push(tag);
    },
    // 検索ボタン押下時
    search() {
      // 検索条件でテーブルにフィルターをかける
      this.setCondition(this.rows);
      // 検索条件を取得
      common.setUrlParam('branchName', this.branchNameCondition, 0);
      common.setUrlParam('email', this.emailCondition, 0);
      // テーブルが空の場合CSVダウンロードを非活性
      this.csvDlFlg = (this.conditionRow.length > 0) ? true : false;
    },
    // 一覧上部の検索条件を一覧へ反映
    setCondition(rows) {
      if (this.branchNameCondition.length === 0 && this.emailCondition.length === 0) {
        // 検索条件がない場合、初期データを書き戻す
        this.conditionRow = rows;
        return;
      }
      let newRows = [];
      let tempRows = rows;
      this.conditionRow = [];

      // 検索条件：支店を検索
      if (this.branchNameCondition.length !== 0) {
        // 検索条件に合うレコードを取得
        this.conditionRow = common.searchConditionName(this.branchNameCondition, tempRows, 'branchName');
        tempRows = this.conditionRow;
      }

      // 検索条件：メールアドレスを検索(部分一致)
      newRows = [];
      this.emailCondition.forEach(
        (email) => {
          const tempRow = tempRows.filter((v) =>
            v.email.match(email.name),
          );
          if (tempRow.length !== 0) {
            Array.prototype.push.apply(newRows, tempRow);
          }
        },
      );
      if (newRows.length !== 0) {
        // Array.prototype.push.apply(this.conditionRow, newRows);
        // 重複チェック
        this.conditionRow = Array.from(
          new Map(newRows.map((row) => [row.branchId, row])).values()
        );
        tempRows = this.conditionRow;
      } else if (this.emailCondition.length !== 0) {
        this.conditionRow = [];
      }
    },
    // 支店新規登録画面へ遷移
    add() {
      this.$router.push('/companies-groups/add');
    },
    // 支店一括登録画面へ遷移
    bulkAdd() {
      this.$router.push('/companies-groups/bulkAdd');
    },
    // 支店編集画面へ遷移
    edit(params) {
      this.$router.push({
        path: '/companies-groups/edit',
        name: 'BranchEdit',
        params: {
          id: params.branchId,
          name: params.branchName,
        },
      });
    },
    // 削除ボタン押下時処理
    async deleteExecution() {
      try {
        this.deleteErrorFlg = false;
        this.deleteLodingFlg = true;
        const restResourceService = new ApiClient();
        // 支店削除API呼び出し
        await restResourceService.deleteBranch(this.selectRow.branchId);
        await this.$cognito.deleteUsers([this.selectRow.loginId]);
        // 支店一覧再取得
        this.rows = await this.callGetBranches();
        this.conditionRow = this.rows;
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.deleteErrorFlg = true;
      } finally {
        this.closeModal();
        this.deleteLodingFlg = false;
      }
    },
    // 削除モーダル表示
    openModal(params) {
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.closeModal);
      this.selectRow = params;
      this.modal = true;
    },
    // 削除モーダル非表示
    closeModal() {
      window.removeEventListener('popstate', this.closeModal);
      this.modal = false;
    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      const columns = this.columns;
      const rows = this.conditionRow;
      // ファイル名用の現在日時
      const currentDate = new Date().getTime();
      const date = await common.getToday(currentDate);
      const fileName = '支店一覧'+date;
      common.outputCsv(columns, rows, fileName);
    },
    // 現在ページ更新
    onPageChange(params) {
      this.currentPage = params.currentPage;
      common.scrollTop();
    },
  },
};
