export default {
  // 検証環境
  // AWSConfig: {
  //   Region: 'ap-northeast-1',
  //   UserPoolId: 'ap-northeast-1_nzqhinYOA',
  //   ClientId: '2k38m52301pi8d5uhdvvu453qn',
  //   IdentityPoolId: 'ap-northeast-1:d1778710-91b8-44ad-878d-b9ebf5d8d11b',
  // }
  // 認証試験環境
  // AWSConfig: {
  //   Region: 'ap-northeast-1',
  //   UserPoolId: 'ap-northeast-1_7t9qNOegi',
  //   ClientId: '225o1q8f8rr50552vgartbko27',
  //   IdentityPoolId: 'ap-northeast-1:8ee4765d-7a1c-4ef6-9b63-9487b7b021da',
  // }
  // 本番環境
  AWSConfig: {
    Region: 'ap-northeast-1',
    UserPoolId: 'ap-northeast-1_kEuZdPAXL',
    ClientId: '2rcbpvao93c8vos5i6hhgu5v1n',
    IdentityPoolId: 'ap-northeast-1:d3178db4-e92a-44c6-aa46-ae84db7c6a47',
  }
}
