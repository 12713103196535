import { render, staticRenderFns } from "./WorkerDetail.vue?vue&type=template&id=6211e34c&scoped=true&"
import script from "./WorkerDetail.vue?vue&type=script&lang=ts&"
export * from "./WorkerDetail.vue?vue&type=script&lang=ts&"
import style0 from "./WorkerDetail.vue?vue&type=style&index=0&id=6211e34c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6211e34c",
  null
  
)

export default component.exports