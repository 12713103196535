




















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { validate, ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueGoodTable } from 'vue-good-table';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T,   R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T,   R>(val: T): ChainedWhen<T,   R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T,   A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T,   A>(fn()) : chain<T,   A>(val),
});
// when使用のための処理 ------ end

export default {
  title: 'カードリーダー一括登録',
  name: 'CardReaderBulkAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    VueLoading,
  },
  // setup() {
  // return {
  // },
  // },
  data() {
    return {
      curdReaderslistPath: '/cardReader',                          // カードリーダー一覧パス
      templateFilePath: '/files/card-reader-bulk-template.csv', // テンプレートファイルパス
      templateFileName: 'card-reader-bulk-template.csv',           // テンプレートファイルダウンロード時のファイル名
      sumpleFilePath: '/files/card-reader-bulk-sample.csv',     // 記入サンプルファイルパス
      sumpleFileName: 'card-reader-bulk-sample.csv',               // 記入サンプルファイルダウンロード時のファイル名
      fileName: '',
      upFile: '',             // アップロードファイル
      csvData: {},            // ファイルから読み込んだCSVデータ
      selectedCompanies: '',  // 選択した事業者用
      optionCompanies: [],    // 選択用事業者配列
      errorMessages: [],       // エラーメッセージ配列
      columns:  [
        {
          label: '機器ID',
          field: 'deviceId',
        },
        {
          label: 'CCUS現場ID',
          field: 'ccusSiteId',
          // TODO suzuno formatFn: を置き、IDに一致する現場名を取得予定
        },
        {
          label: '事業者ID',
          field: 'companyId',
          // TODO suzuno formatFn: を置き、IDに一致する事業者名を取得予定
        },
        {
          label: '入退場モード',
          field: 'modeId',
          // TODO suzuno formatFn: を置き、IDに一致する入退場モード名を取得予定
        },
      ],
      rows: [],
      // ローディング
      getLodingFlg: false, // データ取得時ローディング用フラグ
      getErrorFlg: false, // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false, // データ登録時エラーフラグ
      parseFlg: false,
      errorMessage: '',
    };
  },
  // 初期処理
  async mounted() {
    try {
      this.getLodingFlg = true;
      // 事業者リスト取得API呼出
      this.optionCompanies = await this.callGetCompaniesList();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 事業者リスト取得API呼び出し
    async callGetCompaniesList() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getCompaniesList();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      }
    },
    // アップロードファイル取得
    loadCsvFile(event) {
      this.fileName = event.target.files[0].name;
      this.upFile = event.target.files[0];
      this.$refs.uploadFile.value = null;
      this.parseFlg = false;
    },
    // アップロードして確認ボタン押下時
    async csvExtract() {
      const errorMessages = new Array();
      let flg = false;
      try {
        // 抽出CSV配列とエラーメッセージ配列を空にする
        this.csvData.length = 0;
        if (!this.upFile) {
          errorMessages.push('ファイルを選択して下さい');
          return;
        }
        // 取り込んだCSVファイルをオブジェクト配列に変換
        this.$papa.parse(this.upFile, {
          encoding: 'Shift-JIS',
          header: true,
          complete: this.csvGet,
          error: function(err, file) {
            errorMessages.push('CSVの抽出に失敗しました。ファイルを再度選択して下さい');
            flg = true;
          }
        });
      } catch (error) {
        errorMessages.push('CSVの抽出に失敗しました。ファイル内のデータをご確認下さい');
      } finally {
        this.errorMessages = errorMessages;
        new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1);
        }).then(() => {
          this.parseFlg = flg;
          this.scrollBottom();
        });
      }
    },
    // CSVから抽出されたオブジェクト配列を加工
    async csvGet(results) {
      try {
        const errorMessages = new Array();
        // 事業者ID　completeの中では参照できないためローカルの変数に移す
        const selectedCompanies = this.selectedCompanies;
        const restResourceService = new ApiClient();
        this.csvData = results.data;
        if(this.csvData.length === 0) {
          errorMessages.push('登録データが存在しません。ファイル内のデータをご確認下さい');
        }
        for (let i = 0; i < this.csvData.length;  i++) {
          // 選択した事業者の事業者IDをプロパティとして追加
          this.csvData[i].companyId = selectedCompanies;
          // 必要情報が欠けた行が存在した場合、エラーメッセージを詰める

          let _deviceId = common.trimSpace(this.csvData[i].deviceId);
          await validate(_deviceId, 'required|deviceId:_deviceId|length:12', {
            name: '機器ID（deviceId）',
            values: {
              _deviceId
            }
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          let ccusSiteId = common.trimSpace(this.csvData[i].ccusSiteId);
          await validate(ccusSiteId, 'required|digits:14', {
            name: 'CCUS現場ID（ccusSiteId）',
            values: {
              ccusSiteId
            }
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          let mode = common.trimSpace(this.csvData[i].mode);
          await validate(mode, 'required', {
            name: '入退場モード（mode）',
            values: {
              mode
            }
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          // TODO suzuno 現場IDの存在チェック
          // const siteIdCheck = await restResourceService.getSite(this.csvData[i].ccusSiteId);
          // if (siteIdCheck) {
          // this.errorMessages.push(i + '行目 存在しないccusSiteIdです。');
          // break;
          // }

          // TODO suzuno 入退場モードリストをDBから取得して変換する
          when(this.csvData[i].mode)
            .on((v) => v === '入退場共用', () => this.csvData[i].modeId = '8cf6ed77-0703-cbc2-fbc0-1ed9234c8b3c')
            .on((v) => v === '入場専用', () => this.csvData[i].modeId = 'd0ba89f1-2b26-1efd-cca4-a542cf909756')
            .on((v) => v === '退場専用', () => this.csvData[i].modeId = '0b668acc-ebb8-674c-fd52-5c3f910bd841');

          // TODO suzuno 入退場モードリストをDBから取得してnameをエラーメッセージに詰める
          if (!this.csvData[i].modeId) {
            errorMessages.push((i + 1) + '行目 modeには「入退場共用」「入場専用」「退場専用」のいずれかを入力して下さい');
          }
          // 日本語部分の入退場フィールドは不要なため、削除
          delete this.csvData[i].mode;
        }
        // エラーメッセージが詰められていた場合、処理を中断する
        if (errorMessages.length !== 0) {
          this.errorMessages = errorMessages;
          this.scrollBottom();
          return;
        }
        this.rows = this.csvData;
        this.scrollBottom();
      } catch (error){
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      }
    },
    // DOM更新待機後スクロール
    async scrollBottom() {
      await this.$nextTick();
      common.scrollBottom();
    },
    // 登録ボタン押下時処理
    async confirm() {
      try {
        this.postLodingFlg = true;
        this.postErrorFlg = false;
        // カードリーダー一括登録API呼出
        const restResourceService = new ApiClient();
        // 一括登録実行
        await restResourceService.postCardReader(this.csvData);
        // カードリーダー一覧画面に戻る
        this.screenTransition(this.curdReaderslistPath);
      } catch (error) {
        this.errorMessage = error.message;
        this.postErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
        await this.$nextTick();
        common.scrollBottom();
      }
    },
    // ダウンロード処理（ファイルパス, DL時ファイル名）
    doDownload(url, filename) {
      common.doDownload(url, filename);
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
  },
};
