





























































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';
import Detail from './SiteDetail.vue';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T, R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T, R>(val: T): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T, A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T, A>(fn()) : chain<T, A>(val),
});
// when使用のための処理 ------ end

export default {
  title: '本日の入場者一覧',
  name: 'SiteAttendance',
  components: {
    VueGoodTable,
    Detail,
    VueLoading,
  },
  props: {
    siteName: {
      type: String,
      required: true,
    },
    ccusSiteId: {
      type: Number,
      required: true,
    },
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      pageTitle: '本日の入場者一覧',
      selectedTab: 2,
      today: this.setToday(),
      displayToday: this.setDisplayToday(),
      tab1Columns: [
        {
          label: 'ID',
          field: 'workerId',
          sortable: false,
          hidden: true,
        },
        {
          label: '作業員名',
          field: 'workerName',
          sortable: true,
        },
      ],
      tab2Columns: [
        {
          label: 'ID',
          field: 'workerId',
          sortable: false,
          hidden: true,
        },
        {
          label: '作業員名',
          field: 'workerName',
          sortable: true,
        },
        {
          label: '入場時刻',
          field: 'start',
          sortable: true,
        },
        {
          label: '退場時刻',
          field: 'end',
          sortable: true,
        },
      ],
      rows: [],
      inRows: [],
      todayRows: [],
      conditionRow: [],
      // 詳細モーダル用 選択行のデータを詰める
      detail: {
        workerId: '',
        workerName: '',
        start: '',
        end: '',
      },
      // 選択行のデータを詰める
      detailRow: {},
      // データ取得時ローディングフラグ
      getLodingFlg: false,
      // データ取得時エラーフラグ
      getErrorFlg: false,
      errorMessage: '',
    };
  },
  filters: {
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  // 初期処理
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // 本日の入場者一覧取得API呼出
      this.inRows = await this.callGetSiteIn(this.ccusSiteId);
      this.todayRows = await this.callGetTodayIntoList(this.ccusSiteId);
      this.rows = this.todayRows;
      // this.getTab(this.rows);
      this.conditionRow = this.rows;
      this.selectedTab = this.getUrlSelectTab('selectedTab');
      if (this.selectedTab = 1) {
        this.changeItem(1);
      }
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 現場入退場者一覧取得API呼出し
    async callGetTodayIntoList(ccusSiteId) {
      try {
        const restResourceService = new ApiClient();
        const resultRows = await restResourceService.getTodayIntoList(ccusSiteId);
        // エポックミリ秒をYYYY-MM-DDに変換
        resultRows.forEach((resultRow, index) => {
          resultRows[index].date = common.dateFormatHyphen(resultRow.date);
          resultRows[index].start = common.dateTimeFormatEpoch(resultRow.start);
          resultRows[index].end = common.dateTimeFormatEpoch(resultRow.end);
        });
        return resultRows;
      } catch (error) {
        throw error;
      }
    },
    // 現場入場者一覧取得API呼び出し
    async callGetSiteIn(ccusSiteId) {
      try {
        const restResourceService = new ApiClient();
        const resultRows = await restResourceService.getSiteIn(ccusSiteId);
        // エポックミリ秒をYYYY-MM-DDに変換
        resultRows.forEach((resultRow, index) => {
          resultRows[index].date = common.dateFormatHyphen(resultRow.date);
          resultRows[index].start = common.dateTimeFormatEpoch(resultRow.start);
        });
        return resultRows;
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 本日日付取得
    setToday() {
      return moment().format('YYYY-MM-DD');
    },
    setDisplayToday() {
      return moment().format('YYYY年MM月DD日');
    },
    getTab(rows) {
      // 現在選択されているタブによって表示する内容を変更する
      // when(this.selectedTab)
      //   .on((v) => v === 1,
      //     () =>
      //     // 入場時刻のあるデータを表示
      //     this.conditionRow = this.rows.filter((v) =>
      //       v.start
      //     ),
      //   )
      //   .on((v) => v === 2,
      //     () =>
      //     // 入場時刻のあるデータを表示
      //     this.conditionRow = this.rows.filter((v) =>
      //       v.end
      //     ),
      //   )
      //   .otherwise(
      //     () => this.conditionRow = this.row,
      //   );
    },
    // タブ切り替え時に呼び出される
    changeItem(val) {
      this.selectedTab = val;
      switch (val) {
        case 1:
          this.pageTitle = '入場者一覧';
          this.conditionRow = this.inRows;
          break;
        case 2:
          this.pageTitle = '本日の技能者一覧';
          this.conditionRow = this.todayRows;
          break;
      }
      this.setUrlSelectTab('selectedTab', this.selectedTab, 2);
      // this.getTab(this.conditionRow);
    },
    // URL内検索条件追加
    // name: 検索項目名  conditions: 条件
    setUrlSelectTab(name, conditions) {
      const url = new URL(location.href);
      // 検索条件がある場合、URLの検索パラメータに詰める
      if (conditions === 1) {
        url.searchParams.set(name, conditions);
      // 検索条件が無い場合、URLの検索パラメータから該当の値を削除
      } else {
        url.searchParams.delete(name);
      }
      // URLデコード
      url.search = decodeURIComponent(url.search);
      // URLを書き換える
      history.replaceState("", "", url.toString());
    },
    // URL内検索条件取得
    getUrlSelectTab(name) {
      const url = new URL(location.href);
      // URLに検索条件が追加されていた場合
      if(url.search !== '') {
        // URLから指定された検索項目の検索条件を取得
        const urlParam = Number(url.searchParams.get(name));
        if(urlParam === 0) {
          return 0;
        }
        if(urlParam === 2) {
          return 2;
        }
      }
      return 1;
    },
    // 現場一覧画面に戻る
    siteList() {
      history.back();
    },
  },
};
