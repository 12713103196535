























































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min, confirmed, regex, numeric } from 'vee-validate/dist/rules';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import CognitoMessages from "../cognito/cognitoMessages";

export default {
  title: 'パスワード再発行',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  props: {
    loginId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      code: '',
      newPassword: '',
      newPasswordConfirm: '',
      errorMessage: '',
      // ローディング
      putLodingFlg: false,  // データ更新時ローディングフラグ
      putErrorFlg: false,   // データ更新時エラーフラグ
      passwordChangeFlg: false, // パスワード変更済みフラグ
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 変更ボタン押下時処理
    async confirm() {
      try {
        this.putErrorFlg = false;
        this.putLodingFlg = true;
        // パスワード変更処理呼び出し
        await this.$cognito.resetPassword(this.loginId, this.newPassword, this.code);
        this.passwordChangeFlg = true;
      } catch (error) {
        this.errorMessage = await CognitoMessages.translateMessage(error.code);
        // TODO suzuno エラー横串時に共通cognitoエラーメッセージ返却関数に変更
        // switch (error.code) {
        //   case 'CodeMismatchException':
        //     this.errorMessage = '検証コードが正しくありません。メール内の検証コードを改めてご確認下さい';
        //     break;
        //   case 'LimitExceededException':
        //     this.errorMessage = '検証コードを複数回誤りました。時間を空けて再度ご入力下さい';
        //     break;
        //   case 'ExpiredCodeException':
        //     this.errorMessage = '検証コードの有効期限が切れています。ログイン画面からやり直して下さい';
        //     break;
        //   default:
        //     break;
        // }
        this.putErrorFlg = true;
      } finally {
        this.putLodingFlg = false;
      }
    },
    // ログイン画面へ遷移
    screenTransition() {
      this.$router.push('/');
    },
  },
};
