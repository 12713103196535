import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Signin from '../views/Signin.vue';

import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
// お知らせ管理
import Notification from '../views/Notification.vue';
import NotificationAdd from '../views/NotificationAdd.vue';
import NotificationEdit from '../views/NotificationEdit.vue';
// 支店管理
import Branch from '../views/Branch.vue';
import BranchAdd from '../views/BranchAdd.vue';
import BranchBulkAdd from '../views/BranchBulkAdd.vue';
import BranchEdit from '../views/BranchEdit.vue';
// 事業者管理
import Business from '../views/Business.vue';
import BusinessAdd from '../views/BusinessAdd.vue';
// 技能者管理
import Worker from '../views/Workers.vue';
import WorkerAdd from '../views/WorkerAdd.vue';
import WorkerBulkAdd from '../views/WorkersBulkAdd.vue';
import WorkerEdit from '../views/WorkerEdit.vue';
import WorkerDetail from '../views/WorkerDetail.vue';
// 現場管理
import Site from '../views/Sites.vue';
import SiteAdd from '../views/SitesAdd.vue';
import SiteBulkAdd from '../views/SitesBulkAdd.vue';
import SiteEdit from '../views/SiteEdit.vue';
import SiteDetail from '../views/SiteDetail.vue';
import SiteAttendance from '../views/SiteAttendance.vue';
// 入退場管理
import Entrance from '../views/Entrances.vue';
// アカウント管理
import Account from '../views/Account.vue';
import PasswordChange from '../views/PasswordChange.vue';
import PasswordForgot from '../views/PasswordForgot.vue';
import PasswordReset from '../views/PasswordReset.vue';
import LoginIdForgot from '../views/LoginIdForgot.vue';
// カードリーダー管理
import CardReader from '../views/CardReader.vue';
import CardReaderAdd from '../views/CardReaderAdd.vue';
import CardReaderBulkAdd from '../views/CardReaderBulkAdd.vue';
import CardReaderEdit from '../views/CardReaderEdit.vue';
// アクセスログ
import AccessLog from '../views/AccessLog.vue';
// ログイン履歴
import LoginLog from '../views/LoginLog.vue';
// 利用規約
import TeamsOfUse from '../views/TeamsOfUse.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  // cognito検証
  // {
  //   path: '/signin',
  //   name: 'Signin',
  //   component: Signin,
  // },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: Notification,
  },
  {
    path: '/notifications/add',
    name: 'NotificationAdd',
    component: NotificationAdd,
  },
  {
    path: '/notifications/edit/:id/:mode',
    name: 'NotificationEdit',
    component: NotificationEdit,
    props: true,
  },
  {
    path: '/cardReader',
    name: 'CardReader',
    component: CardReader,
  },
  {
    path: '/cardReader/add',
    name: 'CardReaderAdd',
    component: CardReaderAdd,
  },
  {
    path: '/cardReader/bulkAdd',
    name: 'CardReaderBulkAdd',
    component: CardReaderBulkAdd,
  },
  {
    path: '/cardReader/edit/:id',
    name: 'CardReaderEdit',
    component: CardReaderEdit,
    props: true,
  },
  {
    path: '/entrances',
    name: 'Entrance',
    component: Entrance,
  },
  {
    path: '/companies-groups',
    name: 'Branch',
    component: Branch,
  },
  {
    path: '/companies-groups/add',
    name: 'BranchAdd',
    component: BranchAdd,
  },
  {
    path: '/companies-groups/bulkAdd',
    name: 'BranchBulkAdd',
    component: BranchBulkAdd,
  },
  {
    path: '/companies-groups/edit/:id/:name',
    name: 'BranchEdit',
    component: BranchEdit,
    props: true,
  },
  {
    path: '/business',
    name: 'Business',
    component: Business,
  },
  {
    path: '/business/add',
    name: 'BusinessAdd',
    component: BusinessAdd,
  },
  {
    path: '/workers',
    name: 'Worker',
    component: Worker,
  },
  {
    path: '/workers/add',
    name: 'WorkerAdd',
    component: WorkerAdd,
  },
  {
    path: '/workers/bulkAdd',
    name: 'WorkerBulkAdd',
    component: WorkerBulkAdd,
  },
  {
    path: '/workers/edit/:id/:name',
    name: 'WorkerEdit',
    component: WorkerEdit,
    props: true,
  },
  {
    path: '/workers/detail/:id/:name',
    name: 'WorkerDetail',
    component: WorkerDetail,
    props: true,
  },
  {
    path: '/sites',
    name: 'Site',
    component: Site,
  },
  {
    path: '/sites/add',
    name: 'SiteAdd',
    component: SiteAdd,
  },
  {
    path: '/sites/bulkAdd',
    name: 'SiteBulkAdd',
    component: SiteBulkAdd,
  },
  {
    path: '/sites/edit/:id/:name',
    name: 'SiteEdit',
    component: SiteEdit,
    props: true,
  },
  {
    path: '/sites/detail/:id/:name',
    name: 'SiteDetail',
    component: SiteDetail,
    props: true,
  },
  {
    path: '/sites/attendance/:siteName/:ccusSiteId',
    name: 'SiteAttendance',
    component: SiteAttendance,
    props: true,
  },
  {
    path: '/companies/me',
    name: 'Account',
    component: Account,
  },
  {
    path: '/users/change-password',
    name: 'PasswordChange',
    component: PasswordChange,
  },
  {
    path: '/users/forgot-password',
    name: 'PasswordForgot',
    component: PasswordForgot,
  },
  {
    path: '/users/reset-password/:loginId',
    name: 'PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/users/forgot-login-id',
    name: 'LoginIdForgot',
    component: LoginIdForgot,
  },
  {
    path: '/accessLog/:id',
    name: 'AccessLog',
    component: AccessLog,
    props: true,
  },
  {
    path: '/loginLog/:id',
    name: 'LoginLog',
    component: LoginLog,
    props: true,
  },
  {
    path: '/teamsOfUse',
    name: 'TeamsOfUse',
    component: TeamsOfUse,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
