

























































































































































































import { Component, Vue } from 'vue-property-decorator';
import Modal from './BaseModal.vue';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  name: 'Sidebar',
  components: {
    Modal,
  },
  created() {
    this.nombreRuta = this.$route.fullPath;
    this.setName();
  },
  data() {
    return {
      name: '',
      modal: false,
      // role: this.$store.getters.role,
      // isSidebarShow: this.$store.getters.isSidebarShow,
      // ローディング
      postLodingFlg: false, // データ更新時ローディングフラグ
      postErrorFlg: false,  // データ更新時エラーフラグ
    };
  },
  mounted() {
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
    supervisor() {
      if(this.$store.getters.session.supervisor) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    async setName() {
      // 10文字以上は省力する
      const session = this.$store.getters.session;
      if (!session.name) {
        this.name = '';
      } else {
        const name = session.name;
        const maxLength = 10;
        if (name.length > maxLength) {
          this.name = name.substring(0, maxLength) + '...';
        } else {
          this.name = name;
        }
      }

    },
    operateSidebar() {
      const session = this.$store.getters.session;
      session.isSidebarShow = !this.isSidebarShow;
      this.$store.commit('setSession', session);
      // this.$store.commit('setIsSidebarShow', {
      //   isShow: !this.isSidebarShow,
      // });
    },
    isCurrent(next) {
      return this.nombreRuta === next;
    },
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    async logout() {
      try {
        // ローディング表示
        // this.postLodingFlg = true;
        // ログアウト前に再度認証取得(有効期限切れでログアウトAPIが呼び出せないことがあるため)
        await this.$cognito.isAuthenticated();
        // ログアウト履歴登録
        this.postLoginLog();

        // Cognito サインアウト
        await this.$cognito.signout();
        this.$store.commit('reset');
        this.$router.push('/');
      } catch (error) {
        this.postErrorFlg = true;
      } finally {
        // ローディング非表示
        this.postLodingFlg = false;
      }
    },

    // ログアウト履歴登録
    async postLoginLog() {
      try {
        const restResourceService = new ApiClient();
        // クライアントのユーザーエージェント取得
        const ua = navigator.userAgent;
        // Body用意
        const body = {
          ua,
          type: 0,
        };
        // ログイン情報を登録
        await restResourceService.postLoginLogs(body);
      } catch (error) {
      }
    },

    notification() {
      const next = '/notifications';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    cardReader() {
      const next = '/cardReader';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    entrance() {
      const next = '/entrances';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    loginLog() {
      const next = '/loginLog/-';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    branch() {
      const next = '/companies-groups';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    business() {
      const next = '/business';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    worker() {
      const next = '/workers';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    site() {
      const next = '/sites';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    account() {
      const next = '/companies/me';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
    passwordChange() {
      const next = '/users/change-password';
      if (this.isCurrent(next)) {
        return;
      }
      this.$router.push(next);
    },
  },
};
