







































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import moment from 'moment';
import common from '../services/common';
import constants from "../constants/constants";

export default {
  title: '現場登録',
  name: 'SiteAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      siteslistPath: '/sites', // 現場一覧パス
      // 入力項目モデル
      detail: {
        ccusId: '',
        // 選択した事業者用
        companyId: '',
        // 選択した支店用
        branchId: '',
        siteName: '',
        startDate: '',
        endDate: '',
        postalCode: '',
        prefecture: '',
        city: '',
        town: '',
        otherAddress: '',
        fullAddress: '',
        supervisorId: '',
        tel: '',
        approve: true,
      },
      // 選択用事業者配列　事業者一覧取得APIが実装され次第そちらに変更
      optionCompanies: [],
      // 選択用支店配列
      optionBranches: [],
      // 選択用技能者配列
      optionWorkers: [],
      // ドロップダウンリスト
      selectedPrefectures: '', // 選択した都道府県用
      optionPrefectures: [],   // 選択用都道府県配列
      // ローディング
      getLodingFlg: false,     // データ取得時ローディング用フラグ
      getErrorFlg: false,      // データ取得時エラーフラグ
      postLodingFlg: false,    // データ登録時ローディングフラグ
      postErrorFlg: false,     // データ登録時エラーフラグ
      // 入力チェック
      validationErrFlg: false, // 入力チェックエラーフラグ
      validationErrMsg: [],    // 入力チェックエラーメッセージ用配列
      errorMessage: '',
      searchCondition: {},
    };
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  // 初期処理
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // this.detail.supervisorId = 'wa01w767-bf19-4a6d-81a8-710daf1635d5';
      // 事業者リスト取得API呼出
      this.optionCompanies = await this.callGetCompaniesList();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  methods: {
    // 事業者リスト取得API呼び出し
    async callGetCompaniesList() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getCompaniesNameList();
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // 支店名リスト取得API呼び出し
    async updateBranchsNameList(companyId) {
      try {
        const restResourceService = new ApiClient();
        this.optionBranches = await restResourceService.getBranchesNameList(companyId);
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // 技能者リスト取得API呼出し
    async updateWorkersList(companyId, branchId) {
      try {
        this.searchCondition['companyId'] = companyId;
        this.searchCondition['branchId'] = branchId;
        const restResourceService = new ApiClient();
        // TODO: 取得人数
        const result =await restResourceService.getWorkersList(constants.LIMIT, constants.PAGE, this.searchCondition,{})
        this.optionWorkers = result.workers;
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    async updateSiteTel(optionWorkers, supervisorId) {
      try {
        const workers = await optionWorkers;
        await workers.forEach((worker) => {
          if (supervisorId === worker.workerId) {
            this.detail.tel = worker.tel;
          }
        });
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // 郵便番号を元に住所取得
    getAddress() {
      const restResourceService = new ApiClient();
      restResourceService.getAddress(this.detail.postalCode)
        .then((res) => {
          this.detail.prefecture = res.pref;
          this.detail.city = res.city;
          this.detail.town = res.town;
        },
      );
    },
    // 登録ボタン押下時 登録処理
    async confirm() {
      // ボタン非活性化
      this.startProcessing();
      // ====================入力項目空白削除====================
      if (this.detail.otherAddress) {
        this.detail.fullAddress = this.detail.prefecture + this.detail.city + this.detail.town + this.detail.otherAddress;
      } else {
        this.detail.fullAddress = this.detail.prefecture + this.detail.city + this.detail.town;
      }
      this.detail.siteName = this.detail.siteName.trim();
      this.detail.town = this.detail.town.trim();
      if (this.detail.otherAddress) {
        this.detail.otherAddress = this.detail.otherAddress.trim();
      }
      if (this.detail.supervisorId) {
        this.detail.supervisorId = this.detail.supervisorId.trim();
      }
      // ====================入力チェック====================
      // 入力チェックフラグ・メッセージ初期化
      this.validationErrFlg = false;
      this.validationErrMsg = [];
      // 郵便番号と住所の妥当性チェック(郵便番号,都道府県,市区町村)
      if (this.detail.postalCode) {
        const checkAddressFlg = await common.checkAddress(
          this.detail.postalCode, this.detail.prefecture, this.detail.city);
        if (!checkAddressFlg) {
          this.validationErrFlg = true;
          this.validationErrMsg.push('都道府県と市区町村が郵便番号と一致していません');
        }
      }
      // 現場管理者存在チェック
      // if (this.detail.supervisorId) {
      //   const checksupervisorIdeFlg = await common.checksupervisorIde(this.detail.supervisorId);
      //   if (!checksupervisorIdeFlg) {
      //     this.validationErrFlg = true;
      //     this.validationErrMsg.push('一致する現場管理者が存在しません');
      //   }
      // }
      // 入力に不正がある場合、処理を中断
      if (this.validationErrFlg) {
        return;
      }

      // ====================API呼出処理====================
      try {
        // ローディング表示
        this.postLodingFlg = true;
        // エラーフラグ初期化
        this.postErrorFlg = false;
        const bodies = [];
        const body = {
           ccusId: this.detail.ccusId,
           siteName: this.detail.siteName,
           companyId: this.detail.companyId,
           parentCompanyId: '', // TODO 親会社IDを取得　選択欄追加
           rank: 1, // TODO 何次受けかを入力もしくは取得　方法不明
           branchId: this.detail.branchId,
           postalCode: this.detail.postalCode,
           prefecture: this.detail.prefecture,
           city: this.detail.city,
           town: this.detail.town,
           otherAddress: this.detail.otherAddress,
           fullAddress: this.detail.fullAddress,
           tel: this.detail.tel,
           supervisorId: this.detail.supervisorId,
           approve: this.detail.approve,
           startDate: common.unixTimeFormat(this.detail.startDate),
           endDate: common.unixTimeFormat(this.detail.endDate),
        };
        bodies.push(body);
        const restResourceService = new ApiClient();
        await restResourceService.postSite(bodies);
        this.screenTransition(this.siteslistPath);
      } catch (error) {
      this.errorMessage = error.message;
      this.postErrorFlg = true;
      this.endProcessing();
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
    // チャタリング防止
    startProcessing() {
      this.processing = true;
    },
    endProcessing() {
      this.processing = false;
    },
    isProcessing() {
      return this.processing;
    },
  },
};
