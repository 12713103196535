





















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import Modal from './BaseModal.vue';
import Detail from './BusinessDetail.vue';
import common from '../services/common';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: '事業者一覧',
  name: 'Worker',
  components: {
    VueGoodTable,
    Modal,
    Detail,
    VueLoading,
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      detailModal: false,
      deleteModal: false,
      loginIdOptions: [],
      loginIdCondition: [],
      companyNameOptions: [],
      companyNameCondition: [],
      columns: [
        {
          label: '内部ID',
          field: 'companyId',
          sortable: true,
          width: '160px',
        },
        {
          label: 'ログインID',
          field: 'loginId',
          sortable: true,
        },
        {
          label: 'メールアドレス',
          field: 'email',
          sortable: true,
          width: '150px',
        },
        {
          label: '事業者名',
          field: 'companyName',
          sortable: true,
          width: '200px',
        },
        {
          label: '登録日',
          field: 'createdAt',
          sortable: false,
          // type: 'date',
          // dateInputFormat: 'yyyy-mm-dd',
          // dateOutputFormat: 'yyyy年mm月dd日',
          width: '130px',
          formatFn: this.callDateFormat,
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
          width: '65px',
        },
      ],
      rows: [],
      conditionRow: [],
      selectRow: [],
      deleteCompanySiteList: [],
      deleteCompanySiteColumns: [
        {
          label: '現場名',
          field: 'siteName',
        },
      ],
      // ローディング
      getLodingFlg: false,    // データ取得時ローディング用フラグ
      getErrorFlg: false,     // データ取得時エラーフラグ
      deleteLodingFlg: false, // データ削除時ローディングフラグ
      deleteErrorFlg: false,  // データ削除時エラーフラグ
      csvDlFlg: false,
      errorMessage: '',
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // CSV取得上限
      csvLimit: 10000,
    };
  },
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // 事業者一覧取得
      this.rows = await this.callGetCompaniesList();
      // 初期データをセット
      this.conditionRow = this.rows;
      // 事業者リスト取得
      this.companyNameOptions = await this.callGetCompaniesNameList();
      // URL検索条件確認
      this.loginIdCondition = common.getUrlParam('loginId', 0);
      this.companyNameCondition = common.getUrlParam('companyName', 0);
      // 検索処理呼出
      this.search();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 事業者一覧取得API呼び出し
    async callGetCompaniesList() {
      try {
        const restResourceService = new ApiClient();
        const resultRows = await restResourceService.getCompaniesList();
        // エポックミリ秒をYYYY-MM-DDに変換
        resultRows.forEach((resultRow, index) => {
          resultRows[index].createdAt = common.dateFormatHyphen(resultRow.createdAt);
        });
        this.csvDlFlg  = (resultRows.length > 0) ? true : false;
        return resultRows;
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // 日時値を日時（YYYY年MM月DD日）に変換
    callDateFormat(value) {
      return common.dateFormat(value);
    },
    // 事業者名リスト取得API呼び出し
    async callGetCompaniesNameList() {
      try {
        switch (this.role) {
          case 'admin':
            const restResourceService = new ApiClient();
            return await restResourceService.getCompaniesNameList();
            break;
          case 'business':
            break;
          case 'branch':
            break;
          case 'worker':
            break;
          default:
            break;
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // 検索条件：メールアドレス追加
    addLoginIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.loginIdCondition.push(tag);
      this.loginIdOptions.push(tag);
    },
    // 検索条件：事業者名追加
    addCompanyNameTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.companyNameCondition.push(tag);
      this.companyNameOptions.push(tag);
    },
    // 検索ボタン押下時
    search() {
      this.setCondition(this.rows);
      // 検索条件をURLに詰める
      common.setUrlParam('loginId', this.loginIdCondition, 0);
      common.setUrlParam('companyName', this.companyNameCondition, 0);
      // テーブルが空の場合CSVダウンロードを非活性
      this.csvDlFlg  = (this.conditionRow.length > 0) ? true : false;
    },
    // 一覧上部の検索条件を一覧へ反映
    setCondition(rows) {
      if (this.loginIdCondition.length === 0 && this.companyNameCondition.length === 0) {
        // 検索条件がない場合、初期データを書き戻す
        this.conditionRow = rows;
        return;
      }
      let newRows = [];
      let tempRows = rows;
      this.conditionRow = [];
      // 検索条件：メールアドレス(部分一致)
      if (this.loginIdCondition.length !== 0) {
        newRows = [];
        // 検索用タグ配列分ループ
        this.loginIdCondition.forEach(
          (loginId) => {
            // 表示中のテーブル列の中からタグ
            const tempRow = tempRows.filter((v) =>
              v.email.match(loginId.name),
            );
            if (tempRow.length !== 0) {
              Array.prototype.push.apply(newRows, tempRow);
            }
          },
        );
        // 重複チェック
        this.conditionRow = Array.from(
          new Map(newRows.map((row) => [row.companyId, row])).values()
        );
        tempRows = this.conditionRow;
      }
      // 検索条件：事業者名（選択）
      if (this.companyNameCondition.length !== 0) {
        // 検索条件に合うレコードを取得
        this.conditionRow = common.searchConditionName(this.companyNameCondition, tempRows, 'companyName');
        tempRows = this.conditionRow;
      }
    },
    // 事業者新規登録画面へ遷移
    add() {
      this.$router.push('/business/add');
    },
    // 事業者-詳細画面 or 詳細モーダルを開く
    openDetail(params) {
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.closeModal);
      this.selectRow = params.row;
      // モーダル表示
      this.detailModal = true;
    },
    // 詳細モーダルを閉じる
    closeModal() {
      window.removeEventListener('popstate', this.closeModal);
      this.detailModal = false;
    },
    // 削除モーダルを開く
    async openDeleteModal(params) {
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.closeDeleteModal);
      // 事業者所属現場リストを検索
      const companyId = params.companyId;
      const restResourceService = new ApiClient();
      this.deleteCompanySiteList = await restResourceService.getCompaniesSiteList(companyId);
      this.selectRow = params;
      this.deleteModal = true;
    },
    // 削除モーダルを閉じる
    closeDeleteModal() {
      window.removeEventListener('popstate', this.closeDeleteModal);
      this.deleteModal = false;
    },
    // 削除実行
    async deleteExecution(companyId, loginId) {
      try {
        this.deleteErrorFlg = false;
        this.deleteLodingFlg = true;
        const restResourceService = new ApiClient();
        // 事業者削除API呼び出し
        await restResourceService.deleteCompany(companyId);
        // cognitoユーザー削除
        await this.$cognito.deleteUsers([loginId]);
        // 事業者一覧再取得
        this.rows = await this.callGetCompaniesList();
        // 初期データをセット
        this.conditionRow = this.rows;
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.deleteErrorFlg = true;
      } finally {
        this.deleteModal = false;
        this.deleteLodingFlg = false;
      }
    },
    // アクセスログ
    accessLog() {
      this.$router.push({
        name: 'AccessLog',
        params: {
          id: this.id,
        },
      });
    },
    // ログイン履歴
    loginLog() {
      this.$router.push({
        name: 'LoginLog',
        params: {
          id: this.id,
        },
      });
    },
    // CCUSID修正
    ccusIdChange() {

    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      const columns = this.columns;
      const rows = this.conditionRow;
      // ファイル名用の現在日時
      const currentDate = new Date().getTime();
      const date = await common.getToday(currentDate);
      const fileName = '事業者一覧'+date;
      common.outputCsv(columns, rows, fileName);
    },
    // 現在ページ更新
    onPageChange(params) {
      this.currentPage = params.currentPage;
      common.scrollTop();
    },
  },
};
