


































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'ログイン',
  name: 'Login',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  data() {
    return {
      errorMassage: '',
      loginId: '',
      password: '',
      lodingFlg: false,
      processing: false,
      getErrorFlg: false,   // データ取得時エラーフラグ
    };
  },
  methods: {
    // async signUp() {
    //   await this.$cognito.signUp(this.loginId, this.password, 3);
    // },
    async login() {
      try {
        // ボタン非活性化
        this.startProcessing();
        // ローディング表示
        this.lodingFlg = true;
        this.getErrorFlg = false;
        // Cognito サインイン
        // cognito.configure(config);
        // console.log('---->>>***', cognito);
        const userData = await this.$cognito.signin(this.loginId, this.password);
      } catch (error) {
        console.log('Error:', error);
        this.errorMessage = 'ログインIDまたはパスワードが間違っています';
        this.getErrorFlg = true;
        this.lodingFlg = false;
        this.endProcessing();
      }
        // 現在のユーザーの属性情報を取得・表示する
      const currentUserData = {};
      const cognitoUser = this.$cognito.userPool.getCurrentUser();
      if (cognitoUser != null) {
          cognitoUser.getSession(async (err, session) => {
            if (err) {
              console.log('getSession', err);
              return;
            }
            // ユーザの属性を取得
            cognitoUser.getUserAttributes(async (err, result) => {
              if (err) {
                console.log('getUserAttributes', err);
                throw err;
              }
              if (!result) {
                console.log(err);
                return;
              }
              console.log(result);

              // 認証済みか
              await this.$cognito.isAuthenticated();
              // 取得した属性情報を連想配列に格納
              for (let i = 0; i < result.length; i++) {
                currentUserData[result[i].getName()] = result[i].getValue();
              }
              // ログインユーザーに紐づくID情報を取得
              const userIds = await this.getUser(currentUserData);
              // this.$store.commit('setLoginId', currentUserData['name']);
              // this.$store.commit('setRole', currentUserData['custom:role']);
              // this.$store.commit('setName', userIds.name);
              // this.$store.commit('setCompanyId', userIds.companyId);
              // this.$store.commit('setBranchId', userIds.branchId);
              // this.$store.commit('setWorkerId', userIds.workerId);
              // ロール名判別
              const role = await this.roleCheck(currentUserData['custom:role']);
              const session = {
                loginId: currentUserData['name'],
                role: role,
                name: userIds.name,
                companyId: userIds.companyId,
                branchId: userIds.branchId,
                workerId: userIds.workerId,
                supervisor: false,
                isSidebarShow: true,
              };
              if (currentUserData['custom:role'] === '7' ) {
                // this.$store.commit('setSupervisor', userIds.supervisor);
                session.supervisor = userIds.supervisor;
              }
              this.$store.commit('setSession', session);

              // サインアウト処理
              // const signoutButton = document.getElementById("signout");
              // signoutButton.addEventListener("click", event => {
              //   cognitoUser.signOut();
              //   location.reload();
              // });
              // signoutButton.hidden = false;

              // ログイン履歴登録
              this.postLoginLog();

              // ログイン時サイドバーは開いておく
              // this.$store.commit('setIsSidebarShow', {
              //   isShow: true,
              // });
              this.lodingFlg = false;
              this.$router.push('/notifications');
            });
          });
        } else {
          console.log('cognitoUserなし');
          this.lodingFlg = false;
          // ボタン有効化
          this.endProcessing();
        }
    },

    // ロール判別
    async roleCheck(role) {
      let _role = '';
      switch (role) {
        case '1':
          _role = 'admin';
          break;
        case '3':
          _role = 'business';
          break;
        case '5':
          _role = 'branch';
          break;
        case '7':
          _role = 'worker';
          break;
        default:
          _role = '';
      }
      return _role;
    },

    // ログインユーザー取得
    async getUser(currentUserData) {
      try {
        // DBから登録ユーザー情報を取得
        const restResourceService = new ApiClient();
        const user = await restResourceService.getUser(
          currentUserData['custom:role'],
          currentUserData['name'],
          this.$store.getters.currentUser.signInUserSession.idToken.jwtToken);
        return user;
      } catch (error) {
        console.log('getUser', error);
        this.errorMessage = 'サーバーに問題が発生しました';
        this.getErrorFlg = true;
        this.lodingFlg = false;
        // ボタン有効化
        this.endProcessing();
        // Cognito サインアウト
        await this.$cognito.signout();
        this.$store.commit('reset');
        throw error;
      }
    },

    // ログイン履歴登録
    async postLoginLog() {
      try {
        const restResourceService = new ApiClient();
        // クライアントのユーザーエージェント取得
        const ua = navigator.userAgent;
        // Body用意
        const body = {
          ua,
          type: 1,
        };
        // ログイン情報を登録
        await restResourceService.postLoginLogs(body);
      } catch (error) {
      }
    },

    // パスワード忘れ再発行画面へ遷移
    async forgotPassword() {
      this.$router.push('/users/forgot-password');
    },
    // チャタリング防止
    startProcessing() {
      this.processing = true;
    },
    endProcessing() {
      this.processing = false;
    },
    isProcessing() {
      return this.processing;
    },
  },
};
