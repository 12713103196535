import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VuePapaParse from 'vue-papa-parse';
import VCalendar from 'v-calendar';
import validate from './services/validation';
import titleMixin from './router/title';

import Cognito from './cognito/cognito';
import config from './cognito-config';

import Multiselect from 'vue-multiselect';

// register globally
Vue.component('multiselect', Multiselect);

Vue.use(VuePapaParse);
Vue.use(validate);
Vue.use(VCalendar);

// Cognito 読み込み
Vue.use(Cognito, config.AWSConfig);
// Vue.use(Cognito);

const cognito = new Cognito();
// cognito.configure(config);
// console.log('---->>>***', cognito);

// Vue.use(cognito);
// export default cognito;

Vue.mixin(titleMixin);

Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth) && !Auth.loggedIn) {
//     next({ path: '/login', query: { redirect: to.fullPath }});
//   } else {
//     next();
//   }
// });

router.beforeResolve(async (to, from, next) => {
  const arr = ['/home', '/teamsOfUse', '/users/forgot-login-id', '/users/reset-password/', '/users/forgot-password'];
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1);
  }).then(() => {
    // 認証が切れていたらホームに戻す
    cognito.isAuthenticated()
    .then((session: any) => { // eslint-disable-line
      if (to.path == '/' || to.path == '/home') {
        next('/notifications');
        return;
      }
      next();
      return;
    })
    .catch((err) => { // eslint-disable-line
      console.log('=== session無し', err);

      // Arrayの配列内にあるURLであれば認証チェックを飛ばす
      if (to.path == '/') {
        next();
        return;
      } else if (arr.some(item => to.path.startsWith(item))) {
        next();
        return;
      } else {
        next('/');
        return;
      }
    });
  });

  // // Get signin session.
  // console.log('start === router.beforeResolve');
  // cognito.isAuthenticated()
  //   .then((session: any) => { // eslint-disable-line
  //     console.log('=== beforeResolve.then: ', session);
  //     const token = session.idToken.jwtToken;
  //     cognito.getAttribute()
  //       .then((result: any) => {
  //         const user = {};
  //         for (const v of result) {
  //           user[v.getName()] = v.getValue();
  //         }
  //         user['token'] = token;
  //       })
  //       .catch((error) => {
  //         next('/');
  //       });
  //     if (to.matched.some((record) => record.meta.notRequiredAuth)) {
  //       next({
  //         path: '/',
  //       });
  //     }
  //   })
  //   .catch((err) => { // eslint-disable-line
  //     console.log('=== beforeResolve.catch: ', err);
  //     if (to.matched.some((record) => record.meta.requiredAuth)) {
  //       next({
  //         path: '/',
  //       });
  //     }
  //   });
  // next();
});

/* eslint-disable no-new */

new Vue({
  // @ts-ignore
  cognito,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
