































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'カードリーダー登録',
  name: 'CurdReaderAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },

  data() {
    return {
      curdReaderslistPath: '/cardReader', // カードリーダー一覧パス
      // 登録用モデル
      detail: {
        deviceId: '',
        companyId: '',
        ccusSiteId: '',
        modeId: '',
      },
      selectedMode: '',     // 選択した入退場モード
      optionMode: [],       // 選択用入退場モード配列
      selectedCompany: '',  // 選択した事業者用
      optionCompanies: [],  // 選択用事業者配列
      // ローディング
      getLodingFlg: false,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false,  // データ登録時エラーフラグ2
      processing: false,
      errorMessage: '',
    };
  },
  // 初期処理
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // 入退場モード一覧取得API呼出
      this.optionMode = await this.callGetModeCategories();
      // 事業者リスト取得API呼出
      this.optionCompanies = await this.callGetCompaniesList();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 入退場モード一覧取得API呼び出し
    async callGetModeCategories() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getModeCategories();
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 事業者リスト取得API呼び出し
    async callGetCompaniesList() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getCompaniesList();
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 登録ボタン押下時処理 カードリーダー新規登録API呼び出し
    async confirm() {
      try {
        // ローディング表示
        this.postLodingFlg = true;
        // エラーフラグ初期化
        this.postErrorFlg = false;
        // カードリーダー新規登録API呼出
        const body =
        [
          {
            deviceId: this.detail.deviceId,
            companyId: this.selectedCompany,
            ccusSiteId: this.detail.ccusSiteId,
            modeId: this.selectedMode,
          },
        ];
        const restResourceService = new ApiClient();
        // 新規登録実行
        await restResourceService.postCardReader(body);
        // カードリーダー一覧画面に戻る
        this.screenTransition(this.curdReaderslistPath);
      } catch (error) {
        this.endProcessing();
        this.errorMessage = error.message;
        this.postErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
    // チャタリング防止
    startProcessing() {
      this.processing = true;
    },
    endProcessing() {
      this.processing = false;
    },
    isProcessing() {
      return this.processing;
    },
  },
};
