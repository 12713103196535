


























































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min, email } from 'vee-validate/dist/rules';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'パスワード再発行準備',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  data() {
    return {
      email: '',
      // ローディング
      sendLodingFlg: false,  // メール送信ローディングフラグ
      sendErrorFlg: false,   // メール送信時エラーフラグ
      loginIdErrorFlg: false,   // メール送信時エラーフラグ
      completeFlg: false,       // メール送信完了後フラグ
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 変更ボタン押下時処理
    async confirm() {
      try {
        // TODO suzuno パス系や固定値は定数ファイルにまとめたい……
        // 本番
        const RESET_PASSWORD_URL = 'https://ep-manager.com/users/forgot-password/';
        // 認証試験
        // const RESET_PASSWORD_URL = 'http://ep-manager-front-stg-s3-front-bucket.s3-website-ap-northeast-1.amazonaws.com/users/forgot-password/';
        this.sendErrorFlg = false;
        this.sendLodingFlg = true;
        const restResourceService = new ApiClient();
        const users = await restResourceService.getLoginId(this.email);
        // メールに使用するTemplateData
        const templateData = {
          users: [],
          url: RESET_PASSWORD_URL
        };
        users.forEach((user, i) => {
          templateData.users.push(
            {
              name: user.name,
              loginid: user.userName,
            }
          );
        });
        // メール送信API呼出
        await restResourceService.loginIdForgetMail(this.email, templateData);
        this.completeFlg = true;
      } catch (error) {
        this.sendErrorFlg = true;
        this.errorMessage = error.message;
        throw error;
      } finally {
        this.sendLodingFlg = false;
      }
    },
    // パスワード再設定画面へ遷移
    screenTransition() {
      this.$router.push('/users/forgot-password');
    },
  },
};
