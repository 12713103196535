import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import store from '../store';

import config from '../cognito-config';

export default class Cognito {

  public static install = (Vue, options) => {
    console.log('start === Getting Install.');
    Object.defineProperty(Vue.prototype, '$cognito', {
      get() { return this.$root._cognito; },
    });
    Vue.mixin({
      beforeCreate() {
        if (this.$options.cognito) {
          this._cognito = this.$options.cognito;
          this._cognito.configure(options);
        }
      },
    });
    console.log('  end === Getting Install.');
  }
  private userPool;
  private options;
  private currentUser;

  // 認証ずみかどうか
  public async isAuthenticated() {
    console.log('start === isAuthenticated');
    if (!this.userPool) {
      return new Promise((resolve, reject) => {
        reject('end === isAuthenticated Nothing...');
      });
    }
    this.currentUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) { reject(this.currentUser); }
      this.currentUser.getSession((err, session) => {
        if (err) {
          console.log('  end === isAuthenticated', err);
          // RefreshToken 有効期限切れの際にエラー　[NotAuthorizedException: Refresh Token has expired]
          reject(err);
        } else {
          // 要素の無限循環対策
          const currentUser =  Object.assign(this.currentUser);
          delete currentUser.storage['ccus-gw-front'];
          delete currentUser.pool.storage['ccus-gw-front'];
          store.commit('setCurrentUser', currentUser);
          if (!session.isValid()) {
            console.log('  end === isAuthenticated.!session.isValid()', session);
            reject(session);
          } else {
            console.log('  end === isAuthenticated.session.isValid()', session);
            resolve(session);
          }
        }
      });
    });
  }

  // 属性の取得
  public async getAttribute() {
    return new Promise((resolve, reject) => {
      const currentUser = store.getters.currentUser;
      currentUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  // 他ユーザーパスワードの更新
  public async setUserPassword(userName, email, password) {
    const cognito = new AWS.CognitoIdentityServiceProvider({
      apiVersion: '2016-04-18',
    });
    try {
      const result = await cognito.adminSetUserPassword(
        {
          UserPoolId: this.userPool.userPoolId,
          Username: userName,
          Password: password,
          Permanent: true,
        },
      ).promise();
    } catch (error) {
      console.error(error);
    }
  }
  protected configure(config) {
    if (config.userPool) {
      this.userPool = config.userPool;
    } else {
      this.userPool = new CognitoUserPool({
        UserPoolId: config.UserPoolId,
        ClientId: config.ClientId,
      });
    }
    // Config.region = config.Region;
    // Config.credentials = new CognitoIdentityCredentials({
    //   IdentityPoolId: config.IdentityPoolId
    // });
    // AWS.config.update(
    //   {
    //     credentials: new AWS.CognitoIdentityCredentials({
    //       IdentityPoolId: config.IdentityPoolId,
    //     }),
    //     region: config.Region,
    //   },
    // );
    AWS.config.region = config.Region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.IdentityPoolId
    })
    this.options = config;
    this.currentUser = false;
  }

  // サインアップ
  protected async signUp(username, email_, password, role_) {
    const now = Math.floor(new Date().getTime() / 1000);
    console.log(' === Before SignUp:', username, email_, password);

    const name = {
      Name: 'name',
      Value: username,
    };
    const email = {
      Name: 'email',
      Value: email_,
    };
    // const upatedAt = {
    //   Name: 'updated_at',
    //   Value: String(now)
    // };
    const role = {
      Name: 'custom:role',
      Value: role_,
    };

    const attributeList = [];
    attributeList.push(new CognitoUserAttribute(name));
    attributeList.push(new CognitoUserAttribute(email));
    // attributeList.push(new CognitoUserAttribute(upatedAt));
    attributeList.push(new CognitoUserAttribute(role));

    console.log(' === Start SignUp:', username, email, password, attributeList);

    return new Promise((resolve, reject) => {
      this.userPool.signUp(username, password, attributeList, null, (err, result) => {
        if (err) {
          console.log(' === SignUp ERROR', err);
          reject(err);
        } else {
          console.log(' === SignUp SUCCESS', result);
          resolve(result);
        }
      });
    });
  }

  // サインアップ時のコード認証
  protected async confirmation(username, confirmationCode) {
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  // サインイン
  protected async signin(username, password) {
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    console.log('signin・・・・・cognitoUser:', cognitoUser, '-----password', password);
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  // サインアウト
  protected async signout() {
    if (this.userPool.getCurrentUser()) {
      this.userPool.getCurrentUser().signOut();
    }
  }

  // コードの再送
  protected async resentCode() {
    return new Promise((resolve, reject) => {
      const currentUser = store.getters.currentUser;
      currentUser.getAttributeVerificationCode('email', {
        onSuccess: (result) => {
          console.log('success getAttributeVerificationCode');
          resolve(result);
        },
        onFailure: (err) => {
          console.log('failed getAttributeVerificationCode: ' + JSON.stringify(err));
          reject(err);
        },
      });
    });
  }

  // Eメールアドレス変更後 emailを有効可する
  protected async verifyAttribute(confirmationCode) {
    return new Promise((resolve, reject) => {
      const currentUser = store.getters.currentUser;
      currentUser.verifyAttribute('email', confirmationCode, {
        onSuccess: (result) => {
          console.log('email verification success');
          // const user = store.getters.user;
          // user.email_verified = 'true';
          // TODO user必要か？
          // const session = store.getters.session;
          // session.user.email_verified = 'true';
          // store.commit('setUser', user);
          resolve(result);
        },
        onFailure: (err) => {
          console.log('email verification failed');
          reject(err);
        },
      });
    });
  }

  // Eメールアドレスの更新
  protected async updateEmailAddress(username, email) {
    const attributes = {
      email,
      name: username,
    };
    return new Promise((resolve, reject) => {
      this.updateAttributes(attributes)
        .then((result) => { // eslint-disable-line
          resolve(result);
          const user = store.getters.user;
          user.email_verified = 'ture';
          // store.commit('setUser', user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // パスワード更新
  protected async updatePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      const currentUser = store.getters.currentUser;
      currentUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  // パスワード忘れメール送信
  protected async forgetPassword(username) {
    const userData = { Username: username, Pool: this.userPool };
    console.log('userData========', userData);
    const cognitoUser = new CognitoUser(userData);
    console.log('cognitoUser========', cognitoUser);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          console.log('email verification success');
          console.log(result);
          resolve(result);
        },
        onFailure: (err) => {
          console.log('email verification failed');
          reject(err);
        },
      });
    });
  }

  // パスワードリセット
  protected async resetPassword(username, newPassword, code) {
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (result) => {
          console.log('password reset success');
          resolve(result);
        },
        onFailure: (err) => {
          console.log('password reset failed');
          reject(err);
        },
      });
    });
  }

  // プロフィール更新
  protected async updateAttributes(attributes) {
    const attributeList = [];
    for (const key in attributes) {
      const attribute = { Name: key, Value: attributes[key] };
      attributeList.push(new CognitoUserAttribute(attribute));
    }
    return new Promise((resolve, reject) => {
      const currentUser = store.getters.currentUser;
      if (currentUser === null) {
        reject(currentUser);
      }

      // update attributes
      currentUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          reject(err);
        } else {
          console.log('attributeList', attributeList);
          // const user = store.getters.user;
          store.commit('setLoginId', attributes.email);
          const session = store.getters.session;
          session.loginId = attributes.loginId;
          store.commit('setSession', session);
          // for (const key in attributes) {
          //   user[key] = attributes[key];
          // }
          // store.commit('setUser', user);
          resolve(result);
        }
      });
    });
  }

  protected async deleteUsers(userNameList) {
    if (userNameList.length > 0) {
      const cognito = new AWS.CognitoIdentityServiceProvider({
        apiVersion: '2016-04-18',
      });

      for (const userName of userNameList) {
        // ユーザー削除
        try {
          await cognito.adminDeleteUser({
            UserPoolId: this.userPool.userPoolId,
            Username: userName,
          }).promise();
          console.log('Success! userName : ' + userName);
        } catch (err) {
          console.log('Failed! userName : ' + userName);
          if (err.code == 'UserNotFoundException') {
            // ユーザープールにユーザーが存在していない場合
            console.log('UserNotFoundException');
          } else {
            // その他のエラー
            console.log(err, err.stack);
          }
          throw err;
        }
      }
    }
  }
}
