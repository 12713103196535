






























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { validate, ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueGoodTable } from 'vue-good-table';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T,  R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T,  R>(val: T): ChainedWhen<T,  R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T,  A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T,  A>(fn()) : chain<T,  A>(val),
});
// when使用のための処理 ------ end

export default {
  title: '支店一括登録',
  name: 'branchBulkAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    VueLoading,
  },
  // setup() {
  // return {
  // },
  // },
  data() {
    return {
      // 所属事業者の設定に必要となるログイン事業者の内部ID
      companyId: '',
      // テンプレートファイルパス
      templateFilePath: '/files/branch-bulk-template.csv',
      // テンプレートファイルダウンロード時のファイル名
      templateFileName: 'branch-bulk-template.csv',
      // 記入サンプルファイルパス
      sumpleFilePath: '/files/branch-bulk-sample.csv',
      // 記入サンプルファイルダウンロード時のファイル名
      sumpleFileName: 'branch-bulk-sample.csv',
      // アップロードファイル
      fileName: '',
      upFile: '',
      csvData: {},
      errorMessages: [],
      num: '',
      columns:  [
        {
          label: '支店名',
          field: 'branchName',
        },
        {
          label: 'メールアドレス',
          field: 'email',
        },
        {
          label: 'パスワード',
          field: 'password',
        },
      ],
      rows: [],
      resultColumns:  [
        {
          label: '支店名',
          field: 'branchName',
        },
        {
          label: 'ログインID',
          field: 'loginId',
        },
        {
          label: 'メールアドレス',
          field: 'email',
        },
        {
          label: 'パスワード',
          field: 'password',
        },
      ],
      resultRows: [],
      // ローディング
      getLodingFlg: false,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false,  // データ登録時エラーフラグ
      parseFlg: false,
      errorMessage: '',
    };
  },
  async mounted() {
    try {
      this.getLodingFlg = true;
      // セッション情報からログイン事業者IDを取得
      this.companyId = this.$store.getters.session.companyId;
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // アップロードファイル取得
    loadCsvFile(event) {
      this.fileName = event.target.files[0].name;
      this.upFile = event.target.files[0];
      this.$refs.uploadFile.value = null;
      this.parseFlg = false;
    },
    // アップロードして確認ボタン押下時
    async csvExtract() {
      const errorMessages = new Array();
      let flg = false;
      try {
        // 抽出CSV配列とエラーメッセージ配列を空にする
        this.csvData.length = 0;
        if (!this.upFile) {
          errorMessages.push('ファイルを選択して下さい。');
          return;
        }
        // 取り込んだCSVファイルをオブジェクト配列に変換
        this.$papa.parse(this.upFile, {
          encoding: 'UTF-8',
          header: true,
          complete: this.csvGet,
          error: function(err, file) {
            errorMessages.push('CSVの抽出に失敗しました。ファイルを再度選択して下さい');
            flg = true;
          }
        });
      } catch (error) {
        errorMessages.push('CSVの抽出に失敗しました。ファイル内のデータをご確認下さい');
      } finally {
        this.errorMessages = errorMessages;
        new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1);
        }).then(() => {
          this.parseFlg = flg;
          this.scrollBottom();
        });
      }
    },
    // CSVから抽出されたオブジェクト配列を加工
    async csvGet(results) {
      const errorMessages = new Array();
      // 事業者ID　completeの中では参照できないためローカルの変数に移す
      const selectedCompanies = this.selectedCompanies;
      const restResourceService = new ApiClient();
      this.csvData = results.data;
      if(this.csvData.length === 0) {
        errorMessages.push('登録データが存在しません。ファイル内のデータをご確認下さい。');
      }
      for (let i = 0; i < this.csvData.length;  i++) {
        // ログイン中の事業者の事業者IDをプロパティとして追加
        this.csvData[i].companyId = this.companyId;
        // 必要情報が欠けた行が存在した場合、エラーメッセージを詰める
        if (!this.csvData[i].branchName && !this.csvData[i].loginId && !this.csvData[i].email && !this.csvData[i].password) {
          errorMessages.push('必要な列情報がありません。フォーマットを確認して下さい。');
          break;
        }

        const branchName = common.trimSpace(this.csvData[i].branchName);
        await validate(branchName, 'required|max:255', {
          name: '支店名（branchName）',
          values: {
            branchName
          }
        }).then((result) => {
          if (!result.valid) {
            errorMessages.push((i + 1) + '行目' + result.errors[0]);
          }
        });

        const email = common.trimSpace(this.csvData[i].email);
        await validate(email, 'required|max:255|emailVaridate', {
          name: 'メールアドレス（email）',
          values: {
            email
          }
        }).then((result) => {
          if (!result.valid) {
            errorMessages.push((i + 1) + '行目' + result.errors[0]);
          }
        });

        const password = common.trimSpace(this.csvData[i].password);
        await validate(password, 'required|min:8|max:64|password', {
          name: 'パスワード（password）',
          values: {
            password
          }
        }).then((result) => {
          if (!result.valid) {
            errorMessages.push((i + 1) + '行目' + result.errors[0]);
          }
        });
      }
      // エラーメッセージが詰められていた場合、処理を中断する
      if (errorMessages.length !== 0) {
        this.errorMessages = errorMessages;
        this.scrollBottom();
        return;
      }
      this.rows = this.csvData;
      this.scrollBottom();
    },
    // DOM更新待機後スクロール
    async scrollBottom() {
      await this.$nextTick();
      common.scrollBottom();
    },
    // ナンバリング取得
    // TODO suzuno フロント側ではログインID生成不要
    async callGetNumbering() {
      const key = this.$store.getters.loginId;
      // 採番取API
      const restResourceService = new ApiClient();
      this.num = await restResourceService.getNumbering(key);
    },
    // ログインID重複チェック
    // TODO suzuno 不要
    async callGetLoginIdcheck() {
      const restResourceService = new ApiClient();
      for (let i = 0; i < this.csvData.length; i++) {
        const result = await restResourceService.getLoginIdcheck(this.csvData[i].loginId);
        if(result) {
          await this.callGetNumbering();
          const number =  (('00000' + this.num).slice(-5));
          this.csvData[i].loginId = this.$store.getters.loginId + number;
        }
      }
    },
    // ナンバリング更新
    async callUpdateNumbering() {
      const key = this.$store.getters.loginId;
      for (let i = 0; i < this.csvData.length; i++) {
        // 採番更新API
        const restResourceService = new ApiClient();
        await restResourceService.updateNumbering(key);
      }
    },
    // 登録ボタン押下時処理
    async confirm() {
      try {
        this.postLodingFlg = true;
        this.postErrorFlg = false;
        this.errorMessages = [];
        // TODO　一括登録画面ではログインIDの発行はバックエンドで行うため、フロント側での整形は不要
        // await this.callGetNumbering();
        // for (let i = 0; i < this.csvData.length; i++) {
        //   const num = this.num + i;
        //   const number =  (('00000' + num).slice(-5));
        //   this.csvData[i].loginId = this.$store.getters.loginId + number;
        // }
        // 採番重複チェック
        // await this.callGetLoginIdcheck();
        // 支店一括登録API呼出
        const restResourceService = new ApiClient();
        this.resultRows = await restResourceService.postBranch(this.csvData);
        for (let i = 0; i < this.resultRows.length; i++) {
          this.resultRows[i].password = this.rows[i].password;
        }
        const csv = this.callCognitoCsv();

        // uplord用URLの取得API呼出
        const urlBody = {
           accountType: 'branch',
           fileName: csv.name,
        };
        // TODO: 変更後に対応しているか要確認
        const uploadUrl = await restResourceService.postAccountsUploadUrl(urlBody)

        // リクエストボディに不要な要素を削除
        for (let i = 0; i < this.resultRows.length;  i++) {
          delete this.resultRows[i].createdAt;
        }
        // cognito一括登録実行
        fetch(uploadUrl.uploadUrl, {
          method: 'PUT',
          mode: "cors",
          headers: {
              'Content-Type': 'text/csv',
          },
          body: csv.body,
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
      } catch (error) {
        console.log(error);

        this.errorMessages[0] = error.message;
        this.postErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
        await this.$nextTick();
        common.scrollBottom();
      }
    },
    // 支店一覧画面へ戻る
    branchList() {
      history.back();
    },
    // ダウンロード処理（ファイルパス, DL時ファイル名）
    doDownload(url, filename) {
      common.doDownload(url, filename);
    },
    // CSV出力用共通関数呼出
    callOutputCsv() {
      const today = common.getToday();
      const fileName = 'branch-bulk' + today;
      common.outputCsv(this.resultColumns, this.resultRows, fileName);
    },
    // CSV出力用共通関数呼出
    callCognitoCsv() {
      const today = common.getToday();
      const fileName = this.upFile.name;
      return common.cognitoCsv(this.resultColumns, this.resultRows, fileName);
    },
  },
}
;
