






























































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';
import Modal from './BaseModal.vue';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T, R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T, R>(val: T): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T, A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T, A>(fn()) : chain<T, A>(val),
});
// when使用のための処理 ------ end

export default {
  title: '会員情報',
  name: 'Account',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
    Modal,
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      loginId: '',
      companyId: '',
      workerId: '',
      today: 0,
      facePhoto: '',
      detail: {
        ccusId: '',
        ccusPassword: '',
        ccusSecurityCode: '',
        companyName: '',
        companyId: '',
        responsibleName: '',
        constructionNo: '',
        personDivision: '',
        personPosition: '',
        personDivisionPosition: '',
        personFullName: '',
        personLastName: '',
        personFirstName: '',
        personTel: '',
        approve: true,
        updatedAt: '',

        // 作業者情報
        name: '',
        gender: '',
        postalCode: '',
        fullAddress: '',
        tel: '',
        email: '',
      },
      newDetail: {
        ccusId: '',
        ccusPassword: '',
        ccusSecurityCode: '',
        companyName: '',
        companyId: '',
        responsibleName: '',
        constructionNo: '',
        prefecture: '',
        city: '',
        town: '',
        otherAddress: '',
        personDivision: '',
        personPosition: '',
        personDivisionPosition: '',
        personFullName: '',
        personLastName: '',
        personFirstName: '',
        personTel: '',
        approve: true,
        updatedAt: '',

        // 作業者情報
        name: '',
        gender: '',
        postalCode: '',
        fullAddress: '',
        tel: '',
        email: '',
      },

      // 保険加入情報
      insurance: {
        health: false,               // 健康保険
        pension: true,               // 年金保険
        employment: false,           // 雇用保険
        kentai: false,              // 建退共
        tyutai: false,              // 中退共
        industrialAccident: false,  // 労災保険
      },
      // 保有資格を配列で取得？
      qualifications: [
        {
          id: 1,
          name: '技術士建設部門',
        },
        {
          id: 2,
          name: '車両系建設機械運転者',
        },
        {
          id: 3,
          name: '高所作業車運転者',
        },
      ],
      // ドロップダウンリスト
      // 選択用性別配列
      optionGender: [
        {
          id: 'ge3138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: '男性',
        },
        {
          id: 'ge2138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: '女性',
        },
        {
          id: 'ge1138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: 'その他',
        },
      ],
      // モーダル表示用フラグ
      loginIdModal: false,
      ccusModal: false,
      companyModal: false,
      managerModal: false,
      workerModal: false,
      // ローディング用フラグ
      getLodingFlg: true,
      getErrorFlg: false,
      putLodingFlg: false,
      putErrorFlg: false,
      processing: false,
      errorMessage: '',
      displayingModal: 0,
    };
  },
  // 初期処理
  async mounted() {
    // セッション情報からログイン事業者IDを取得
    const session = this.$store.getters.session;
    this.loginId = session.loginId;
    this.companyId = session.companyId;
    if (this.role === 'worker') {
      this.workerId = session.workerId;
    }
    this.today = Date.now();
    try {
      // プロフィール報取得API呼出
      await this.setAccount();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // Profile取得API呼出
    async setAccount() {
      try {
        const restResourceService = new ApiClient();
        const result = await restResourceService.getAccount(this.companyId, this.workerId);
        if (this.role === 'business') {
          if (result.personLastName && result.personFirstName) {
            result.personFullName = result.personLastName + result.personFirstName;
          } else {
            result.personFullName = '';
          }
          if (result.personDivision && result.personPosition) {
            result.personDivisionPosition = result.personDivision + result.personPosition;
          } else {
            result.personDivisionPosition = '';
          }
        } else if (this.role === 'worker') {
          // 顔写真登録済みの場合、顔写真取得
          if (result.facePhotoName) {
            this.facePhoto = await this.getWorkerFacePhotoUrl(result.facePhotoName);
          }
        }
        this.detail = Object.assign({}, result);
        this.newDetail = Object.assign({}, result);
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // 郵便番号を元に住所取得 同一の処理のため、関数を作成し共通化
    async getAddress(postalCode) {
      const restResourceService = new ApiClient();
      await restResourceService.getAddress(postalCode)
        .then((res) => {
          this.newDetail.prefecture = res.pref;
          this.newDetail.city = res.city;
          this.newDetail.town = res.town;
        },
      );
    },

    // 技能者顔写真取得URL無効時エラーキャッチ
    imgError() {
      this.facePhoto = '';
      // TODO 画面にエラー表示が必要か
    },

    // モーダルを開く
    openModal(num) {
      this.displayingModal = num;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      when(num)
        .on((v) => v === 1, () => this.loginIdModal = true)   // メールアドレス更新
        .on((v) => v === 2, () => this.ccusModal = true)      // CCUS ID更新
        .on((v) => v === 3, () => this.companyModal = true)   // 事業者情報更新
        .on((v) => v === 4, () => this.managerModal = true)   // 事業者管理者更新
        .on((v) => v === 5, () => this.workerModal = true);   // 事業者管理者更新
    },
    // モーダルを閉じる
    closeModal(num) {
      window.removeEventListener('popstate', this.modalBack);
      when(num)
        .on((v) => v === 1, () => this.loginIdModal = false)  // メールアドレス更新
        .on((v) => v === 2, () => this.ccusModal = false)     // CCUS ID更新
        .on((v) => v === 3, () => this.companyModal = false)  // 事業者情報更新
        .on((v) => v === 4, () => this.managerModal = false)  // 事業者管理者更新
        .on((v) => v === 5, () => this.workerModal = false); // 事業者管理者更新
    },
    // モーダル表示中ブラウザバック処理
    modalBack() {
      this.closeModal(this.displayingModal);
    },
    // メールアドレス更新
    async updateEmail() {
      try {
        await this.startProcessing();
        const body = {
          loginId: this.loginId,
          email: this.detail.email,
          newEmail: this.newDetail.email,
          role: this.role,
        };

        this.postErrorFlg = false;
        this.postLodingFlg = true;
        // メールアドレス更新API呼び出し
        const restResourceService = new ApiClient();
        await restResourceService.patchAccountEmail(body);
        // メール送信API呼出
        // メールに使用するTemplateData
        const templateData = {
          oldEmail: this.detail.email,
          newEmail: this.newDetail.email,
        };
        await restResourceService.changeMail(this.newDetail.email, templateData);
        // 登録情報再取得
        await this.setAccount();
      } catch (error) {
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.closeModal(1);
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },

    // CCUS ID更新
    async updateCcusId() {
      try {
        await this.startProcessing();
        this.postErrorFlg = false;
        this.postLodingFlg = true;
        const restResourceService = new ApiClient();
        if (this.role === 'worker') {
          const body = {
            ccusId: this.detail.ccusId,
            newCcusId: this.newDetail.ccusId,
            ccusSecurityCode: this.newDetail.ccusSecurityCode,
            workerId: this.workerId,
            companyId: this.companyId,
          };
          // CCUS技能者ID登録API呼び出し
          await restResourceService.patchWorkerCcus(body);
        } else {
          const body = {
            ccusId: this.newDetail.ccusId,
            ccusPassword: this.newDetail.ccusPassword,
            companyId: this.companyId,
          };
          // CCUS技能者ID登録API呼び出し
          await restResourceService.patchAccountCcus(body);
        }
        // 登録情報再取得
        await this.setAccount();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.closeModal(2);
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },

    // 事業者情報更新
    async updateCompany() {
      try {
        await this.startProcessing();
        this.postErrorFlg = false;
        this.postLodingFlg = true;
        let fullAddress = common.trimSpace(this.newDetail.prefecture + this.newDetail.city + this.newDetail.town);
        if (this.newDetail.otherAddress) {
          fullAddress += this.newDetail.otherAddress;
        }

        // const role = this.role();
        const restResourceService = new ApiClient();
        const body = {
          companyName: common.trimSpace(this.newDetail.companyName),
          responsibleName: common.trimSpace(this.newDetail.responsibleName),
          constructionNo: common.trimSpace(this.newDetail.constructionNo),
          postalCode: common.trimSpace(this.newDetail.postalCode),
          fullAddress,
          prefecture: common.trimSpace(this.newDetail.prefecture),
          city: common.trimSpace(this.newDetail.city),
          town: common.trimSpace(this.newDetail.town),
          otherAddress: common.trimSpace(this.newDetail.otherAddress),
        };
        // 事業者情報更新API呼び出し
        await restResourceService.patchAccountCompany(this.companyId, body);
        // 登録情報再取得
        await this.setAccount();
        this.closeModal(3);
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.closeModal(3);
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },

    // 事業者担当者情報更新
    async managerUpdate() {
      try {
        await this.startProcessing();
        this.postErrorFlg = false;
        this.postLodingFlg = true;
        const restResourceService = new ApiClient();
        const body = {
          personDivision: common.trimSpace(this.newDetail.personDivision),
          personPosition: common.trimSpace(this.newDetail.personPosition),
          personLastName: common.trimSpace(this.newDetail.personLastName),
          personFirstName: common.trimSpace(this.newDetail.personFirstName),
          personTel: common.trimSpace(this.newDetail.personTel),
        };
        // アカウント事業者担当者情報更新API呼び出し
        await restResourceService.patchAccountManager(this.companyId, body);
        // 登録情報再取得
        await this.setAccount();
        this.closeModal(4);
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.closeModal(4);
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },

    // 技能者情報更新
    async workerUpdate() {
      try {
        await this.startProcessing();
        this.postErrorFlg = false;
        this.postLodingFlg = true;
        const fullName = this.newDetail.workerLastName + this.newDetail.workerFirstName;
        const fullAddress = this.newDetail.prefecture + this.newDetail.city + this.newDetail.town + this.newDetail.otherAddress;
        const restResourceService = new ApiClient();
        const body = {
          workerName: fullName,
          workerLastName: this.newDetail.workerLastName,
          workerFirstName: this.newDetail.workerFirstName,
          postalCode: this.newDetail.postalCode,
          fullAddress,
          prefecture: this.newDetail.prefecture,
          city: this.newDetail.city,
          town: this.newDetail.town,
          otherAddress: this.newDetail.otherAddress,
          workerTel: this.newDetail.workerTel,
        };
        // 技能者情報更新API呼び出し
        await restResourceService.patchAccountWorker(this.workerId, body);
        // 登録情報再取得
        await this.setAccount();
        this.closeModal(5);
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.closeModal(5);
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // 技能者顔写真取得
    async getWorkerFacePhotoUrl(fileName) {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getWorkerFacePhotoUrl(fileName);
      } catch (error) {
        throw error;
      }
    },
    // チャタリング防止
    async startProcessing() {
      this.processing = true;
    },
    async endProcessing() {
      this.processing = false;
    },
    async isProcessing() {
      return this.processing;
    },
  },
};
