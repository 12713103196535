









































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import moment from 'moment';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

export default {
  title: 'お知らせ編集',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
      required: true,
    },
    id: {
      type: String,
      default: '0',
      required: true,
    },
  },
  data() {
    return {
      notifiationslistPath: '/notifications', // お知らせ一覧パス
      // 入力項目モデル
      detail: {
        notificationCategory: '',
        notificationTitle: '',
        notificationBody: '',
        notificationExpiredAt: '',
      },
      // 登録済みデータ取得
      notificationDetail: {},
      // ドロップダウンリスト
      selectedcategories: '', // 選択お知らせカテゴリ
      optioncategories: [], // お知らせカテゴリ配列
      // ローディング
      getLodingFlg: true, // データ取得時ローディング用フラグ
      getErrorFlg: false, // データ取得時エラーフラグ
      postLodingFlg: false, // データ更新時ローディングフラグ
      postErrorFlg: false, // データ更新時エラーフラグ
      errorMessage: '',
    };
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  async mounted() {
    try {
      // お知らせカテゴリ取得API呼出
      this.optioncategories = await this.callGetNotificationCategories();
      // お知らせ一意情報取得API呼出
      this.notificationDetail = await this.callGetNotification();
      // 表示情報の詰めなおし
      this.detail.notificationCategory = this.notificationDetail.notificationCategoryId;
      this.detail.notificationTitle = this.notificationDetail.title;
      this.detail.notificationBody = this.notificationDetail.body;
      this.detail.notificationExpiredAt = common.dateFormatHyphen(this.notificationDetail.expiredAt);
      this.selectedcategories = this.notificationDetail.notificationCategoryId;
      // 詳細画面の場合、カテゴリIDでなくカテゴリ名を表示する必要がある
      // APIのレスポンスにカテゴリ名が加わり次第不要となる
      if (this.mode === 'detail') {
        this.detail.notificationCategory =
        this.categoryConversion(this.detail.notificationCategory, this.optioncategories);
      }
      // await new Promise((resolve) => setTimeout(resolve, 300));
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  methods: {
    // お知らせカテゴリ取得API呼び出し
    async callGetNotificationCategories() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getNotificationCategories();
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // お知らせ一意情報取得API呼び出し
    async callGetNotification() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getNotification(this.id);
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // カテゴリIDからカテゴリ名を返却
    categoryConversion(value, list) {
      const result = list.find((v) => v.id === value);
      if (result) {
        return result.name;
      }
    },
    // 変更ボタン押下時処理
    async confirm() {
      try {
        this.postLodingFlg = true;
        this.postErrorFlg = false;
        // カレンダーの値をUnixtimeへ変換
        const notificationExpiredAt = new Date(this.detail.notificationExpiredAt).getTime();
        // お知らせ更新API呼び出し
        const body = {
          id: this.id,
          notificationCategoryId: this.selectedcategories,
          title: this.detail.notificationTitle,
          body: this.detail.notificationBody,
          expiredAt: notificationExpiredAt,
        };
        const restResourceService = new ApiClient();
        await restResourceService.putNotification(body);
        // お知らせ一覧画面に戻る
        this.screenTransition(this.notifiationslistPath);
      } catch (error) {
      this.errorMessage = error.message;
      this.postErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
      }
    },
    // プルダウン選択内容取得・更新
    changeCategory(value) {
      this.selectedcategories = value.target.value;
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
  },
};
