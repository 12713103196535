import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    // loginId: '',
    // role: '',
    // name: '',
    // companyId: '',
    // branchId: '',
    // workerId: '',
    // supervisor: '',
    // isSidebarShow: true,
    currentUser: {},
    session: {},
  };
};

const state = getDefaultState();

const getters = {
  // loginId: (state) => state.loginId,
  // role: (state) => state.role,
  // name: (state) => state.name,
  // companyId: (state) => state.companyId,
  // branchId: (state) => state.branchId,
  // workerId: (state) => state.workerId,
  // supervisor: (state) => state.supervisor,
  // isSidebarShow: (state) => state.isSidebarShow,
  currentUser: (state) => state.currentUser,
  session: (state) => state.session,
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  // setLoginId({ commit }) {
  //   commit('setLoginId');
  // },
  // setRole({ commit }) {
  //   commit('setRole');
  // },
  // setName({ commit }) {
  //   commit('setName');
  // },
  // setCompanyId({ commit }) {
  //   commit('setCompanyId');
  // },
  // setBranchId({ commit }) {
  //   commit('setBranchId');
  // },
  // setWorkerId({ commit }) {
  //   commit('setWorkerId');
  // },
  // setSupervisor({ commit }) {
  //   commit('setSupervisor');
  // },
  // setIsSidebarShow({ commit }) {
  //   commit('setIsSidebarShow');
  // },
  setCurrentUser({ commit }) {
    commit('setCurrentUser');
  },
  setSession({ commit }) {
    commit('setSession');
  },
};

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  // // ログインID
  // setLoginId(state, loginId) {
  //   state.loginId = loginId;
  // },
  // // 権限
  // setRole(state, role) {
  //   let _role = '';
  //
  //   switch (role) {
  //     case '1':
  //       _role = 'admin';
  //       break;
  //     case '3':
  //       _role = 'business';
  //       break;
  //     case '5':
  //       _role = 'branch';
  //       break;
  //     case '7':
  //       _role = 'worker';
  //       break;
  //     default:
  //       _role = '';
  //   }
  //   state.role = _role;
  // },
  // // ログイン者名
  // setName(state, name) {
  //   state.name = name;
  // },
  // // 事業者ID
  // setCompanyId(state, companyId) {
  //   state.companyId = companyId;
  // },
  // // 支店ID
  // setBranchId(state, branchId) {
  //   state.branchId = branchId;
  // },
  // // 技能者ID
  // setWorkerId(state, workerId) {
  //   state.workerId = workerId;
  // },
  // // 現場管理者フラグ
  // setSupervisor(state, supervisor) {
  //   state.supervisor = supervisor;
  // },
  // // サイドバー表示フラグ
  // setIsSidebarShow(state, obj) {
  //   state.isSidebarShow = obj.isShow;
  // },
  // ログインユーザー情報
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
  },
  // セッション情報
  setSession(state, session) {
    state.session = session;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [createPersistedState(
    {
      key: 'ccus-gw-front',
    },
  )],
});
