import moment from 'moment';
import ApiClient from '../services/ApiClient';
import store from '../store';

export default {
  // ===================================================
  // 日時フォーマット変換関数
  // ===================================================
  // エポックミリ秒をYYYY年MM月DD日形式に変換して返却する
  dateFormat(value) {
    return moment(value).format('YYYY年MM月DD日');
  },

  // エポックミリ秒をYYYY年MM月DD日形式に変換して返却する
  dateMonthFormat(value) {
    return moment(value).format('MM月DD日');
  },

  // エポックミリ秒をYYYY-MM-DD形式に変換して返却する
  dateFormatHyphen(value) {
    return moment(value).format('YYYY-MM-DD');
  },

  // エポックミリ秒に変換して返却する
  unixTimeFormat(value) {
    return new Date(value).getTime();
  },

  // 日付をYYYY年MM月DD日hh時mm分ss秒形式に変換して返却する
  dateTimeFormat(value) {
    return moment(value).local().format('llll');
  },

  // エポックミリ秒をhh時mm分形式に変換して返却する
  dateTimeFormatEpoch(value) {
    return moment(value).local().format('HH:mm');
  },

  // エポックミリ秒をYYYY年MM月DD日hh:mm形式に変換して返却する
  dateTimeFormatLog(value) {
    return moment(value).local().format('YYYY年MM月DD日HH:mm');
  },

  // エポックミリ秒をYYYY年MM月DD日形式に変換して返却する
  dateMonthFormat(value) {
    return moment(value).format('MM月DD日');
  },

  // 現在日付取得（YYYY-MM-DD）
  getToday() {
    return moment().add(0).format('YYYY-MM-DD');
  },

  ipAddressFormat(value) {
    const val = value.split(',');
    return val[0];
  },

  // ===================================================
  // CSV関連関数
  // ===================================================
  // テーブル表示データをCSV出力する
  // @columns  1行目に設定する列情報
  // @rows     2行目以降に入力するテーブル表示中の行情報
  // @fileName 拡張子前までのダウンロードファイル名
  outputCsv (columns, rows, fileName) {
    // 列のラベルをカンマ区切りで文字列化
    let columnsCsvStr = '';
    let columnsCsvArr =[];
    columns.forEach(v => {
      // 非表示列は対象外
      if (!v.hidden) {
        // オプション列対象外
        if(v.label !== '') {
          let line = v.label + ',';
          // 列のラベル（日本語）を配列取得
          columnsCsvStr += line;
          // 列のフィールド名を配列取得
          columnsCsvArr.push(v.field);
        }
      }
    });
    // 行末のカンマを削除
    columnsCsvStr = columnsCsvStr.slice(0, -1);
    // CSVの列情報を詰める
    let csv = '\ufeff' + columnsCsvStr + '\n';
    rows.forEach(row => {
      columnsCsvArr.forEach(field => {
        let line = this.csvRow(row, field);
        csv += line;
      });
      // 行末のカンマを削除
      csv = csv.slice(0, -1);
      csv += '\n';
    });
    let blob = new Blob([csv], { type: 'text/csv' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  },

  // テーブル表示データをCSVに変換
  // @columns  1行目に設定する列情報
  // @rows     2行目以降に入力するテーブル表示中の行情報
  // @fileName 拡張子前までのダウンロードファイル名
  cognitoCsv (columns, rows, fileName) {
    // 列のラベルをカンマ区切りで文字列化
    let columnsCsvStr = '';
    let columnsCsvArr =[];
    columns.forEach(v => {
      // 非表示列は対象外
      if (!v.hidden) {
        // オプション列対象外
        if(v.label !== '') {
          let line = v.label + ',';
          // 列のラベル（日本語）を配列取得
          columnsCsvStr += line;
          // 列のフィールド名を配列取得
          columnsCsvArr.push(v.field);
        }
      }
    });
    // 行末のカンマを削除
    columnsCsvStr = columnsCsvStr.slice(0, -1);
    // CSVの列情報を詰める
    let csv = '\ufeff' + columnsCsvStr + '\n';
    rows.forEach(row => {columnsCsvArr.forEach(field => {
      let line = this.csvRow(row, field);
      csv += line;
    });
    // 行末のカンマを削除
    csv = csv.slice(0, -1);
    csv += '\n';
  });

    const res = {
      body: new Blob([csv], { type: 'text/csv' }),
      name:  fileName,
    }

    return res;
  },

  // 行情報をcsvに詰める
  // @row 行情報
  // @columnsCsvArr 列のフィールド名
  csvRow(row, field) {
    let csvRowStr = '';
    for(let rowField in row) {
      if (field === rowField) {
        let line = row[rowField];
        // nullの場合は空文字を挿入
        if(!line && line !== 0) {
          line = '';
        }
        line += ','
        csvRowStr += line;
      }
    };
    return csvRowStr;
  },

  // ===================================================
  // 入力チェック共通関数
  // ===================================================
  // 未来日付チェック（現在含む） 過去日付の場合エラー
  futureDayCheck(setDay) {
    const today = this.getToday();
    return setDay >= today;
  },

  // 過去日付チェック（現在含む） 未来日付の場合エラー
  pastDayCheck(setDay) {
    const today = this.getToday();
    return setDay <= today;
  },

  // 生年月日チェック (1歳から100歳) 範囲外の場合エラー
  birthDayCheck(setDay){
    const minDay = moment().subtract(120, 'years').format('YYYY-MM-DD');
    const maxDay = moment().subtract(12, 'years').format('YYYY-MM-DD');
    return setDay >= minDay && setDay <= maxDay;
  },

  // 郵便番号と住所の整合性確認 郵便番号から取得される住所と異なる場合エラー
  async checkAddress(postalCode, pref, city) {
    const restResourceService = new ApiClient();
    const res = await restResourceService.getAddress(postalCode);
    if (pref !== res.pref || city !== res.city) {
      return false;
    } else {
      return true;
    }
  },

  // 郵便番号と住所の整合性確認 郵便番号から取得される住所と異なる場合エラー
  async checkPrefecture(pref, postalCode) {
    const restResourceService = new ApiClient();
    const res = await restResourceService.getAddress(postalCode);
    if (pref !== res.pref) {
      return false;
    } else {
      return true;
    }
  },

  // 郵便番号と住所の整合性確認 郵便番号から取得される住所と異なる場合エラー
  async checkCity(city, postalCode) {
    const restResourceService = new ApiClient();
    const res = await restResourceService.getAddress(postalCode);
    if (city !== res.city) {
      return false;
    } else {
      return true;
    }
  },

  // 郵便番号の住所が存在しているか確認　見つからない場合エラー
  async addressFlg(postalCode){
    const restResourceService = new ApiClient();
    const res = await restResourceService.getAddress(postalCode);
    if(res == 404){
      return false;
    }else{
      return true;
    }
  },

  // JSON形式チェック　成り立たない場合エラー
  checkJson(strjson) {
    try {
    	JSON.parse(strjson);
    } catch (error) {
    	return false;
    }
    return true;
  },

  // 誕生日から現在年齢を計算
  ageConversion(birthDate) {
    if (!birthDate) {
      return '';
    }
    const nowDate = new Date();
    const newBirthDate = new Date(birthDate);
    const age = nowDate.getFullYear() - newBirthDate.getFullYear();
    // 誕生日まで到達していない場合、1を減算
    const thisYearsBirthday  = new Date(
      nowDate.getFullYear(),
      newBirthDate.getMonth(),
      newBirthDate.getDate()
    );
    return age + ( thisYearsBirthday.getTime() > nowDate.getTime() ? -1 : 0 );
  },

  // ===================================================
  // 配列処理関数
  // ===================================================

  // オブジェクト配列から検索条件に当てはまる要素の配列を返却
  searchConditionName(nameConditions, tempRows, target) {
    let newRows = [];
    let newNameList = [];
    nameConditions.forEach (nameCondition =>
      newNameList.push(nameCondition[target])
    );
    newRows = tempRows.filter((v) =>
      newNameList.includes(v[target])
    );
    return newRows;
  },

  // チェックボックス選択行のIDを配列で返却
  getSelectRowIds(selectRows) {
    let idList = new Array();
    for (let selectRow of selectRows) {
      idList.push(selectRow.id);
    }
    return idList;
  },

  // ===================================================
  // その他関数
  // ===================================================

  // 空白削除
  trimSpace(str) {
    if (str == null) {
      return '';
    }
    return str.replace(/\s+/g, '');
  },
  // 0/1のフラグを文字列に変換して返却する
  oneZeroFlgFormat(value, trueStr, falseStr) {
    if(value === 1) {
      return trueStr;
    } else if (value === 0) {
      return falseStr;
    } else {
      return '';
    }
  },

  // パスワード生成 現在使用箇所無し
  createPassword(length) {
    var alphabet = 'abcdefghijklmnopqrstuvwxyz';
    var alphabetUpper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var symbol = '!#$%()=-^¥[@]:;{}_/.';
    var numbers = '0123456789';
  	let password = '';
  	for (let i = 0; i < length/4; i++) {
  		password += alphabet.charAt(
        Math.floor(Math.random() * alphabet.length));
  		password += alphabetUpper.charAt(
        Math.floor(Math.random() * alphabetUpper.length));
  		password += symbol.charAt(
        Math.floor(Math.random() * symbol.length));
  		password += numbers.charAt(
        Math.floor(Math.random() * numbers.length));
  	}
  	return password;
  },

  // API用ヘッダー取得
  getHeaders() {
    return {
      'Content-Type': 'application/json',
      'x-user-id': store.getters.session.loginId,
      'x-user-role': store.getters.session.role,
      'Authorization': store.getters.currentUser.signInUserSession.idToken.jwtToken,
    };
  },

  // ダウンロード処理（ファイルパス, DL時ファイル名）
  doDownload(url, filename) {
    const alink = document.createElement('a');
    alink.href = url;
    alink.download = filename;
    alink.click();
    return false;
  },

  // ページ最上部スクロール
  scrollTop() {
    // const element = document.documentElement;
    // const bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, 0);
  },
  // ページ最下部スクロール
  scrollBottom() {
    const element = document.documentElement;
    const bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, bottom);
  },

  // URL内検索条件追加
  // name: 検索項目名  conditions: 条件 mode: 0配列 1オブジェクト 2数値
  setUrlParam(name, conditions, mode) {
    const url = new URL(location.href);
    // 検索条件がある場合、URLの検索パラメータに詰める
    if(mode === 0 && conditions.length > 0 || mode === 1 && conditions) {
      url.searchParams.set(name, JSON.stringify(conditions));
    } else if (mode === 2 && conditions) {
      url.searchParams.set(name, conditions);
    } else {
      // 検索条件が無い場合、URLの検索パラメータから該当の値を削除
      url.searchParams.delete(name);
    }
    // URLデコード
    url.search = decodeURIComponent(url.search);
    // URLを書き換える
    history.replaceState("", "", url.toString());
  },
  // URL内検索条件取得
  // name: 検索項目名  mode: 0配列 1オブジェクト 2数値
  getUrlParam(name, mode) {
    const url = new URL(location.href);
    // URLに検索条件が追加されていた場合
    if(url.search !== '') {
      // URLから指定された検索項目の検索条件を取得
      const urlParam = url.searchParams.get(name);
      if(mode === 0) {
        return urlParam ? JSON.parse(urlParam) : [];
      } else if(mode === 1) {
        return urlParam ? urlParam : {};
      } else if(mode === 2) {
        return urlParam ? urlParam : 0;
      }
    }
    if(mode === 0) {
      return [];
    } else if(mode === 1) {
      return {};
    } else if(mode === 2) {
      return 0;
    }
  },
  confirmSave (event) {
    event.returnValue = "ダウンロード処理が中断されますが、よろしいですか？";
    return "ダウンロード処理が中断されますが、よろしいですか？";
  },

  // その他エラー処理（サーバー不通）
  othersError (message) {
    if (message === 'Network Error') {
      return {
        status: '(失敗)',
        code: 'EM011',
        message: 'サーバーに接続できませんでした。'
      };
    } else if (!message) {
      return {
        status: '(失敗)',
        code: 'EM010',
        message: 'サーバーでエラーが発生しました。'
      };
    } else {
      return {
        status: '(失敗)',
        code: 'EM011',
        message: '原因不明のエラーが発生しました。'
      };
    }
  },
}
