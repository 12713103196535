




















































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';
import constants from "../constants/constants";

export default {
  title: '現場編集',
  name: 'SiteEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      siteslistPath: '/sites', // 現場一覧パス
      // 更新用モデル
      detail: {
        ccusId: '',
        siteId: '',
        siteName: '',
        startDate: '',
        endDate: '',
        postalCode: '',
        prefecture: '',
        city: '',
        town: '',
        otherAddress: '',
        fullAddress: '',
        tel: '',
        supervisorId: '',
        approve: true,
      },
      // プレースホルダー用元値
      before: {
        ccusId: '未設定',
        siteId: '未設定',
        siteName: '未設定',
        startDate: '00000000',
        endDate: '00000000',
        postalCode: '未設定',
        prefecture: '未設定',
        city: '未設定',
        town: '未設定',
        otherAddress: '未設定',
        fullAddress: '未設定',
        tel: '未設定',
        supervisorId: '未設定',
        approve: true,
      },
      // 選択用技能者配列
      optionWorkers: [],
      // ローディング
      getLodingFlg: true, // データ取得時ローディング用フラグ
      getErrorFlg: false, // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false, // データ登録時エラーフラグ
      // 入力チェック
      validationErrFlg: false, // 入力チェックエラーフラグ
      processing: false,
      validationErrMsg: [], // 入力チェックエラーメッセージ用配列
      errorMessage: '',
      searchCondition: {},
    };
  },
  created() {
  },
  // 初期処理
  async mounted() {
    try {
      // 現場情報set
      await this.setSite(this.id, this.detail, this.before);
      // await new Promise((resolve) => setTimeout(resolve, 300));
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 郵便番号を元に住所取得
    getAddress(detail) {
      const restResourceService = new ApiClient();
      restResourceService.getAddress(detail.postalCode)
        .then((res) => {
          this.detail.prefecture = res.pref;
          this.detail.city = res.city;
          this.detail.town = res.town;
          this.detail.fullAddress = res.pref + res.city + res.town;
        });
    },
    // 現場一意情報取得API呼出
    async setSite(id, detail, before) {
      try {
        const restResourceService = new ApiClient();
        await restResourceService.getSite(id)
        .then((res) => {
          detail.companyId = res.companyId;
          detail.branchId = res.branchId;
          detail.ccusId = res.ccusId;
          detail.siteId = res.siteId;
          detail.siteName = res.siteName;
          detail.startDate = common.dateFormatHyphen(res.startDate);
          detail.endDate = common.dateFormatHyphen(res.endDate);
          detail.postalCode = res.postalCode;
          detail.prefecture = res.prefecture;
          detail.city = res.city;
          detail.town = res.town;
          detail.otherAddress = res.otherAddress;
          detail.fullAddress = res.fullAddress;
          detail.tel = res.tel;
          detail.supervisorId = res.supervisorId;
          // this.getAddress(detail);
          this.updateWorkersList(detail);
          // before
          before.companyId = res.companyId;
          before.branchId = res.branchId;
          before.ccusId = res.ccusId;
          before.siteId = res.siteId;
          before.siteName = res.siteName;
          before.startDate = common.dateFormatHyphen(res.startDate);
          before.endDate = common.dateFormatHyphen(res.endDate);
          before.postalCode = res.postalCode;
          before.prefecture = res.prefecture;
          before.city = res.city;
          before.town = res.town;
          before.otherAddress = res.otherAddress;
          before.fullAddress = res.fullAddress;
          before.tel = res.tel;
          before.supervisorId = res.supervisorId;
        });
        return;
      } catch (error) {
        this.errorMessage = error.message;
      throw error;
      }
    },
    // 技能者リスト取得API呼出し
    async updateWorkersList(detail) {
      try {
        this.searchCondition['companyId'] = detail.companyId;
        this.searchCondition['branchId'] = detail.branchId;
        const restResourceService = new ApiClient();
        // TODO: 取得人数
        const result =await restResourceService.getWorkersList(constants.LIMIT, constants.PAGE, this.searchCondition,{})
        this.optionWorkers = result.workers;
      } catch (error) {
        this.errorMessage = error.message;
      throw error;
      }
    },
    async updateSiteTel(optionWorkers, ccusIdsupervisorId) {
      try {
        const workers = await optionWorkers;
        await workers.forEach((worker) => {
          if (ccusIdsupervisorId === worker.workerId) {
            this.detail.tel = worker.tel;

          }
        });
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // 変更ボタン押下時 更新処理
    async confirm() {
      // ボタン非活性化
      this.startProcessing();
      // ====================入力項目空白削除====================
      this.detail.siteName = this.detail.siteName.trim();
      this.detail.town = this.detail.town.trim();
      if (this.detail.otherAddress) {
        this.detail.otherAddress = this.detail.otherAddress.trim();
      }
      if (this.detail.supervisorId) {
        this.detail.supervisorId = this.detail.supervisorId.trim();
      }
      // ====================入力チェック開始====================
      // 入力チェックフラグ・メッセージ初期化
      this.validationErrFlg = false;
      this.validationErrMsg = [];
      // 入力に不正がある場合、処理を中断
      if (this.validationErrFlg) {
        return;
      }
      // ====================入力チェック終了====================
      this.detail.siteId = this.before.siteId;
      if (this.detail.otherAddress) {
        this.detail.fullAddress = this.detail.prefecture + this.detail.city + this.detail.town + this.detail.otherAddress;
      } else {
        this.detail.fullAddress = this.detail.prefecture + this.detail.city + this.detail.town;
      }
      this.detail.startDate = common.unixTimeFormat(this.detail.startDate);
      this.detail.endDate = common.unixTimeFormat(this.detail.endDate);
      // ====================API呼出処理開始====================
      try {
        // フラグ初期化
        this.postErrorFlg = false;
        // ローディング表示
        this.postLodingFlg = true;

        // 現場更新API呼出
        const body = {
          siteId:  this.$route.params.id,
          ccusId:  this.detail.ccusId,
          siteName: this.detail.siteName,
          companyId: this.detail.companyId,
          branchId: this.detail.branchId,
          postalCode: this.detail.postalCode,
          prefecture: this.detail.prefecture,
          city: this.detail.city,
          town: this.detail.town,
          otherAddress: this.detail.otherAddress,
          fullAddress: this.detail.fullAddress,
          tel: this.detail.tel,
          supervisorId: this.detail.supervisorId,
          startDate: this.detail.startDate,
          endDate: this.detail.endDate,
        };
        const restResourceService = new ApiClient();
        await restResourceService.putSite(body);
        this.screenTransition(this.siteslistPath);
      } catch (error) {
        this.errorMessage = error.message;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
    log(before, detail) {
      console.log('before: ', before.siteName);
      console.log('detail: ', detail.siteName);
    },
    // チャタリング防止
    startProcessing() {
      this.processing = true;
    },
    endProcessing() {
      this.processing = false;
    },
    isProcessing() {
      return this.processing;
    },
  },
  filters: {
    filterZip(d) {
      const buf = ('0000000' + d).slice(-7);
      return buf;
      // return isNaN(buf) ? '1000000' : buf;
    },
  },
};
