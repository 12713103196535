



































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Modal from './BaseModal.vue';
import Detail from './WorkerDetail.vue';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T,  R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T,  R>(val: T): ChainedWhen<T,  R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T,  A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T,  A>(fn()) : chain<T,  A>(val),
});
// when使用のための処理 ------ end

export default {
  title: '技能者管理',
  name: 'Worker',
  components: {
    VueGoodTable,
    Modal,
    Detail,
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      companyId: '',
      branchId: '',
      // 検索条件用
      loginIdOptions: [],
      loginIdCondition: [],
      nameOptions: [],
      nameCondition: [],
      companyOptions: [],
      companyCondition: [],
      branchNameOptions: [],
      branchNameCondition: [],
      // 選択削除用
      deleteSelectRows: [],
      // モーダル表示用フラグ
      idModal: false,
      ccusModal: false,
      deleteModal: false,
      branchChangeModal: false,
      detailModal: false,
      // 追加登録用
      regLoginId: '', // 登録メールアドレス仮データ。生成規則要確認
      regMail: '',      // 登録メール仮データ。列にメールアドレス情報を持たせる必要有。
      regEngineerId: '',            // 登録CCUS技能者ID
      regAuthNumber: '',            // 登録CCUS技能者本人確認番号
      birthdate: '',                // 登録用生年月日
      // 表示データ情報
      rows: [],
      // 選択行のデータを詰める
      detailRow: {},
      selectedRow: {},
      // ========================事業者画面========================
      columns: [
        {
          label: '内部ID',
          field: 'workerId',
          hidden: true,
        },
        {
          label: '所属支店ID',
          field: 'branchId',
          hidden: true,
        },
        {
          label: '所属支店',
          field: 'branchName',
          sortable: true,
          width: '120px',
        },
        {
          label: 'ログインID',
          field: 'loginId',
          sortable: true,
          width: '100px',
        },
        {
          label: 'メールアドレス',
          field: 'email',
          sortable: true,
          width: '154px',
        },
        {
          label: '名前',
          field: 'workerName',
          sortable: true,
          width: '120px',
        },
        {
          label: 'CCUS技能者ID',
          field: 'ccusWorkerId',
          sortable: false,
          width: '140px',
        },
        {
          label: '年齢',
          field: 'age',
          sortable: false,
          width: '40px',
        },
        {
          label: '電話番号',
          field: 'tel',
          sortable: false,
          width: '120px',
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
          width: '330px',
        },
      ],
      // ========================支店管理者画面========================
      branchColumns: [
        {
          label: '内部ID',
          field: 'workerId',
          hidden: true,
        },
        {
          label: '所属支店ID',
          field: 'branchId',
          hidden: true,
        },
        {
          label: '所属支店',
          field: 'branchName',
          sortable: true,
          width: '120px',
          hidden: true,
        },
        {
          label: 'ログインID',
          field: 'loginId',
          sortable: true,
        },
        {
          label: 'メールアドレス',
          field: 'email',
          sortable: true,
          width: '154px',
        },
        {
          label: '名前',
          field: 'workerName',
          sortable: true,
          width: '120px',
        },
        {
          label: 'CCUS技能者ID',
          field: 'ccusWorkerId',
          sortable: false,
          width: '140px',
        },
        {
          label: '年齢',
          field: 'age',
          sortable: false,
          width: '40px',
        },
        {
          label: '電話番号',
          field: 'tel',
          sortable: false,
          width: '120px',
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
          width: '330px',
        },
      ],
      // ========================運営管理画面========================
      adminColumns: [
        {
          label: '内部ID',
          field: 'workerId',
          sortable: true,
          width: '350px',
        },
        {
          label: 'ログインID',
          field: 'loginId',
          sortable: true,
          width: '140px',
        },
        {
          label: 'メールアドレス',
          field: 'email',
          hidden: true,
        },
        {
          label: '名前',
          field: 'workerName',
          sortable: true,
          width: '140px',
        },
        {
          label: '登録日',
          field: 'registrationDate',
          sortable: false,
          // type: 'date',
          // dateInputFormat: 'yyyy-mm-dd',
          // dateOutputFormat: 'yyyy年mm月dd日',
          width: '140px',
          formatFn: this.callDateFormat,
        },
        // {
        //   label: '',
        //   field: 'operation',
        //   sortable: false,
        // },
      ],
      detail: {
        // 作業者情報
        name: '',
        sex: '',
        postalCode: '',
        address: '',
        tel: '',
        email: '',
        // CCUS登録情報
        newCcusId: '',
        ccusSecurityCode: '',
        // 保険加入情報
        insurance: {
          health: false,               // 健康保険
          pension: true,               // 年金保険
          employment: false,           // 雇用保険
          kentai: false,               // 建退共
          tyutai: false,               // 中退共
          industrialAccident: false,   // 労災保険
        },
        // 保有資格を配列で取得？
        qualifications: [
          {
            id: 1,
            name: '技術士建設部門',
          },
          {
            id: 2,
            name: '車両系建設機械運転者',
          },
          {
            id: 3,
            name: '高所作業車運転者',
          },
        ],
      },
      // 支店選択用
      selectedBranch: {
        branchName: '',
        branchId: '',
      },
      // 支店一覧配列　本来はモーダル読込時に支店リストを取得し、その配列を詰める
      optionBranch: [
        {
          code: 1,
          name: '関東',
        },
        {
          code: 2,
          name: '関西',
        },
      ],
      csvDlFlg: false,
      // ローディング
      getLodingFlg: true,    // データ取得時ローディング用フラグ
      getErrorFlg: false,     // データ取得時エラーフラグ
      postLodingFlg: false,   // データ登録時ローディングフラグ
      postErrorFlg: false,    // データ登録時エラーフラグ
      putLodingFlg: false,    // データ更新時ローディングフラグ
      putErrorFlg: false,     // データ更新時エラーフラグ
      deleteLodingFlg: false, // データ削除時ローディングフラグ
      deleteErrorFlg: false,// データ削除時エラーフラグ
      processing: false,
      errorMessage: '',
      displayingModal: 0,
      searchCondition: {},
      sortCondition: {},
      // CSVダウンロード処理実行中フラグ
      csvGetLodingFlg: false,
      // CSVダウンロードエラーフラグ
      csvGetErrorFlg: false,
      // CSVダウンロードエラーメッセージ
      csvGetErrorMessage: false,
      // CSV取得上限
      csvLimit: 5000,
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // ソートローディングフラグ
      lodingFlg: false,

    };
  },
  // 初期処理
  async mounted() {
    // セッション情報からログイン事業者IDを取得
    if (this.role === 'business') {
      this.companyId = this.$store.getters.session.companyId;
    }else if (this.role === 'branch') {
      this.branchId = this.$store.getters.session.branchId;
    }
    try {
      // 技能者一覧取得API呼出
      await this.callGetWorkersList();
      // 事業者リスト取得API呼出
      this.companyOptions = await this.callGetCompaniesNameList();
      // 支店リスト取得API呼出
      await this.callGetBranchsNameList();
      // 検索条件を取得
      this.loginIdCondition = common.getUrlParam('loginId', 0);
      this.nameCondition = common.getUrlParam('name', 0);
      // 検索処理呼出
      await this.search();
    } catch (error) {
      console.log('ERROR: ', error);
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
    getPaginateCount () {
      return Math.ceil(this.maxCount / this.limit);
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 技能者一覧取得API呼び出し
    async callGetWorkersList() {
      this.getErrorFlg = false;
      try {
        this.searchCondition['companyId'] = this.companyId;
        this.searchCondition['branchId'] = this.branchId;
        const restResourceService = new ApiClient();
        const result = await restResourceService.getWorkersList(this.limit,this.currentPage,this.searchCondition,this.sortCondition);
        // エポックミリ秒をYYYY-MM-DDに変換
        result.workers.forEach((worker, index) => {
          result.workers[index].registrationDate = common.dateFormatHyphen(worker.registrationDate),
          result.workers[index].age = common.ageConversion(worker.birthdate)
        });
        this.rows = result.workers;
        // テーブルデータをセット
        this.maxCount = result.maxCount;
        this.maxPage = Math.ceil(this.maxCount / this.limit);
        this.csvDlFlg = (this.rows.length > 0) ? true : false;
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 日時値を日時（YYYY年MM月DD日）に変換
    callDateFormat(value) {
      return common.dateFormat(value);
    },
    // 事業者リスト取得API呼び出し
    async callGetCompaniesNameList() {
      try {
        switch (this.role) {
          case 'admin':
            const restResourceService = new ApiClient();
            return await restResourceService.getCompaniesNameList();
            break;
          case 'business':
            break;
          case 'branch':
            break;
          case 'worker':
            break;
          default:
            break;
        }
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 支店名リスト取得API呼び出し
    async callGetBranchsNameList() {
      try {
        const restResourceService = new ApiClient();
        switch (this.role) {
          case 'admin':
            this.branchNameOptions = await restResourceService.getBranchesNameList(this.companyId);
            break;
          case 'business':
            this.branchNameOptions = await restResourceService.getBranchesNameList(this.companyId);
            break;
          case 'branch':
            break;
          case 'worker':
            break;
          default:
            break;
        }
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 検索条件：ログインID追加
    addLoginIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.loginIdCondition.push(tag);
      this.loginIdOptions.push(tag);
    },
    // 検索条件：名前追加
    addNameTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.nameCondition.push(tag);
      this.nameOptions.push(tag);
    },
    // 検索ボタン押下時処理　一覧上部の検索条件を一覧へ反映
    async search() {
      // 一覧取得用の検索条件を詰める
      this.setCondition();
      // 検索条件をURLに詰める
      common.setUrlParam('loginId', this.loginIdCondition, 0);
      common.setUrlParam('name', this.nameCondition, 0);
      try {
        // ローディング表示
        this.getLodingFlg = true;
        this.currentPage = 1;
        this.inputPage = this.currentPage;
        // 技能者一覧取得API呼出
        await this.callGetWorkersList();
      } catch (error) {
        throw error;
      } finally {
        // テーブルが空の場合CSVダウンロードを非活性
        this.csvDlFlg = (this.rows.length > 0) ? true : false;
        // ローディング非表示
        this.getLodingFlg = false;
      }
    },
    // 一覧上部の検索条件を一覧へ反映
    setCondition(rows) {
      this.ccusSentConditionErr = [];
      // 検索条件初期化
      if (this.searchCondition.loginId) delete this.searchCondition.loginId;
      if (this.searchCondition.workerName) delete this.searchCondition.workerName;

      // 検索条件が設定されていない場合はreturn
      if (this.loginIdCondition.length === 0 && this.nameCondition.length === 0) {
        return;
      }
      // 検索条件：ログインID（部分一致）
      if (this.loginIdCondition.length !== 0) {
        this.searchCondition.loginId = [];
        for (const loginId of this.loginIdCondition) {
          this.searchCondition.loginId.push(loginId.name);
        }
      }
      // 検索条件：技能者名（部分一致）
      if (this.nameCondition.length !== 0) {
        this.searchCondition.workerName = [];
        for (const workerName of this.nameCondition) {
          this.searchCondition.workerName.push(workerName.name);
        }
      }
    },

    // 技能者新規登録画面へ遷移
    add() {
      this.$router.push('/workers/add');
    },
    // 技能者一括登録画面へ遷移
    bulkAdd() {
      this.$router.push('/workers/bulkAdd');
    },
    // 技能者-編集画面
    edit(params) {
      this.$router.push({
        name: 'WorkerEdit',
        params: {
          id: params.workerId,
          loginId: params.loginId,
          name: params.workerName,
        },
      });
    },
    // 技能者詳細画面へ遷移
    openDetail(params) {
      this.detailRow = params.row;
      // 運用管理者
      if (this.$store.getters.session.role === 'admin') {
        this.$router.push({
          name: 'WorkerDetail',
          params: {
            id: this.detailRow.workerId,
            name: this.detailRow.workerName,
          },
        });
      // 運営管理者以外
      } else {
        this.openModal(6);
      }
    },
    // 削除実行
    async deleteExecution() {
      try {
        const workers = [this.selectedRow.workerId];
        this.deleteErrorFlg = false;
        this.deleteLodingFlg = true;
        const restResourceService = new ApiClient();
        // 技能者削除API呼び出し
        await restResourceService.deleteWorkers(workers);
        await this.$cognito.deleteUsers([this.selectedRow.loginId])
        // 技能者一覧再取得
        await this.callGetWorkersList();
      } catch (error) {
        this.errorMessage = error.message;
        this.deleteErrorFlg = true;
      } finally {
        this.closeModal(3);
        this.deleteLodingFlg = false;
      }
    },
    // メールアドレス登録
    async emailRegistration(workerId, email, loginId) {
      try {
        // ボタン非活性化
        await this.startProcessing();
        this.postErrorFlg = false;
        this.postLodingFlg = true;
        const body = {
          loginId,
          email: '',
          newEmail: email,
          role: 'worker',
        };
        // メールアドレス登録API呼び出し
        const restResourceService = new ApiClient();
        await restResourceService.patchAccountEmail(body);

        // メール送信API呼出
        // メールに使用するTemplateData
        const templateData = {
          oldMail: '未登録',
          newMail: email
        };
        await restResourceService.changeMail(email, templateData);
        // 技能者一覧再取得
        const resGetList = await this.callGetWorkersList();
        this.rows = resGetList;
        this.regMail = '';
      } catch (error) {
      this.errorMessage = error.message;
      this.postErrorFlg = true;
      this.endProcessing();
      } finally {
        this.closeModal(1);
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // CCUS技能者ID登録
    async ccusIdRegistration(workerId, newCcusId, ccusSecurityCode, birthdate) {
      try {
        // ボタン非活性化
        await this.startProcessing();
        this.postErrorFlg = false;
        this.postLodingFlg = true;
        const body = {
          workerId,
          newCcusId,
          ccusSecurityCode,
          birthdate,
        };
        const restResourceService = new ApiClient();
        // CCUS技能者ID登録API呼び出し
        await restResourceService.patchWorkerCcus(body);
        // 技能者一覧再取得
        const resGetList = await this.callGetWorkersList();
        this.rows = resGetList;
      } catch (error) {
      this.errorMessage = error.message;
      this.postErrorFlg = true;
      this.endProcessing();
      } finally {
        this.closeModal(2);
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // 支店変更実行
    async branchChangeExecution() {
      try {
        // ボタン非活性化
        await this.startProcessing();
        this.putErrorFlg = false;
        this.putLodingFlg = true;
        const restResourceService = new ApiClient();
        // 支店変更API呼び出し
        await restResourceService.patchWorkerBranch(
          this.selectedRow.workerId,
          this.selectedBranch.branchId);
        // 技能者一覧再取得
        this.rows = await this.callGetWorkersList();
      } catch (error) {
      this.errorMessage = error.message;
      this.postErrorFlg = true;
      this.endProcessing();
      } finally {
        this.closeModal(7);
        this.putLodingFlg = false;
        this.endProcessing();
      }
    },
    // モーダルを開く前処理
    beforeOpenModal(val, num) {
      this.selectedRow = val;
      if (num === 7) {
        this.selectedBranch = {
          branchName: val.branchName,
          branchId: val.branchId,
        };
      }
      this.openModal(num);
    },
    // モーダルを開く
    openModal(num) {
      this.displayingModal = num;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      when(num)
        .on((v) => v === 1, () => this.idModal = true)            // メールアドレス登録
        .on((v) => v === 2, () => this.ccusModal = true)          // CCUS技能者ID登録
        .on((v) => v === 3, () => this.deleteModal = true)        // 削除
        .on((v) => v === 6, () => this.detailModal = true)        // 詳細
        .on((v) => v === 7, () => this.branchChangeModal = true); // 支店変更
    },
    // モーダルを閉じる
    closeModal(num) {
      window.removeEventListener('popstate', this.modalBack);
      when(num)
        .on((v) => v === 1, () => this.idModal = false)           // メールアドレス登録
        .on((v) => v === 2, () => this.ccusModal = false)         // CCUS技能者ID登録
        .on((v) => v === 3, () => this.deleteModal = false)       // 削除
        .on((v) => v === 6, () => this.detailModal = false)       // 詳細
        .on((v) => v === 7, () => this.branchChangeModal = false); // 支店変更
    },
    // モーダル表示中ブラウザバック処理
    modalBack() {
      this.closeModal(this.displayingModal);
    },
    // チェックボックス選択行取得
    selectionChanged(v) {
      this.deleteSelectRows = v.selectedRows;
    },
    // チェックボックス選択行を一括削除
    async bulkDelete() {
      try {
        this.deleteErrorFlg = false;
        this.deleteLodingFlg = true;
        const restResourceService = new ApiClient();
        // 一括削除対象(メールアドレス未登録)のIDを詰める配列
        const deleteIdList = new Array();
        for (const deleteSelectRow of this.deleteSelectRows) {
          if (!deleteSelectRow.email) {
            deleteIdList.push(deleteSelectRow.workerId);
          }
        }
        if (deleteIdList.length !== 0) {
          // 技能者削除API呼び出し
          await restResourceService.deleteWorkers(deleteIdList);
        }
        // 技能者一覧再取得
        await this.callGetWorkersList();
      } catch (error) {
      this.errorMessage = error.message;
      this.deleteErrorFlg = true;
      } finally {
        this.deleteLodingFlg = false;
      }
    },
    // チャタリング防止
    async startProcessing() {
      this.processing = true;
    },
    async endProcessing() {
      this.processing = false;
    },
    async isProcessing() {
      return this.processing;
    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      try {
        this.csvGetLodingFlg = true;
        window.addEventListener('beforeunload', common.confirmSave);
        // ローディングアニメ表示のために画面最下部を表示
        await this.$nextTick();
        await common.scrollBottom();
        // CSV取得処理（検索条件, ファイル名）
        await this.getCsv(
          this.csvLimit,
          this.searchCondition,
          this.sortCondition,
          '技能者一覧'
        );
      } catch (error) {
        this.csvGetErrorMessage = error.message;
        this.csvGetErrorFlg = true;
      } finally {
        window.removeEventListener('beforeunload', common.confirmSave);
        this.csvGetLodingFlg = false;
      }
    },
    // ソート検索実行
    async onSortChange(params) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (params[0].type !== 'none') {
          this.sortCondition = params[0];
        } else {
          this.sortCondition = {};
        }
        // 技能者一覧取得API呼出
        await this.callGetWorkersList();
      } catch (error) {
        throw error;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング処理
    async pagination(moveType) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (moveType === 'next' && this.currentPage <= this.maxPage) {
          this.currentPage ++;
          this.inputPage = this.currentPage;
        } else if (moveType === 'back' && this.currentPage >= 1) {
          this.currentPage --;
          this.inputPage = this.currentPage;
        } else {
          return;
        }
        common.scrollTop();
        // 技能者一覧取得API呼出
        await this.callGetWorkersList();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング番号指定
    async paginationInput() {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (this.inputPage > this.maxPage || this.inputPage < 1) {
          this.inputPage = this.currentPage;
          return;
        }
        this.currentPage = this.inputPage;
        common.scrollTop();
        await this.callGetWorkersList();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページングフォーカス外し時処理
    paginationBlue() {
      this.inputPage = this.currentPage;
    },
    // CSV出力用共通関数呼出
   // （検索条件,
   async getCsv(limit, searchCondition, sortCondition, pageName) {
     try {
       const restResourceService = new ApiClient();
       const result =  await restResourceService.getWorkersCsv(
         limit, searchCondition, sortCondition
       );
       // ファイル名用の現在日時
       const currentDate = new Date().getTime();
       const date = common.getToday(currentDate);
       const columns = this.columns;
       const rows = result;
       const fileName = pageName + date;
       common.outputCsv(columns, rows, fileName);
     } catch (error) {
       throw error;
     }
   },
  },
}
