

















































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: '支店登録',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  data() {
    return {
      num: '',
      detail: {
        loginId: '',
        companyId: '',
        branchName: '',
        email: '',
        password: '',
        confirm: '',
      },
      // ローディング
      getLodingFlg: false,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false,  // データ登録時エラーフラグ
      errorMessage: '',
      idCheckFlg: false,
    };
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  async mounted() {
    try {
      const session = this.$store.getters.session;
      this.getLodingFlg = true;
      // セッション情報からログイン事業者IDを取得
      this.detail.companyId = session.companyId;
      await this.callGetNumbering();
      const number =  (('00000' + this.num).slice(-5));
      this.detail.loginId = session.loginId + number;
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  methods: {
    // ナンバリング取得
    async callGetNumbering() {
      const key = this.$store.getters.session.loginId;
      // 採番取API
      const restResourceService = new ApiClient();
      this.num = await restResourceService.getNumbering(key);
    },
    // ログインID重複チェック
    async callGetLoginIdcheck() {
      // 採番取API
      const restResourceService = new ApiClient();
      this.idCheckFlg = await restResourceService.getLoginIdcheck(this.detail.loginId);
    },
    // ナンバリング更新
    async callUpdateNumbering() {
      const key = this.$store.getters.loginId;
      // 採番更新API
      const restResourceService = new ApiClient();
      await restResourceService.updateNumbering(key);
    },
    // 登録ボタン押下時処理
    async confirm(detail) {
      // 採番重複チェック
      try {
        // ボタン非活性化
        this.startProcessing();
        // ローディング表示
        this.postLodingFlg = true;
        // エラーフラグ初期化
        this.postErrorFlg = false;
        // 採番重複チェック
        await this.callGetLoginIdcheck();
        // ログインIDが重複していた場合
        if (this.idCheckFlg) {
          await this.callGetNumbering();
          const number =  (('00000' + this.num).slice(-5));
          this.detail.loginId = this.$store.getters.session.loginId + number;
          return;
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.postErrorFlg = true;
      } finally {
        // ローディング非表示
        this.postLodingFlg = false;
      }
      // ====================入力項目空白削除====================
      detail.branchName = detail.branchName.trim();
      // ====================API呼出====================
      try {
        this.postLodingFlg = true;
        this.postErrorFlg = false;
        // 支店登録API呼出
        const bodies = [];
        const body = {
           branchName: detail.branchName,
           loginId: detail.loginId,
           email: detail.email,
           companyId: detail.companyId,
           password: detail.password,
        };
        bodies.push(body);
        const restResourceService = new ApiClient();
        // 支店新規登録API呼出
        await restResourceService.postBranch(bodies);
        // cognitoへ支店管理者権限アカウントを新規登録
        await this.$cognito.signUp(detail.loginId, detail.email, detail.password, '5');
        await this.$cognito.setUserPassword(detail.loginId, detail.email, detail.password);
        // await this.callUpdateNumbering();
        this.screenTransition();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // 画面遷移
    screenTransition() {
      history.back();
    },
    // チャタリング防止
    async startProcessing() {
      this.processing = true;
    },
    async endProcessing() {
      this.processing = false;
    },
    async isProcessing() {
      return this.processing;
    },
  },
};
