

















































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min, confirmed, regex } from 'vee-validate/dist/rules';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'パスワード変更',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  data() {
    return {
      id: '',
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      errorMessage: '',
      // ローディング
      putLodingFlg: false,  // データ更新時ローディングフラグ
      putErrorFlg: false,   // データ更新時エラーフラグ
      passwordChangeFlg: false, // パスワード変更完了フラグ
    };
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 変更ボタン押下時処理
    async confirm() {
      try {
        this.putErrorFlg = false;
        this.putLodingFlg = true;
        // パスワード変更処理呼び出し
        await this.$cognito.updatePassword(this.oldPassword, this.newPassword);
        this.passwordChangeFlg = true;
      } catch (error) {
        if (error = 'NotAuthorizedException: Incorrect username or password') {
          this.errorMessage = '現在のパスワードが正しくありません。';
        } else if (error = 'LimitExceededException: Attempt limit exceeded, please try after some time') {
          this.errorMessage = '試行制限を超えました。<br>しばらくしてから試してください。';
        } else {
          this.errorMessage = 'データの更新中に問題が発生しました。';
        }
        this.putErrorFlg = true;
      } finally {
        this.putLodingFlg = false;
      }
    },
  },
};
