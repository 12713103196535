






import { Component, Vue } from 'vue-property-decorator';
import Content from '@/components/PasswordReset.vue';

@Component({
  components: {
    Content,
  },
})
export default class PasswordReset extends Vue {}
