






























































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: '支店編集',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  props: {
    id: {
      type: String,
      default: '0',
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      detail: {
        branchName: '',
        loginId: '',
        email: '',
        password: '',
        confirm: '',
        oldEmail: '',
      },
      before: {
        branchName: '',
        email: '',
      },
      // ローディング
      getLodingFlg: true,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ更新時ローディングフラグ
      postErrorFlg: false,  // データ更新時エラーフラグ
      errorMessage: '',
    };
  },
  async mounted() {
    try {
      this.getLodingFlg = true;
      // 支店一意情報取得API呼出
      await this.setBranch(this.detail, this.id, this.before);
      // await new Promise((resolve) => setTimeout(resolve, 300));
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 支店一意情報取得API呼び出し
    setBranch(detail, id, before) {
      try {
        const restResourceService = new ApiClient();
        restResourceService.getBranch(id)
        .then((res) => {
          detail.branchName = res.branchName;
          detail.email = res.email;
          detail.oldEmail = detail.email;
          detail.loginId = res.loginId;
          //before
          before.branchName = res.branchName;
          before.email = res.email;
        });
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // 変更ボタン押下時処理
    async confirm() {
      try {
        this.postErrorFlg = false;
        this.postLodingFlg = true;

        // cognitoPassword更新
        if (this.detail.password) {
          await this.$cognito.setUserPassword(this.detail.loginId, this.detail.email, this.detail.password);
        }

        // 支店更新API呼び出し
        const body = {
          id: this.id,
          branchName: this.detail.branchName,
          companyId: this.$store.getters.session.companyId,
          loginId: this.detail.loginId,
          email: this.detail.email,
        };
        const restResourceService = new ApiClient();
        await restResourceService.putBranch(body);
        if (this.detail.email != this.detail.oldEmail) {
          // メール送信API呼出
          // メールに使用するTemplateData
          const templateData = {
            oldEmail: this.detail.oldEmail,
            newEmail: this.detail.email,
          };
          await restResourceService.changeMail(this.detail.email, templateData);
        }
        // 登録完了
        history.back();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // 支店一覧に戻る
    branchList() {
      history.back();
    },
    // チャタリング防止
    startProcessing() {
      this.processing = true;
    },
    endProcessing() {
      this.processing = false;
    },
    isProcessing() {
      return this.processing;
    },
  },
};
