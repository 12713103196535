




























































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment';
import Modal from './BaseModal.vue';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';
export default {
  title: '現場詳細',
  name: 'SiteDetail',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
    Modal,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      // 表示用データ格納用
      detail: {},
      // CCUS JSON情報
      ccusInf: '',
      ccusInfs: [
        {
          id: '',
          modified: '',
          value: '',
        },
      ],
      ccusInfDeleteModal: false,      // CCUS情報削除モーダル
      selectCcusInfId: '',     // 削除対象CCUS情報ID
      // ローディング
      getLodingFlg: true,     // データ取得時ローディング用フラグ
      getErrorFlg: false,      // データ取得時エラーフラグ
      postLodingFlg: false,    // データ登録時ローディングフラグ
      postErrorFlg: false,     // データ登録時エラーフラグ
      // 入力チェック
      validationErrFlg: false, // 入力チェックエラーフラグ
      validationErrMsg: [],    // 入力チェックエラーメッセージ用配列
      errorMessage: '',
    };
  },
  // 初期処理
  async mounted() {
    try {
      // 現場一意情報取得API呼出
      await this.callGetSite();
      await new Promise((resolve) => setTimeout(resolve, 300));
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 現場一意情報取得API呼出
    async callGetSite() {
      try {
        const restResourceService = new ApiClient();
        const result = await restResourceService.getSite(this.id);
        // エポックミリ秒をyyyy-mm-ddに変換
        result.startDate = common.dateFormatHyphen(result.startDate);
        result.endDate = common.dateFormatHyphen(result.endDate);
        result.createdAt = common.dateFormatHyphen(result.createdAt);
        result.updatedAt = common.dateFormatHyphen(result.updatedAt);

        // 表示用
        this.detail = result;


        // 現場CCUS情報取得処理
        this.ccusInfs = await restResourceService.getCcusSite(this.id);

      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // CCUS情報追加処理
    async ccusInfAdd() {
      // ====================入力項目空白削除====================
      this.ccusInf = this.ccusInf.trim();
      // ====================入力チェック====================
      // 入力チェックメッセージ初期化
      this.validationErrMsg = [];
      const jsonCheckFlg = common.checkJson(this.ccusInf);
      // 郵便番号と住所の妥当性チェック(郵便番号,都道府県,市区町村)
      if (!jsonCheckFlg) {
        this.validationErrMsg.push('CCUS情報のJSONの構成が不正です');
      }
      if (this.validationErrMsg.length !== 0) {
        return;
      }
      try {
        const restResourceService = new ApiClient();
        const body = [{
          version: 1.0, // TODO: version取得方法
          ccusId: this.detail.ccusId,
          value: this.ccusInf,
          modified: '2022-08-15 14:36:25', // TODO: 連携日時の取得方法
        }];
        await restResourceService.postCcusSite(body);
        // 現場一意情報取得API呼出
        await this.callGetSite();
        this.ccusInf = null;
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // CCUS情報削除処理
    async ccusInfDelete() {
      try {
        const restResourceService = new ApiClient();
        await restResourceService.deleteCcusSite(this.selectCcusInf);
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      } finally {
        this.closeModal();
      }
    },
    // CCUS情報削除モーダルを開く
    openModal(ccusInfId) {
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.closeModal);
      this.selectCcusInf = ccusInfId;
      this.ccusInfDeleteModal = true;
    },
    // CCUS情報削除モーダルを閉じる
    closeModal() {
      window.removeEventListener('popstate', this.closeModal);
      this.ccusInfDeleteModal = false;
    },
    // 日付フォーマット適用
    dateFormat(date) {
       return common.dateTimeFormatLog(date);
    },
    // Googleマップを開く
    openGoogleMap() {
      window.open('http://maps.google.co.jp/maps?q=' + encodeURI(this.detail.address));
    },
    // 埋め込みGoogleマップAPI呼び出し
    embeddedGoogleMap() {
    },
    // 現場一覧画面に戻る
    siteList() {
      history.back();
    },
  },
  // updated: function() {
  //   try {
  //     // 緯度経度取得API　Community Geocoder　https://geolonia.com/
  //     getLatLng(this.detail.fullAddress, (latlng) => {
  //       var mymap = L.map('mapid').setView([latlng.lat, latlng.lng], 16);
  //       var marker = L.marker([latlng.lat, latlng.lng]).addTo(mymap);
//         var marker = L.marker([latlng.lat, latlng.lng]).addTo(mymap);
// marker.bindPopup("<p>popup1</p><p>地図表示時に一緒に表示されます。</p>").openPopup();
  //       L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
  //         maxZoom: 18,
  //         attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ',
  //       }).addTo(mymap);
  //     });
  //   } catch (error) {
  //     this.errorMessage = error.message;
  //     throw error;
  //   }
  // },
};
