






















































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import moment from 'moment';
import ApiClient from '../services/ApiClient';
import common from '../services/common';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'お知らせ登録',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  data() {
    return {
      notifiationslistPath: '/notifications', // お知らせ一覧パス
      // 入力項目モデル
      detail: {
        notificationCategory: '', // カテゴリー
        notificationTitle: '', // タイトル
        notificationText: '', // 本文
        notificationLimit: common.getToday(), // 有効期限
      },
      // ドロップダウンリスト
      selectedcategories: '', // 選択お知らせカテゴリ
      optioncategories: [],   // お知らせカテゴリ配列
      // ローディング
      getLodingFlg: false,    // データ取得時ローディング用フラグ
      getErrorFlg: false,     // データ取得時エラーフラグ
      postLodingFlg: false,   // データ登録時ローディングフラグ
      postErrorFlg: false,    // データ登録時エラーフラグ
      processing: false,
      errorMessage: '',
    };
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  async mounted() {
    try {
      this.getLodingFlg = true;
      // お知らせカテゴリ取得API呼出
      this.optioncategories = await this.callGetNotificationCategories();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  methods: {
    // お知らせカテゴリ取得API呼び出し
    async callGetNotificationCategories() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getNotificationCategories();
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 登録ボタン押下時処理
    async confirm() {
      try {
        this.startProcessing();
        this.postLodingFlg = true;
        this.postErrorFlg = false;
        // カレンダーの値をUnixtimeへ変換
        const notificationExpiredAt = common.unixTimeFormat(this.detail.notificationLimit);
        // お知らせ新規登録API呼び出し
        const body = {
          notificationCategoryId: this.selectedcategories,
          title: this.detail.notificationTitle,
          body: this.detail.notificationText,
          expiredAt: notificationExpiredAt,
        };
        const restResourceService = new ApiClient();
        await restResourceService.postNotification(body);
        // お知らせ一覧画面に戻る
        this.screenTransition(this.notifiationslistPath);
      } catch (error) {
      this.errorMessage = error.message;
      this.postErrorFlg = true;
      this.endProcessing();
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    // プルダウン選択内容取得・更新
    changeCategory(value) {
      this.selectedcategories = value.target.value;
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
    // チャタリング防止
    startProcessing() {
      this.processing = true;
    },
    endProcessing() {
      this.processing = false;
    },
    isProcessing() {
      return this.processing;
    },
  },
};
