








































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { VueGoodTable } from 'vue-good-table';
import Modal from './BaseModal.vue';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

export default {
  title: '技能者詳細',
  name: 'WorkerDetail',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    Modal,
    VueLoading,
  },
  props: {
    id: {
      type: String,
      default: '0',
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      deleteModal: false,
      selectAffiliation: { select: {}},
      facePhoto: '',
      detail: {
        // 作業者情報
        name: '',
        gender: '',
        postalCode: '',
        fullAddress: '',
        tel: '',
        // EasyPass2アカウント情報
        email: '',
        // CCUS登録情報
        ccusId: '',
        ccusNo: '',
        healthIns: '未加入',       // 健康保険
        pensionIns: '未加入',      // 年金保険
        employmentIns : '未加入',  // 雇用保険
        kentaikyo: '未加入',       // 建退共
        chutaikyo: '未加入',       // 中退共
        workmansCompIns: '未加入',    // 労災保険
        // 保険加入情報
        insurance: {
          healthInsStatus: 0,       // 健康保険
          pensionInsStatus: 0,      // 年金保険
          employmentInsStatus : 0,  // 雇用保険
          kentaikyoStatus: 0,       // 建退共
          chutaikyoStatus: 0,       // 中退共
          workmansCompInsStatus: 0,    // 労災保険
        },
        // 保有資格を配列で取得？
        licenses: [],
      },

      adminDetail: {
        // 作業者情報
        internalID: '',
        name: '',
        gender: '',
        postalCode: '',
        fullAddress: '',
        tel: '',
        email: '',
        registrationDate: '',
        // EasyPass2アカウント情報
        loginId: '',
        // CCUS登録情報
        ccusId: '',
        ccusNo: '',

        // 所属事業者選択用
        selectedCompany: '',
        // 事業者リスト取得
        optionCompany: [],
        // 主事業者選択用
        selectedMain: '',
        // 筆頭主選択用
        selectedHead: '',
        // 二択選択用
        optionChoice: [
          {
            id: 1,
            name: 'はい',
          },
          {
            id: 0,
            name: 'いいえ',
          },
        ],
      },

      columns: [
        {
          label: '技能者所属会社情報ID',
          field: 'workerCompanyId',
          hidden: true,
        },
        {
          label: '事業者ID',
          field: 'companyId',
          hidden: true,
        },
        {
          label: '事業者名',
          field: 'companyName',
          sortable: true,
        },
        {
          label: '主たる事業者',
          field: 'isPrimary',
          sortable: false,
          formatFn: this.viewFormat,
        },
        {
          label: '筆頭者',
          field: 'isManager',
          sortable: false,
          formatFn: this.viewFormat,
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
        },
      ],
      rows: [],
      // ローディング
      getLodingFlg: true,     // データ取得時ローディング用フラグ
      getErrorFlg: false,      // データ取得時エラーフラグ
      postLodingFlg: false,    // データ登録時ローディングフラグ
      postErrorFlg: false,     // データ登録時エラーフラグ
      putLodingFlg: false,   　// データ更新時ローディングフラグ
      putErrorFlg: false,      // データ更新時エラーフラグ
      deleteLodingFlg: false,  // データ削除時ローディングフラグ
      deleteErrorFlg: false, // データ削除時エラーフラグ
      processing: false,
      errorMessage: '',
      // 入力チェック
      validationErrFlg: false, // 入力チェックエラーフラグ
      validationErrMsg: [],    // 入力チェックエラーメッセージ用配列
    };
  },
  // 初期処理
  async mounted() {
    try {
      // 技能者詳細情報取得
      await this.getWorker(this.id, this.detail, this.adminDetail, this.role);
      if (this.role !== 'admin') {
        // 技能者保険加入情報取得
        await this.getInsurances();
        // 技能者保有資格情報取得
        await this.getLicenses();
      } else {
        // 事業者リスト取得
        await this.getCompaniesList(this.adminDetail);
        // 技能者所属会社情報取得
        await this.getWorkerCompanies(this.id, this.adminDetail, this.rows);
      }
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 事業者リスト取得
    async getCompaniesList(adminDetail) {
      try {
        // 事業者リスト取得API呼出
        const restResourceService = new ApiClient();
        adminDetail.optionCompany = await restResourceService.getCompaniesList();
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // 技能者詳細取得
    async getWorker(id, detail, adminDetail, role) {
      try {
        // 技能者詳細取得API呼出
        const restResourceService = new ApiClient();
        const result = await restResourceService.getWorker(id);
        // 日付フォーマット変換
        const registrationDate = common.dateFormat(result.registrationDate);
        if (role !== 'admin') {
          detail.name = result.workerName;
          detail.gender = result.genderName;
          detail.postalCode = result.postalCode;
          detail.fullAddress = result.fullAddress;
          detail.tel = result.tel;
          detail.email = result.email;
          detail.ccusId = result.ccusWorkerId;
          detail.ccusNo = result.ccusSecurityCode;
          detail.loginId = result.userId;
          const fileName = result.facePhotoName;
          // 顔写真登録済みの場合、顔写真取得
          if (fileName) {
            this.facePhoto = await this.getWorkerFacePhotoUrl(fileName);
          }
        } else {
          adminDetail.id = result.workerId;
          adminDetail.name = result.workerName;
          detail.name = result.workerName;
          adminDetail.fullAddress = result.fullAddress;
          adminDetail.tel = result.tel;
          adminDetail.email = result.email;
          adminDetail.registrationDate = registrationDate;
          adminDetail.ccusId = result.ccusWorkerId;
          adminDetail.ccusNo = result.ccusSecurityCode;
          adminDetail.loginId = result.userId;
        }
        return;
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },

    // 技能者顔写真取得
    async getWorkerFacePhotoUrl(fileName) {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getWorkerFacePhotoUrl(fileName);
      } catch (error) {
        throw error;
      }
    },
    // 技能者顔写真取得URL無効時エラーキャッチ
    imgError() {
      this.facePhoto = '';
      // TODO 画面にエラー表示が必要か
    },

    // 加入保険情報取得
    async getInsurances() {
      try {
      // 加入保険情報取得API呼出
        const restResourceService = new ApiClient();
        this.detail.insurance = await restResourceService.getWorkerInsurances(this.id);
        this.detail.healthIns = this.insCheck(this.detail.insurance.healthInsStatus);
        this.detail.pensionIns = this.insCheck(this.detail.insurance.pensionInsStatus);
        this.detail.employmentIns = this.insCheck(this.detail.insurance.employmentInsStatus);
        this.detail.kentaikyo = this.insCheck(this.detail.insurance.kentaikyoStatus);
        this.detail.chutaikyo = this.insCheck(this.detail.insurance.chutaikyoStatus);
        this.detail.workmansCompIns = this.insCheck(this.detail.insurance.workmansCompInsStatus);
      } catch (error) {
        throw error;
      }
    },
    insCheck(ins) {
      switch (ins) {
        case 0:
          return '未加入';
          break;
        case 1:
          return '加入';
          break;
        case 2:
          return '適用除外';
          break;
        default:
          return '未加入';
          break;
      }
    },
    // 保有資格情報取得
    async getLicenses() {
      try {
      // 保有資格情報取得API呼出
        const restResourceService = new ApiClient();
        this.detail.licenses = await restResourceService.getWorkerLicenses(this.id);
      } catch (error) {
        throw error;
      }
    },
    // 削除押下時処理　技能者所属会社情報削除モーダルを開く
    openDeleteModal(params, selectAffiliation) {
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.closeDeleteModal);
      selectAffiliation.select = params;
      this.deleteModal = true;
    },
    // 所属事業者削除モーダルを閉じる
    closeDeleteModal() {
      window.removeEventListener('popstate', this.closeDeleteModal);
      this.deleteModal = false;
    },
    // 所属事業者リスト取得
    async getWorkerCompanies(id, adminDetail, rows) {
      try {
        // 所属事業者リスト取得API呼出
        const restResourceService = new ApiClient();
        const workerCompanies = await restResourceService.getWorkerCompanies(id);
        for (let i = 0; i < workerCompanies.length; i++) {
          const targetCompany = adminDetail.optionCompany.find((company) =>
            company.companyId === workerCompanies[i].companyId,
          );
          workerCompanies[i].companyName = targetCompany.companyName;
        }
        rows.length = 0;
        for (const workerCompanie of workerCompanies) {
          rows.push(workerCompanie);
        }
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // 所属事業者追加
    async companyAdd(id, adminDetail, rows, validationErrMsg) {
      await this.startProcessing();
      // ====================入力チェック====================
      // 入力チェックメッセージ初期化
      validationErrMsg.length = 0;
      if (adminDetail.selectedCompany === '' ||
          adminDetail.selectedMain === '' ||
          adminDetail.selectedHead === '') {
          validationErrMsg.push('所属、主たる事業者、筆頭者はどれも必須項目です');
      }
      if (validationErrMsg.length !== 0) {
        return;
      }
      try {
        // バリデーション未実装
        const companyId = adminDetail.selectedCompany;
        const isPrimary = adminDetail.selectedMain;
        const isManager = adminDetail.selectedHead;
        const body = {
          workerId: id,
          companyId,
          isPrimary,
          isManager,
        };
        // 所属事業者登録API呼出
        const restResourceService = new ApiClient();
        await restResourceService.postWorkerCompanies(body);
        this.getWorkerCompanies(id, adminDetail, rows);
      } catch (error) {
        this.errorMessage = error.message;
        await this.endProcessing();
        throw error;
      } finally {
        await this.endProcessing();
      }
    },
    // 所属事業者削除実行
    async companyDeleteExecution(id, adminDetail, rows, selectAffiliation, modal) {
      try {
        await this.startProcessing();
        const body = {
          workerCompanyId: selectAffiliation.select.workerCompanyId,
        };
        // 所属事業者削除API呼出
        const restResourceService = new ApiClient();
        await restResourceService.deleteWorkerCompany(body);
        this.getWorkerCompanies(id, adminDetail, rows);
      } catch (error) {
      this.errorMessage = error.message;
      this.endProcessing();
      throw error;
      } finally {
        this.endProcessing();
        this.closeDeleteModal();
      }
    },
    // アクセスログ
    accessLog() {
      this.$router.push({
        name: 'AccessLog',
        params: {
          id: this.adminDetail.loginId,
        },
      });
    },
    // ログイン履歴
    loginLog() {
      this.$router.push({
        name: 'LoginLog',
        params: {
          id: this.adminDetail.loginId,
        },
      });
    },
    // 表示用フォーマット
    viewFormat(value) {
      const res = value === 1 ? '〇' : '×';
      return res;
    },
    // 技能者一覧画面へ戻る
    workersList() {
      history.back();
    },
    // チャタリング防止
    async startProcessing() {
      this.processing = true;
    },
    async endProcessing() {
      this.processing = false;
    },
  },
};
