
























































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';
import common from '../services/common';
import VCalendar from 'v-calendar';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

// saito ここは気にしないで ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T,  R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T,  R>(val: T): ChainedWhen<T,  R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T,  A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T,  A>(fn()) : chain<T,  A>(val),
});
// saito ここは気にしないで ------ end

// CSV出力用列情報
const csvColumns =
 [
  {
    field: 'internalId',
    roles: ['admin'],
  },
  {
    field: 'regSource',
    roles: ['admin'],
  },
  {
    field: 'deviceId',
    roles: ['admin', 'business', 'branch', 'worker'],
  },
];

export default {
  title: '入退場履歴',
  name: 'Entrances',
  components: {
    VueGoodTable,
    VCalendar,
    VueLoading,
  },
  // setup() {
  //   return {
  //   },
  // },
  // 初期表示時のテーブルデータの準備
  data() {
    return {
      selectSearchMode: 'month',
      searchRange: {
        start: moment().add(0, 'months').startOf('month').format('YYYY-MM'),
        end: moment().add(0, 'months').endOf('month').format('YYYY-MM-DD'),
      },
      threeMonths: {
        startOf: new Date(),
        endOf: new Date(),
      },
      ccusSentCondition: {
        start: new Date(),
        end: new Date(),
      },
      ccusSentChangeFlg: false,
      ccusSentConditionErr: new Array(),
      serchFormats: {
        input: ['YYYY-MM-DD'],
      },
      companyId: '',
      branchId: '',
      workerId: '',
      companyNameOptions: [],
      companyNameCondition: [],
      ccusCompanyIdOptions: [],
      ccusCompanyIdCondition: [],
      siteNameOptions: [],
      siteNameCondition: [],
      siteIdOptions: [],
      ccusSiteIdCondition: [],
      workerOptions: [],
      workerNameCondition: [],
      ccusWorkerIdOptions: [],
      ccusWorkerIdCondition: [],
      deviceIdOptions: [],
      deviceIdCondition: [],
      branchNameOptions: [],
      branchNameCondition: [],
      selectedMonth: this.setCurrentMonth(),
      optionMonths: this.setMonthDropDown(),
      selectedTab: 0,
      sentActive: false,
      // チェックボックス選択行データを詰める
      checkRows: [],
      // ローディング
      getLodingFlg: false,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false,  // データ登録時エラーフラグ
      processing: false,
      approveErrorFlg: false,
      errorMessage: '',
      id: 'entrancesTables',
      columns: [
        {
          label: 'ID/登録元',
          field: 'id',
          sortable: true,
          hidden: this.roleCheck(2),
          width: '130px',
        },
        {
          label: '現場名',
          field: 'siteName',
          sortable: true,
          width: '150px',
        },
        {
          label: '現場ID',
          field: 'siteId',
          hidden: true,
        },
        {
          label: '事業者名',
          field: 'companyName',
          sortable: true,
          width: '150px',
          hidden: this.roleCheck(2),
        },
        {
          label: '支店名',
          field: 'branchName',
          sortable: true,
          width: '150px',
        },
        {
          label: '技能者',
          field: 'workerName',
          sortable: true,
          width: '100px',
          hidden: this.roleCheck(1),
        },
        {
          label: 'CCUS技能者ID',
          field: 'ccusWorkerId',
          sortable: true,
          hidden: this.roleCheck(2),
          width: '130px',
        },
        {
          label: 'CCUS現場ID',
          field: 'ccusSiteId',
          sortable: true,
          hidden: this.roleCheck(2),
          width: '130px',
        },
        {
          label: 'CCUS事業者ID',
          field: 'ccusCompanyId',
          sortable: true,
          hidden: this.roleCheck(2),
          width: '130px',
        },
        {
          label: '支店ID',
          field: 'branchId',
          sortable: true,
          width: '154px',
          hidden: true,
        },
        {
          label: '登録元',
          field: 'regSource',
          hidden: true,
        },
        {
          label: '機器ID',
          field: 'deviceId',
          sortable: true,
          hidden: this.roleCheck(2),
          width: '125px',
        },
        {
          label: '日付',
          field: 'date',
          sortable: false,
          // type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'mm月dd日',
          width: '65px',
          formatFn: this.callDateFormat,
        },
        {
          label: '入退場受信',
          field: 'updatedAt',
          sortable: false,
          hidden: this.roleCheck(2),
          width: '90px',
        },
        {
          label: '入場',
          field: 'start',
          sortable: false,
          width: '40px',
        },
        {
          label: '退場',
          field: 'end',
          sortable: false,
          width: '40px',
        },
        {
          label: '承認',
          field: 'approved',
          sortable: true,
          hidden: true,
          width: '40px',
          formatFn: this.callApprovedFormat,
        },
        {
          label: 'エラーコード',
          field: 'errorDetail',
          sortable: true,
          hidden: true,
          width: '130px',
        },
        {
          label: 'エラー日時',
          field: 'errortedAt',
          sortable: true,
          hidden: true,
          width: '90px',
        },
      ],
      rows: [],
      searchCondition: {},
      sortCondition: {},
      // CSVダウンロード可否フラグ
      csvDlFlg: false,
      // CSVダウンロード処理実行中フラグ
      csvGetLodingFlg: false,
      // CSVダウンロードエラーフラグ
      csvGetErrorFlg: false,
      // CSVダウンロードエラーメッセージ
      csvGetErrorMessage: false,
      // CSV取得上限
      csvLimit: 10000,
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // ソートローディングフラグ
      lodingFlg: false,
    };
  },
  filters: {
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  // 初期処理
  async mounted() {
    const session = this.$store.getters.session;
    // セッション情報からログイン事業者IDを取得
    switch (this.role) {
      // 順に要素を足していくため、break不要
      case 'worker':
        this.workerId = session.workerId;
        this.searchCondition.workerId = this.workerId;
      case 'branch':
        this.branchId = session.branchId;
        this.searchCondition.branchId = this.branchId;
      case 'business':
        this.companyId = session.companyId;
        this.searchCondition.companyId = this.companyId;
      default:
    }
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // 月始月末日付取得
      let startEnd = this.getCurrentMonth();
      this.threeMonths = {
        startOf: new Date(moment().add(-2, 'months').startOf('month').format('YYYY-MM')).getTime(),
        endOf: new Date(startEnd.endOf).getTime(),
      };
      // 検索条件を取得
      this.companyNameCondition = common.getUrlParam('companyName', 0);
      this.ccusCompanyIdCondition = common.getUrlParam('companyId', 0);
      this.siteNameCondition = common.getUrlParam('siteName', 0);
      this.ccusSiteIdCondition = common.getUrlParam('siteId', 0);
      this.workerNameCondition = common.getUrlParam('worker', 0);
      this.ccusWorkerIdCondition = common.getUrlParam('workerId', 0);
      this.deviceIdCondition = common.getUrlParam('deviceId', 0);
      this.branchNameCondition = common.getUrlParam('branchName', 0);
      this.getUrlUniqueCondition();
      if(this.role === 'business' || this.role === 'admin') {
        // 事業者リスト取得API呼出
        this.companyNameOptions = await this.callGetCompaniesNameList();
        // 支店リスト取得API呼出
        this.branchNameOptions = await this.callGetBranchsNameList();
      }
      if(this.role === 'business' || this.role === 'branch') {
        // 現場リスト取得API呼出
        this.siteNameOptions = await this.callGetSitesNameList();
      }
      if (this.selectSearchMode === 'range') {
        startEnd = this.searchRange;
      }
      // 入退場データ取得、ラジオボタン&タブ反映、検索条件フィルター
      this.changeTab(this.selectedTab);
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
    getPaginateCount () {
      return Math.ceil(this.maxCount / this.limit);
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },

    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    // CCUS送信期間初回検索回避フラグ変更
    ccusSentCondition() {
      if (!this.ccusSentChangeFlg) {
        this.ccusSentChangeFlg = true;
      }
    },
  },
  methods: {
    // 入退場履歴一覧取得API呼び出し
    async callGetEntrances() {
      try {
        const restResourceService = new ApiClient();
        let result = await restResourceService.getEntrances(
          this.limit,
          this.currentPage,
          this.searchCondition,
          this.sortCondition,
        );
        result.entrances.forEach((resultRow, index) => {
          result.entrances[index].updatedAt = common.dateTimeFormatEpoch(resultRow.updatedAt);
          result.entrances[index].createdAt = common.dateTimeFormat(common.dateFormatHyphen(resultRow.createdAt));
          result.entrances[index].date = common.dateFormatHyphen(resultRow.date);
          if (resultRow.start) {
            result.entrances[index].start = common.dateTimeFormatEpoch(resultRow.start);
          }
          if (resultRow.end) {
            result.entrances[index].end = common.dateTimeFormatEpoch(resultRow.end);
          }
        });
        this.rows = result.entrances;
        this.maxCount = result.maxCount;
        this.maxPage = Math.ceil(this.maxCount / this.limit);
        // 初期テーブルデータをセット
        this.csvDlFlg  = (this.rows.length > 0) ? true : false;
        this.checkboxDisabled();
      } catch (error) {
        throw error;
      }
    },
    // 日時値を日時（YYYY年MM月DD日）に変換
    callDateFormat(value) {
      return common.dateMonthFormat(value);
    },
    // 承認の１／０を済／未に変換
    callApprovedFormat(value) {
      return common.oneZeroFlgFormat(value,'済','未');
    },
    // 事業者リスト取得API呼び出し
    async callGetCompaniesNameList() {
      try {
        switch (this.role) {
          case 'admin':
            const restResourceService = new ApiClient();
            return await restResourceService.getCompaniesNameList();
            break;
          case 'business':
            break;
          case 'branch':
            break;
          case 'worker':
            break;
          default:
            break;
        }
      } catch (error) {
        throw error;
      }
    },

    // 現場名ドロップダウンリスト取得
    async callGetSitesNameList() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getSitesNameList(this.companyId, this.branchId,this.workerId);
      } catch (error) {
        throw error;
      }
    },

      // 支店名ドロップダウンリスト取得
    async callGetBranchsNameList() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getBranchesNameList(this.companyId);
      } catch (error) {
        throw error;
      }
    },

    // ラジオボタン変更時処理
    async clickRadio() {
      // 月指定選択時、期間指定入力欄を非活性にする
      if (this.selectSearchMode === 'month') {
        document.getElementById('month').removeAttribute('disabled');
        document.getElementById('range').setAttribute('disabled', 'disabled');
        await this.dropChange();
        const url = new URL(location.href);
        url.searchParams.delete('selectSearchMode');
        url.searchParams.delete('searchRangeStart');
        url.searchParams.delete('searchRangeEnd');
        // URLデコード
        url.search = decodeURIComponent(url.search);
        // URLを書き換える
        history.replaceState("", "", url.toString());
      // 期間定選択時、月指定入力欄を非活性にする
      } else if (this.selectSearchMode === 'range') {
        document.getElementById('range').removeAttribute('disabled');
        document.getElementById('month').setAttribute('disabled', 'disabled');
        this.rangeChange();
        common.setUrlParam('selectSearchMode', this.selectSearchMode, 2);
        common.setUrlParam('searchRangeStart', moment(this.searchRange.start).format('YYYY-MM-DD'), 2);
        common.setUrlParam('searchRangeEnd', moment(this.searchRange.end).format('YYYY-MM-DD'), 2);
      }
    },

    // ラジオボタン文字列選択時
    async clickRadioText(type) {
      if(type === 'month' && this.selectSearchMode !== 'month') {
        this.selectSearchMode = 'month';
        this.clickRadio();
      } else if(type === 'range' && this.selectSearchMode !== 'range') {
        this.selectSearchMode = 'range';
        this.clickRadio();
      }
    },

    // 事業者ID検索用タグ追加処理
    addCompanyIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.ccusCompanyIdCondition.push(tag);
      this.ccusCompanyIdOptions.push(tag);
    },

    // 現場ID検索用タグ追加処理
    addSiteIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.ccusSiteIdCondition.push(tag);
      this.siteIdOptions.push(tag);
    },

    // 現場名検索用タグ追加処理
    addSiteNameTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.siteNameCondition.push(tag);
      this.siteNameOptions.push(tag);
    },

    // 技能者検索用タグ追加処理
    addWorkerTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.workerNameCondition.push(tag);
      this.workerOptions.push(tag);
    },
    // 技能者ID検索用タグ追加処理
    addWorkerIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.ccusWorkerIdCondition.push(tag);
      this.ccusWorkerIdOptions.push(tag);
    },

    // 機器ID検索用タグ追加処理
    addDeviceIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.deviceIdCondition.push(tag);
      this.deviceIdOptions.push(tag);
    },
    // チェックボックス操作時の処理
    selectionChanged(checkRows) {
      this.checkRows = checkRows.selectedRows;
      // 1件以上選択時に送信ボタンを活性化
      if (this.checkRows.length !== 0) {
        this.sentActive = true;
      //  未選択時に送信ボタンを非活性
      } else {
        this.sentActive = false;
      }
    },

    // 現在の月をDate型で返却
    setCurrentMonth() {
      return moment().add(0, 'months').format('YYYY-MM');
    },

    // 月選択用の月のリストを現在から3か月分詰め返却
    setMonthDropDown() {
      const months = [];
      for (let i = -2; i < 1; i++) {
        months.push(
          { id: i, val: moment().add(i, 'months').format('YYYY-MM') },
        );
      }
      return months;
    },

    // 権限によって表示列を非表示にする処理
    roleCheck(val) {
      const session = this.$store.getters.session;
      // 運営管理者の場合は全て表示
      if (session.role === 'admin') {
        return false;
      // 事業者の場合
      } else if (session.role === 'business' || session.role === 'branch') {
        if (val <=   1 ) {
          return false;
        } else {
          return true;
        }
      // 支店管理者の場合
    } else if (session.role === 'branch') {
        if (val <=  1 ) {
          return false;
        } else {
          return true;
        }
      // 技能者の場合
      } else {
        if (val === 0) {
          return false;
        } else {
          return true;
        }
      }
    },

    // vue-good-table の:select-optionsを切り替える
    setCheckbox() {
      if (this.selectedTab === 2 || this.selectedTab === 3) {
        return true;
      }
      return false;
    },

    // 選択中の月の月始月末日付取得
    getCurrentMonth() {
      const startEnd = {
        startOf: moment(this.selectedMonth).add(0, 'months').startOf('month').format('YYYY-MM-DD'),
        endOf: moment(this.selectedMonth).add(0, 'months').endOf('month').format('YYYY-MM-DD'),
      };
      return startEnd;
    },

    // 月指定のドロップダウン切り替え時に呼び出される
    async dropChange() {
      const startEnd = this.getCurrentMonth();
      this.setTable(startEnd);
    },

    // 期間指定の選択範囲切り替え時に呼び出される
    async rangeChange() {
      const startEnd = {
        startOf: moment(this.searchRange.start).format('YYYY-MM-DD'),
        endOf: moment(this.searchRange.end).format('YYYY-MM-DD'),
      };
      this.setTable(startEnd);
    },

    // タブ切り替え時処理
    changeTab(val) {
      this.selectedTab = val;
      switch (val) {
        // 入退場履歴タブ
        case 0:
        // CCUS送信済みタブ
        case 1:
          // 承認：非表示 エラーコード：非表示　エラー日時：非表示
          this.columns[16].hidden = true;
          this.columns[17].hidden = true;
          this.columns[18].hidden = true;
          break;
        // CCUS未送信タブ
        case 2:
          // 承認：表示 エラーコード：非表示　エラー日時：非表示
          this.columns[16].hidden = false;
          this.columns[17].hidden = true;
          this.columns[18].hidden = true;
          break;
        // CCUSエラータブ
        case 3:
          // 承認：非表示 エラーコード：表示　エラー日時：表示
          this.columns[16].hidden = true;
          this.columns[17].hidden = false;
          this.columns[18].hidden = false;
          break;
        default:
          break;
      };
      this.search();
      common.setUrlParam('selectedTab', this.selectedTab, 2);
    },

    // 検索ボタン押下時
    async search() {
      // ページをリセット
      this.currentPage = 1;
      this.inputPage = this.currentPage;
      // 検索条件をURLに詰める
      common.setUrlParam('companyName', this.companyNameCondition, 0);
      common.setUrlParam('companyId', this.ccusCompanyIdCondition, 0);
      common.setUrlParam('siteName', this.siteNameCondition, 0);
      common.setUrlParam('siteId', this.ccusSiteIdCondition, 0);
      common.setUrlParam('worker', this.workerNameCondition, 0);
      common.setUrlParam('workerId', this.ccusWorkerIdCondition, 0);
      common.setUrlParam('deviceId', this.deviceIdCondition, 0);
      common.setUrlParam('branchName', this.branchNameCondition, 0);
      if(this.selectedTab === 1 && this.ccusSentChangeFlg) {
        common.setUrlParam('ccusSent', this.ccusSentCondition, 1);
      } else if(this.selectedTab !== 1) {
        common.setUrlParam('ccusSent', null, 1);
      }
      // 月指定検索の場合
      if (this.selectSearchMode === 'month') {
        await this.dropChange();
      // 期間指定検索の場合
      } else if (this.selectSearchMode === 'range') {
        await this.rangeChange();
      }
    },

    // 検索期間を返却
    getSearchPeriod() {
      return {
        startOf: moment(this.searchDateStart).format('YYYY-MM-DD'),
        endOf: moment(this.searchDateEnd).format('YYYY-MM-DD'),
      };
    },

    // 一覧をセット
    async setTable(startEnd) {
      // 対象月・期間でフィルター
      this.searchCondition.startEnd = {
        start: new Date(startEnd.startOf).getTime(),
        end: new Date(startEnd.endOf).getTime(),
      };
      // 対象タブでフィルター
      this.searchCondition.tab = this.selectedTab;
      this.setCondition();
      try {
        // ローディング表示
        this.getLodingFlg = true;
        this.errorMessage = '';
        await this.callGetEntrances();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.getLodingFlg = false;
      }
    },

    // チェックボックス非選択対象化
    checkboxDisabled() {
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i].approved === 1) {
          this.$set(this.rows[i], 'vgtDisabled', true);
        }
      }
      return this.rows;
    },

    // 一覧上部の検索条件を一覧へ反映
    setCondition(rows) {
      this.ccusSentConditionErr = [];
      // 検索条件リセット
      if (this.searchCondition.companyName) delete this.searchCondition.companyName;
      if (this.searchCondition.ccusCompanyId) delete this.searchCondition.ccusCompanyId;
      if (this.searchCondition.siteName) delete this.searchCondition.siteName;
      if (this.searchCondition.ccusSiteId) delete this.searchCondition.ccusSiteId;
      if (this.searchCondition.workerName) delete this.searchCondition.workerName;
      if (this.searchCondition.ccusWorkerId) delete this.searchCondition.ccusWorkerId;
      if (this.searchCondition.deviceId) delete this.searchCondition.deviceId;
      if (this.searchCondition.branchName) delete this.searchCondition.branchName;
      if (this.searchCondition.ccusSent) delete this.searchCondition.ccusSent;
      // 検索条件が設定されていない場合はreturn
      // if (this.companyNameCondition.length === 0 && this.ccusCompanyIdCondition.length === 0 &&
      //   this.siteNameCondition.length === 0 && this.ccusSiteIdCondition.length === 0 &&
      //   this.workerNameCondition.length === 0 && this.ccusWorkerIdCondition.length === 0 &&
      //   this.deviceIdCondition.length === 0 && this.branchNameCondition.length === 0) {
      //   if (this.selectedTab !== 2) {
      //     return;
      //   // 初回CCUS送信済みタブ表示時の検索回避（現在日時検索がかかってしまうため）
      //   // 月指定の変更・期間指定の変更時の値で常にCCUS送信期間の値を更新できればよいが、
      //   // v-date-pickerの仕様上v-model変更では値が反映されないためやむを得ず
      //   } else if (!this.ccusSentChangeFlg) {
      //     return;
      //   }
      // }

      // 検索条件：事業者名（選択）
      if (this.companyNameCondition.length !== 0) {
        this.searchCondition.companyName = [];
        for (const companyName of this.companyNameCondition) {
          this.searchCondition.companyName.push(companyName.companyName);
        }
      }
      // 検索条件：事業者ID(部分一致)
      if (this.ccusCompanyIdCondition.length !== 0) {
        this.searchCondition.ccusCompanyId = [];
        for (const ccusCompanyId of this.ccusCompanyIdCondition) {
          this.searchCondition.ccusCompanyId.push(ccusCompanyId.name);
        }
      }
      // 検索条件：現場名（選択）
      if (this.siteNameCondition.length !== 0) {
        this.searchCondition.siteName = [];
        for (const siteName of this.siteNameCondition) {
          this.searchCondition.siteName.push(siteName.name);
        }
      }
      // 検索条件：現場ID(部分一致)
      if (this.ccusSiteIdCondition.length !== 0) {
        this.searchCondition.ccusSiteId = [];
        for (const ccusSiteId of this.ccusSiteIdCondition) {
          this.searchCondition.ccusSiteId.push(ccusSiteId.name);
        }
      }
      // 検索条件：技能者(部分一致)
      if (this.workerNameCondition.length !== 0) {
        this.searchCondition.workerName = [];
        for (const workerName of this.workerNameCondition) {
          this.searchCondition.workerName.push(workerName.name);
        }
      }
      // 検索条件：技能者ID(部分一致)
      if (this.ccusWorkerIdCondition.length !== 0) {
        this.searchCondition.ccusWorkerId = [];
        for (const ccusWorkerId of this.ccusWorkerIdCondition) {
          this.searchCondition.ccusWorkerId.push(ccusWorkerId.name);
        }
      }
      // 検索条件：機器ID(部分一致)
      if (this.deviceIdCondition.length !== 0) {
        this.searchCondition.deviceId = [];
        for (const deviceId of this.deviceIdCondition) {
          this.searchCondition.deviceId.push(deviceId.name);
        }
      }
      // 検索条件：支店名（選択）
      if (this.role !== 'branch') {
        if (this.branchNameCondition.length !== 0) {
          this.searchCondition.branchName = [];
          for (const branch of this.branchNameCondition) {
            this.searchCondition.branchName.push(branch.branchName);
          }
        }
      }
      // 検索条件：CCUS送信期間(部分一致)
      if (this.selectedTab === 1 && this.ccusSentChangeFlg) {
        this.searchCondition.ccusSent = {};
        const ccusSentConditionErr = new Array();
        // CCUS送信期間条件の日付フォーマットを変更
        const startEnd = {
          startOf : moment(this.ccusSentCondition.start).format('YYYY-MM-DD'),
          endOf : moment(this.ccusSentCondition.end).format('YYYY-MM-DD'),
        };
        // 月指定検索の場合
        if (this.selectSearchMode === 'month') {
          const monthStartEnd = this.getCurrentMonth();
          // CCUS送信期間条件が月指定の検索期間を超えていた場合、エラーメッセージを返却
          if (monthStartEnd.startOf > startEnd.startOf ||
              monthStartEnd.endOf < startEnd.endOf) {
            ccusSentConditionErr.push('CCUS送信期間は月指定の範囲内で設定して下さい。');
            this.ccusSentConditionErr = ccusSentConditionErr;
          }
        // 期間指定検索の場合
        } else if (this.selectSearchMode === 'range') {
          const rangeStartEnd = {
            startOf : moment(this.searchRange.start).format('YYYY-MM-DD'),
            endOf : moment(this.searchRange.end).format('YYYY-MM-DD'),
          };
          // CCUS送信期間条件が期間指定の検索期間を超えていた場合、エラーメッセージを返却
          if (rangeStartEnd.startOf > startEnd.startOf ||
              rangeStartEnd.endOf < startEnd.endOf) {
            ccusSentConditionErr.push('CCUS送信期間は期間指定の範囲内で設定して下さい。');
            this.ccusSentConditionErr = ccusSentConditionErr;
          }
        }
        // CCUS送信期間条件に問題が無い場合、検索を実行
        // 問題があった場合、CCUS送信期間条件を無視する
        if (this.ccusSentConditionErr.length === 0) {
          this.searchCondition.ccusSent.start = new Date(this.ccusSentCondition.start).getTime();
          this.searchCondition.ccusSent.end = new Date(this.ccusSentCondition.end).getTime();
        }
      }
    },

    // 承認ボタン押下時処理
    async approveUnsent() {
      this.postErrorFlg = false;
      this.approveErrorFlg = false;
      const entranceIds = [];
      // 既に承認済みのものは対象外とする
      for(const checkRow of this.checkRows) {
        if(checkRow.approved === 0) {
          entranceIds.push(checkRow.id);
        }
      }
      // 承認済みのものしかない場合には処理を終了
      if(entranceIds.length === 0) {
        this.approveErrorFlg = true;
        return;
      }
      this.postLodingFlg = true;
      this.errorMessage = '';
      try {
        // チェックボックス選択行からIDを配列で取得する
        const restResourceService = new ApiClient();
        // CCUS未送信承認更新API呼び出し
        await restResourceService.patchEntranceUnsent(entranceIds);

        let getStartEnd;
        // 月指定の場合は直近3か月、期間指定の場合は指定期間でデータを再取得
        if(this.selectSearchMode === 'month') {
          getStartEnd = this.threeMonths;
        } else if(this.selectSearchMode === 'range') {
          getStartEnd = {
            startOf: new Date(moment(this.searchRange.start).format('YYYY-MM-DD')).getTime(),
            endOf: new Date(moment(this.searchRange.end).format('YYYY-MM-DD')).getTime(),
          };
        }
        this.search();
      } catch (error) {
        this.errorMessage = error.message;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },

    // 再送信ボタン押下時処理
    async resendError() {
      try {
        this.postErrorFlg = false;
        this.postLodingFlg = true;
        this.errorMessage = '';
        // チェックボックス選択行からIDを配列で取得する
        const entranceIds = common.getSelectRowIds(this.checkRows);
        const restResourceService = new ApiClient();
        // CCUSエラー再送信API呼び出し
        await restResourceService.patchEntranceError(entranceIds);

        let getStartEnd;
        // 月指定の場合は直近3か月、期間指定の場合は指定期間でデータを再取得
        if(this.selectSearchMode === 'months') {
          getStartEnd = this.threeMonths;
        } else if(this.selectSearchMode === 'range') {
          getStartEnd = {
            startOf: new Date(moment(this.searchRange.start).format('YYYY-MM-DD')).getTime(),
            endOf: new Date(moment(this.searchRange.end).format('YYYY-MM-DD')).getTime(),
          };
        }
        // 入退場一覧再取得
        await this.callGetEntrances();
        this.search();
      } catch (error) {
        this.errorMessage = error.message;
        this.postErrorFlg = true;
        this.endProcessing();
      } finally {
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },

    // CSV出力用共通関数呼出
    async callOutputCsv() {
      try {
        this.csvGetLodingFlg = true;
        window.addEventListener('beforeunload', common.confirmSave);
        // ローディングアニメ表示のために画面最下部を表示
        await this.$nextTick();
        await common.scrollBottom();
        // CSV取得処理
        const restResourceService = new ApiClient();
        const result =  await restResourceService.getEntrancesCsv(
          this.csvLimit,
          this.searchCondition,
          this.sortCondition,
        );
        // エポックミリ秒を日時等表記に変換
        result.forEach((resultRow, index) => {
          result[index].updatedAt = common.dateTimeFormatEpoch(resultRow.updatedAt);
          result[index].createdAt = common.dateTimeFormat(common.dateFormatHyphen(resultRow.createdAt));
          result[index].date = common.dateFormatHyphen(resultRow.date);
          if (resultRow.start) {
            result[index].start = common.dateTimeFormatEpoch(resultRow.start);
          }
          if (resultRow.end) {
            result[index].end = common.dateTimeFormatEpoch(resultRow.end);
          }
        });
        // ファイル名用の現在日時種痘
        const currentDate = new Date().getTime();
        const date = common.getToday(currentDate);
        const columns = this.columns;
        const rows = result;
        const fileName = '入退場履歴一覧' + date;
        common.outputCsv(columns, rows, fileName);
      } catch (error) {
        this.csvGetErrorMessage = error.message;
        this.csvGetErrorFlg = true;
      } finally {
        window.removeEventListener('beforeunload', common.confirmSave);
        this.csvGetLodingFlg = false;
      }
    },
    // 期間指定カレンダー変更時処理
    picker(searchRange){
      this.rangeChange();
      common.setUrlParam('searchRangeStart', moment(searchRange.start).format('YYYY-MM-DD'), 2);
      common.setUrlParam('searchRangeEnd', moment(searchRange.end).format('YYYY-MM-DD'), 2);
    },
    // URL内検索条件取得
    async getUrlUniqueCondition() {
      const url = new URL(location.href);
      // URLに検索条件が追加されていた場合
      if(url.search !== '') {
        // URLから指定された検索項目の検索条件を取得
        const ccusSent = url.searchParams.get('ccusSent');
        if(ccusSent) {
          this.ccusSentCondition = JSON.parse(ccusSent);
        }
        const selectSearchMode = url.searchParams.get('selectSearchMode');
        if(selectSearchMode === 'range') {
          const searchRangeStart = url.searchParams.get('searchRangeStart');
          const searchRangeEnd = url.searchParams.get('searchRangeEnd');
          if(searchRangeStart && searchRangeEnd) {
            this.selectSearchMode = selectSearchMode;
            const searchRange = {
              start: new Date(searchRangeStart).getTime(),
              end: new Date(searchRangeEnd).getTime(),
            };
            this.searchRange = searchRange;
          }
        }
        const selectedTab = url.searchParams.get('selectedTab');
        if(selectedTab) {
          this.selectedTab = Number(selectedTab);
        }
      }
    },
    // チャタリング防止
    startProcessing() {
      this.processing = true;
    },
    endProcessing() {
      this.processing = false;
    },
    isProcessing() {
      return this.processing;
    },
    // ソート検索実行
    async onSortChange(params) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        this.errorMessage = '';
        if (params[0].type !== 'none') {
          this.sortCondition = params[0];
        } else {
          this.sortCondition = {};
        }
        // 入退場履歴一覧取得API呼出
        await this.callGetEntrances();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング処理
    async pagination(moveType) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        this.errorMessage = '';
        if (moveType === 'next' && this.currentPage <= this.maxPage) {
          this.currentPage ++;
          this.inputPage = this.currentPage;
        } else if (moveType === 'back' && this.currentPage >= 1) {
          this.currentPage --;
          this.inputPage = this.currentPage;
        } else {
          return;
        }
        common.scrollTop();
        // 入退場履歴一覧取得API呼出
        await this.callGetEntrances();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング番号指定
    async paginationInput() {
      try {
        // ローディング表示
        this.lodingFlg = true;
        this.errorMessage = '';
        if (this.inputPage > this.maxPage || this.inputPage < 1) {
          this.inputPage = this.currentPage;
          return;
        }
        this.currentPage = this.inputPage;
        common.scrollTop();
        await this.callGetEntrances();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページングフォーカス外し時処理
    paginationBlue() {
      this.inputPage = this.currentPage;
    },
  },
}
;
