



































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';
import Modal from './BaseModal.vue';
import Detail from './SiteDetail.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import common from '../services/common';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T,  R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T,  R>(val: T): ChainedWhen<T,  R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T,  A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T,  A>(fn()) : chain<T,  A>(val),
});
// when使用のための処理 ------ end

export default {
  title: '現場一覧',
  name: 'Site',
  components: {
    VueGoodTable,
    Modal,
    Detail,
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      selectedTab: 1,
      today: this.setToday(),
      companyId: '',
      branchId: '',
      workerId: '',
      // 検索条件用
      companyNameOptions: [],
      companyNameCondition: [],
      ccusCompanyIdOptions: [],
      ccusCompanyIdCondition: [],
      siteNameOptions: [],
      siteNameCondition: [],
      ccusSiteIdOptions: [],
      ccusSiteIdCondition: [],
      branchNameOptions: [],
      branchNameCondition: [],
      supervisorNameOptions: [],
      supervisorNameCondition: [],
      // モーダル表示用フラグ
      detailModal: false,
      deleteModal: false,
      branchChangeModal: false,
      bulkDeleteModal: false,
      columns: [
        {
          label: '現場内部ID',
          field: 'id',
          sortable: false,
          hidden: this.roleCheck(2),
          width: this.columnWidth('id'),
        },
        {
          label: '所属支店',
          field: 'branchName',
          sortable: true,
          hidden: this.roleCheck(3),
          width: this.columnWidth('branchName'),
        },
        {
          label: 'CCUS現場ID',
          field: 'ccusId',
          sortable: false,
          hidden: this.roleCheck(2),
          width: this.columnWidth('ccusId'),
        },
        {
          label: '現場名',
          field: 'siteName',
          sortable: true,
          width: this.columnWidth('siteName'),
        },
        {
          label: '現場管理者',
          field: 'supervisorName',
          sortable: true,
          hidden: this.roleCheck(1),
          width: this.columnWidth('supervisorName'),
        },
        {
          label: '事業者ID',
          field: 'companyId',
          hidden: true,
          width: this.columnWidth('companyId'),
        },
        {
          label: '事業者',
          field: 'companyName',
          sortable: true,
          hidden: this.roleCheck(2),
          width: this.columnWidth('companyName'),
        },
        {
          label: '現場住所',
          field: 'fullAddress',
          sortable: true,
          hidden: this.roleCheck(2),
          width: this.columnWidth('fullAddress'),
        },
        {
          label: '工期（開始）',
          field: 'startDate',
          sortable: true,
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy年mm月dd日',
          hidden: this.roleCheck(0),
          width: this.columnWidth('startDate'),
        },
        {
          label: '工期（終了）',
          field: 'endDate',
          sortable: true,
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy年mm月dd日',
          hidden: this.roleCheck(0),
          width: this.columnWidth('endDate'),
        },
        {
          label: '本日の入場者数',
          field: 'todayInto',
          sortable: true,
          hidden: this.roleCheck(0),
          width: this.columnWidth('todayInto'),
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
          hidden: this.roleCheck(1),
          width: this.columnWidth('operation'),
        },
      ],
      rows: [],
      conditionRow: [],
      // 詳細モーダル用 選択行のデータを詰める
      detail: {
        id: '',
        ccusId: '',
        siteName: '',
        business: '',
        fullAddress: '',
        latLon: '',
        lastUpdated: '',
      },
      // 選択行のデータを詰める
      selectRow: {},
      // チェックボックス選択行データを詰める
      deleteSelectRows: [],
      // 支店選択用
      selectedBranch: '',
      // 支店一覧配列　本来はモーダル読込時に支店リストを取得し、その配列を詰める
      optionBranch: [],
      // ローディング
      getLodingFlg: false,    // データ取得時ローディング用フラグ
      getErrorFlg: false,     // データ取得時エラーフラグ
      deleteLodingFlg: false, // データ削除時ローディングフラグ
      deleteErrorFlg: false,// データ削除時エラーフラグ
      csvDlFlg: false,
      errorMessage: '',
      displayingModal: 0,
      // 削除現場テーブル列
      deleteSiteColumns: [
        {
          label: '現場',
          field: 'siteName',
          sortable: true,
        },
      ],
      searchCondition: {},
      sortCondition: {},
      // CSVダウンロード処理実行中フラグ
      csvGetLodingFlg: false,
      // CSVダウンロードエラーフラグ
      csvGetErrorFlg: false,
      // CSVダウンロードエラーメッセージ
      csvGetErrorMessage: false,
      // CSV取得上限
      csvLimit: 5000,
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // ソートローディングフラグ
      lodingFlg: false,
    };
  },
  filters: {
    moment(value, format) {
      return moment(value).format(format);
    },
  },
  // 初期処理
  async mounted() {
    const session = this.$store.getters.session;
    // セッション情報からログイン事業者IDを取得
    switch (this.role) {
      // 順に要素を足していくため、break不要
      case 'worker':
        this.workerId = session.workerId;
        this.searchCondition.workerId = this.workerId;
      case 'branch':
        this.branchId = session.branchId;
        this.searchCondition.branchId = this.branchId;
      case 'business':
        this.companyId = session.companyId;
        this.searchCondition.companyId = this.companyId;
      default:
    }
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // 現場一覧取得API呼出
      this.rows = await this.callGetSitesList();
      if(this.role === 'business' || this.role === 'admin') {
        // 事業者リスト取得API呼出
        this.companyNameOptions = await this.callGetCompaniesNameList();
        if(this.role === 'business') {
        // 現場リスト取得API呼出
          this.siteNameOptions = await this.callGetSitesNameList();
        }
        // 支店リスト取得API呼出
        this.branchNameOptions = await this.callGetBranchsNameList();
        this.optionBranch = this.branchNameOptions;
      } else if(this.role === 'branch') {
        // 現場リスト取得API呼出
        this.siteNameOptions = await this.callGetSitesNameList();
      }
      // 検索条件を取得
      this.companyNameCondition = common.getUrlParam('companyName', 0);
      this.siteNameCondition = common.getUrlParam('siteName', 0);
      this.branchNameCondition = common.getUrlParam('branchName', 0);
      this.ccusCompanyIdCondition = common.getUrlParam('ccusCompanyId', 0);
      this.ccusSiteIdCondition = common.getUrlParam('ccusSiteId', 0);
      this.supervisorNameCondition = common.getUrlParam('supervisorName', 0);
      this.selectedTab = this.getUrlSelectTab('selectedTab');
      // 検索処理呼出
      await this.search();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
    getPaginateCount () {
      return Math.ceil(this.maxCount / this.limit);
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 現場一覧取得API呼び出し
    async callGetSitesList() {
      this.getErrorFlg = false;
      try {
        this.searchCondition.tab = this.selectedTab;
        const restResourceService = new ApiClient();
        // 現場一覧取得API
        const result = await restResourceService.getSitesList(
          this.limit,
          this.currentPage,
          this.searchCondition,
          this.sortCondition
        );
        // エポックミリ秒をYYYY-MM-DDに変換
        result.sites.forEach((resultRow, index) => {
          result.sites[index].startDate = common.dateFormatHyphen(resultRow.startDate),
          result.sites[index].endDate = common.dateFormatHyphen(resultRow.endDate);
        });
        this.rows = result.sites;
        this.maxCount = result.maxCount;
        this.maxPage = Math.ceil(this.maxCount / this.limit);
        this.csvDlFlg = (this.rows.length > 0) ? true : false;
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 施行中判別用の本日日付取得
    setToday() {
      return moment().format('YYYY-MM-DD');
    },
    // タブ切り替え時に呼び出される
    async changeTab(val) {
      this.selectedTab = val;
      await this.search();
      this.setUrlSelectTab('selectedTab', this.selectedTab, 2);
    },
    // 権限によって表示される列の制御
    roleCheck(val) {
      const role = this.$store.getters.session.role;
      // 運営管理者の場合
      if (role === 'admin') {
        if (val === 2 || val === 1) {
          return false;
        } else {
          return true;
        }
      // 事業者の場合
    } else if (role === 'business') {
        if (val !== 2) {
          return false;
        } else {
          return true;
        }
      // 支店管理者の場合
    } else if (role === 'branch') {
        if (val <=  1 || val === 4) {
          return false;
        } else {
          return true;
        }
      // 技能者の場合
    } else if (role === 'worker') {
        if (val === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 現場新規登録画面へ遷移
    add() {
      this.$router.push('/sites/add');
    },
    // 現場一括登録画面へ遷移
    bulkAdd() {
      this.$router.push('/sites/bulkAdd');
    },
    // 現場編集画面へ遷移
    edit(params) {
      this.$router.push({
        name: 'SiteEdit',
        params: {
          id: params.id,
          name: params.siteName,
        },
      });
    },
    // 入場者一覧画面へ遷移
    atendance(params) {
      this.$router.push({
        name: 'SiteAttendance',
        params: {
          siteName: params.siteName,
          ccusSiteId: params.ccusId,
        },
      });
    },
    // 行選択時 詳細画面遷移or詳細モーダル表示
    openDetail(params) {
      this.displayingModal = 0;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      // 詳細モーダル用 選択行のデータを詰める
      this.selectRow = params.row;
      if (this.$store.getters.session.role === 'admin') {

        this.pageTop();
        this.$router.push({
          name: 'SiteDetail',
          params: {
            id: this.selectRow.id,
            name: this.selectRow.siteName,
          },
        });
      } else {
        // モーダルを表示
        this.detailModal = true;
      }
    },
    // 削除モーダルを開く
    openDeleteModal(params) {
      this.displayingModal = 1;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      this.selectRow = params;
      this.deleteModal = true;
    },
    // 一括削除モーダルを開く
    openBulkDeleteModal() {
      this.displayingModal = 3;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      this.bulkDeleteModal = true;
    },
    // 削除実行
    async deleteExecution(siteId) {
      try {
        this.deleteErrorFlg = false;
        this.deleteLodingFlg = true;
        const siteIdList = [siteId];
        const restResourceService = new ApiClient();
        // 現場削除API呼び出し
        await restResourceService.deleteSiteList(siteIdList);
        // 現場一覧再取得
        const resGetList = await this.callGetSitesList();
        this.rows = resGetList;
      } catch (error) {
        this.errorMessage = error.message;
        this.deleteErrorFlg = true;
      } finally {
        this.closeModal(1);
        this.deleteLodingFlg = false;
      }
    },
    // 詳細モーダル埋め込みGoogleマップAPI呼び出し
    embeddedGoogleMap() {
    },
    // 支店変更モーダルを開く
    openBranchChange(params) {
      this.displayingModal = 2;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      this.selectRow = params;
      this.branchChangeModal = true;
    },
    // 支店変更実行
    async branchChangeExecution() {
      try {
        this.deleteErrorFlg = false;
        this.deleteLodingFlg = true;
        const body = {
          id: this.selectRow.id,
          branchId: this.selectedBranch,
        };
        const restResourceService = new ApiClient();
        // 支店変更API呼び出し
        await restResourceService.updateSiteBranch(body);
        // 現場一覧再取得
        const resGetList = await this.callGetSitesList();
        this.rows = resGetList;
      } catch (error) {
      this.errorMessage = error.message;
      this.deleteErrorFlg = true;
      } finally {
        this.closeModal(2);
        this.deleteLodingFlg = false;
      }
    },
    // モーダルを閉じる
    closeModal(num) {
      window.removeEventListener('popstate', this.modalBack);
      when(num)
        .on((v) => v === 0, () => this.detailModal = false)        // 詳細
        .on((v) => v === 1, () => this.deleteModal = false)        // 削除
        .on((v) => v === 2, () => this.branchChangeModal = false) // 支店変更
        .on((v) => v === 3, () => this.bulkDeleteModal = false);   // 一括削除
    },
    // モーダル表示中ブラウザバック処理
    modalBack() {
      this.closeModal(this.displayingModal);
    },
    // ページ最上部へ戻る
    pageTop() {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    },
    // テーブル列幅設定
    columnWidth(val) {
      let returnWidth = '';
      switch (val) {
        case 'id':
          returnWidth = '50px';
          break;
        case 'branchName':
          returnWidth = '100px';
          break;
        case 'ccusId':
          returnWidth = '110px';
          break;
        case 'siteName':
          returnWidth = '150px';
          break;
        case 'supervisorName':
          returnWidth = '100px';
          break;
        case 'fullAddress':
          returnWidth = '90px';
          break;
        case 'companyId':
          returnWidth = '150px';
          break;
        case 'companyName':
          returnWidth = '150px';
          break;
        case 'startDate':
          returnWidth = '125px';
          break;
        case 'endDate':
          returnWidth = '125px';
          break;
        case 'todayInto':
          returnWidth = '75px';
          break;
        case 'operation':
          if (this.$store.getters.session.role === 'admin') {
            returnWidth = '150px';
          } else if (this.$store.getters.session.role === 'business') {
            returnWidth = '340px';
          } else if (this.$store.getters.session.role === 'branch') {
            returnWidth = '240px';
          }
          break;
      }
      return returnWidth;
    },
    // チェックボックス選択行取得
    selectionChanged(v) {
      this.deleteSelectRows = v.selectedRows;
    },
    // チェックボックス選択行を一括削除
    async bulkDelete() {
      try {
        // チェックボックス選択行からIDを配列で取得する
        const siteIdList = common.getSelectRowIds(this.deleteSelectRows);
        if (siteIdList.length !== 0) {
          // 削除API呼出
          this.deleteErrorFlg = false;
          this.deleteLodingFlg = true;
          const restResourceService = new ApiClient();
          // 現場削除API呼び出し
          await restResourceService.deleteSiteList(siteIdList);
          // 現場一覧再取得
          const resGetList = await this.callGetSitesList();
          this.rows = resGetList;
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.deleteErrorFlg = true;
      } finally {
        // 削除モーダルを閉じる
        this.closeModal(3);
        this.deleteLodingFlg = false;
      }
    },
   // 事業者リスト取得API呼び出し
   async callGetCompaniesNameList() {
     try {
       switch (this.role) {
         case 'admin':
           const restResourceService = new ApiClient();
           return await restResourceService.getCompaniesNameList();
           break;
         case 'business':
           break;
         case 'branch':
           break;
         case 'worker':
           break;
         default:
           break;
       }
     } catch (error) {
      this.errorMessage = error.message;
      throw error;
     }
   },
   // 現場名リスト取得API呼び出し
   async callGetSitesNameList() {
     try {
       const restResourceService = new ApiClient();
       return await restResourceService.getSitesNameList(this.companyId, this.branchId, this.role);
     } catch (error) {
      this.errorMessage = error.message;
      throw error;
     }
   },
   // 支店名リスト取得API呼び出し
   async callGetBranchsNameList() {
     try {
       const restResourceService = new ApiClient();
       return await restResourceService.getBranchesNameList(this.companyId);
     } catch (error) {
      this.errorMessage = error.message;
      throw error;
     }
   },
   // 事業者ID検索用タグ追加処理
    addCompanyIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.ccusCompanyIdCondition.push(tag);
      this.ccusCompanyIdOptions.push(tag);
    },
    // 現場ID検索用タグ追加処理
    addSiteIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.ccusSiteIdCondition.push(tag);
      this.ccusSiteIdOptions.push(tag);
    },
    // 現場名検索用タグ追加処理
    addSiteNameTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.siteNameCondition.push(tag);
      this.siteNameOptions.push(tag);
    },
    // 現場管理者検索用タグ追加処理
    addSupervisorNameTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.supervisorNameCondition.push(tag);
      this.supervisorNameOptions.push(tag);
    },
    // 検索ボタン押下時
    async search() {
      // 一覧取得用の検索条件を詰める
      this.setCondition();
      // 検索条件をURLに詰める
      common.setUrlParam('companyName',this.companyNameCondition, 0);
      common.setUrlParam('siteName',this.siteNameCondition, 0);
      common.setUrlParam('branchName',this.branchNameCondition, 0);
      common.setUrlParam('ccusCompanyId',this.ccusCompanyIdCondition, 0);
      common.setUrlParam('ccusSiteId',this.ccusSiteIdCondition, 0);
      common.setUrlParam('supervisorName',this.supervisorNameCondition, 0);
      try {
        // ローディング表示
        this.getLodingFlg = true;
        this.currentPage = 1;
        this.inputPage = this.currentPage;
        // アクセスログ一覧取得API呼出
        await this.callGetSitesList();
      } catch (error) {
        throw error;
      } finally {
        // ローディング非表示
        this.getLodingFlg = false;
      }
    },
    // 一覧上部の検索条件を一覧へ反映
    setCondition(rows) {
      this.ccusSentConditionErr = [];
      // 検索条件初期化
      if (this.searchCondition.companyName) delete this.searchCondition.companyName;
      if (this.searchCondition.ccusCompanyId) delete this.searchCondition.ccusCompanyId;
      if (this.searchCondition.siteName) delete this.searchCondition.siteName;
      if (this.searchCondition.ccusSiteId) delete this.searchCondition.ccusSiteId;
      if (this.searchCondition.branchName) delete this.searchCondition.branchName;
      if (this.searchCondition.supervisorName) delete this.searchCondition.supervisorName;

      // 検索条件が設定されていない場合はreturn
      if (this.companyNameCondition.length === 0 && this.ccusCompanyIdCondition.length === 0 &&
        this.siteNameCondition.length === 0 && this.ccusSiteIdCondition.length === 0 &&
        this.branchNameCondition.length === 0 && this.supervisorNameCondition.length === 0) {
        return;
      }
      // 検索条件：事業者名（選択）
      if (this.companyNameCondition.length !== 0) {
        this.searchCondition.companyName = [];
        for (const companyName of this.companyNameCondition) {
          this.searchCondition.companyName.push(companyName.companyName);
        }
      }

      // 検索条件：事業者ID(部分一致)
      if (this.ccusCompanyIdCondition.length !== 0) {
        this.searchCondition.ccusCompanyId = [];
        for (const ccusCompanyId of this.ccusCompanyIdCondition) {
          this.searchCondition.ccusCompanyId.push(ccusCompanyId.name);
        }
      }

      // 検索条件：現場名 運営管理者は部分一致、それ以外の場合は選択
      if (this.siteNameCondition.length !== 0) {
        this.searchCondition.siteName = [];
        for (const siteName of this.siteNameCondition) {
          if (this.role !== 'admin') {
            this.searchCondition.siteName.push(siteName.siteName);
          } else {
            this.searchCondition.siteName.push(siteName.name);
          }
        }
      }

      // 検索条件：現場ID(部分一致)
      if (this.ccusSiteIdCondition.length !== 0) {
        this.searchCondition.ccusSiteId = [];
        for (const ccusSiteId of this.ccusSiteIdCondition) {
          this.searchCondition.ccusSiteId.push(ccusSiteId.name);
        }
      }

      // 検索条件：支店名（選択）
      if (this.role !== 'branch') {
        if (this.branchNameCondition.length !== 0) {
          this.searchCondition.branchName = [];
          for (const branchName of this.branchNameCondition) {
            this.searchCondition.branchName.push(branchName.branchName);
          }
        }
      }

      // 検索条件：現場管理者(部分一致)
      if (this.supervisorNameCondition.length !== 0) {
        this.searchCondition.supervisorName = [];
        for (const supervisorName of this.supervisorNameCondition) {
          this.searchCondition.supervisorName.push(supervisorName.name);
        }
      }
    },
    // URL内検索条件追加
    // name: 検索項目名  conditions: 条件
    setUrlSelectTab(name, conditions) {
      const url = new URL(location.href);
      // 検索条件がある場合、URLの検索パラメータに詰める
      if (conditions === 0 || conditions === 2) {
        url.searchParams.set(name, conditions);
      // 検索条件が無い場合、URLの検索パラメータから該当の値を削除
      } else {
        url.searchParams.delete(name);
      }
      // URLデコード
      url.search = decodeURIComponent(url.search);
      // URLを書き換える
      history.replaceState("", "", url.toString());
    },
    // URL内検索条件取得
    getUrlSelectTab(name) {
      const url = new URL(location.href);
      // URLに検索条件が追加されていた場合
      if(url.search !== '') {
        // URLから指定された検索項目の検索条件を取得
        const urlParam = Number(url.searchParams.get(name));
        if(urlParam === 0) {
          return 0;
        }
        if(urlParam === 2) {
          return 2;
        }
      }
      return 1;
    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      try {
        this.csvGetLodingFlg = true;
        window.addEventListener('beforeunload', common.confirmSave);
        // ローディングアニメ表示のために画面最下部を表示
        await this.$nextTick();
        await common.scrollBottom();
        // CSV取得処理（検索条件, ファイル名）
        await this.getCsv(
          this.csvLimit,
          this.searchCondition,
          this.sortCondition,
          '現場一覧'
        );
      } catch (error) {
        this.csvGetErrorMessage = error.message;
        this.csvGetErrorFlg = true;
      } finally {
        window.removeEventListener('beforeunload', common.confirmSave);
        this.csvGetLodingFlg = false;
      }
    },
    // ソート検索実行
    async onSortChange(params) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (params[0].type !== 'none') {
          this.sortCondition = params[0];
        } else {
          this.sortCondition = {};
        }
        // アクセスログ一覧取得API呼出
        await this.callGetSitesList();
      } catch (error) {
        throw error;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング処理
    async pagination(moveType) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (moveType === 'next' && this.currentPage <= this.maxPage) {
          this.currentPage ++;
          this.inputPage = this.currentPage;
        } else if (moveType === 'back' && this.currentPage >= 1) {
          this.currentPage --;
          this.inputPage = this.currentPage;
        } else {
          return;
        }
        common.scrollTop();
        // アクセスログ一覧取得API呼出
        await this.callGetSitesList();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング番号指定
    async paginationInput() {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (this.inputPage > this.maxPage || this.inputPage < 1) {
          this.inputPage = this.currentPage;
          return;
        }
        this.currentPage = this.inputPage;
        common.scrollTop();
        await this.callGetSitesList();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページングフォーカス外し時処理
    paginationBlue() {
      this.inputPage = this.currentPage;
    },
    // CSV出力用共通関数呼出
   // （検索条件,
   async getCsv(limit, searchCondition, sortCondition, pageName) {
     try {
       const restResourceService = new ApiClient();
       const result =  await restResourceService.getSitesCsv(
         limit, searchCondition, sortCondition
       );
       // ファイル名用の現在日時種痘
       const currentDate = new Date().getTime();
       const date = common.getToday(currentDate);
       const columns = this.columns;
       const rows = result;
       const fileName = pageName + date;
       common.outputCsv(columns, rows, fileName);
     } catch (error) {
       throw error;
     }
   },
  },
}
;
