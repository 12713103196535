












































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import ApiClient from '../services/ApiClient';
import common from '../services/common';
import moment from 'moment';
import VCalendar from 'v-calendar';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'アクセスログ',
  name: 'accessLog',
  components: {
    VueGoodTable,
    VueLoading,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      // 検索項目用
      userIdOptions: [],
      userIdCondition: [],
      pathOptions: [],
      pathCondition: [],
      methodOptions: this.setMethodDropDown(),
      methodCondition: [],
      ipOptions: [],
      ipCondition: [],
      searchCondition: {},
      sortCondition: {},

      columns: [
        {
          label: '内部ID',
          field: 'id',
          hidden: true,
        },
        {
          label: 'ユーザーID',
          field: 'userId',
          sortable: true,
          width: '200px',
        },
        {
          label: 'ユーザー',
          field: 'userName',
          hidden: true,
        },
        {
          label: 'リクエストパス',
          field: 'path',
          sortable: true,
          width: '130px',
        },
        {
          label: 'リクエスト種別',
          field: 'method',
          sortable: true,
          hidden: true,
        },
        {
          label: 'IPアドレス',
          field: 'ip',
          width: '120px',
          sortable: true,
          formatFn: common.ipAddressFormat,
        },
        {
          label: '日時',
          field: 'dateTime',
          sortable: true,
          formatFn: this.callDateTimeFormat,
          width: '170px',
        },
        {
          label: 'ユーザーエージェント',
          field: 'ua',
          sortable: true,
          width: '500px',
        },
      ],
      rows: [],
      selectRow: {},
      // データ取得時ローディングフラグ
      getLodingFlg: false,
      // データ取得時エラーフラグ
      getErrorFlg: false,
      // CSVダウンロード可否フラグ
      csvDlFlg: false,
      // CSVダウンロード処理実行中フラグ
      csvGetLodingFlg: false,
      // CSVダウンロードエラーフラグ
      csvGetErrorFlg: false,
      // CSVダウンロードエラーメッセージ
      csvGetErrorMessage: false,
      // CSV取得上限
      csvLimit: 5000,
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // ソートローディングフラグ
      lodingFlg: false,
      // searchRange: {
      //   start: moment().add(0, 'months').startOf('month').format('YYYY-MM'),
      //   end: moment().add(0, 'months').endOf('month').format('YYYY-MM-DD'),
      // },
    };
  },
  // 初期処理
  async mounted() {
    try {
      // 初期検索のユーザID
      this.addUserIdTag(this.id);
      common.setUrlParam('userId', this.userIdCondition, 0);
      // URL検索条件確認
      this.userIdCondition = common.getUrlParam('userId', 0);
      this.methodCondition = common.getUrlParam('method', 0);
      this.pathCondition = common.getUrlParam('path', 0);
      this.ipCondition = common.getUrlParam('ip', 0);
      // 検索処理呼出
      await this.search();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
    getPaginateCount () {
      return Math.ceil(this.maxCount / this.limit);
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // アクセスログ一覧取得API呼び出し
    async callGetAccessLogs() {
      try {
        const restResourceService = new ApiClient();
        // アクセスログ一覧取得API
        const result = await restResourceService.getAccessLogs(
          this.limit,
          this.currentPage,
          this.searchCondition,
          this.sortCondition
        );
        // dateTime列用に登録日のデータを格納
        for (const accessLog of result.accessLogs) {
          accessLog.dateTime = accessLog.createdAt;
        }
        this.rows = result.accessLogs;
        this.maxCount = result.maxCount;
        this.maxPage = Math.ceil(this.maxCount / this.limit);
        // 初期テーブルデータをセット
        this.csvDlFlg  = (this.rows.length > 0) ? true : false;
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // リクエスト種別ドロップダウン
    setMethodDropDown() {
      const TAG_CODE = 'TAGCODE';
      return [
        'GET',
        'POST',
        'PUT',
        'PATCH',
        'DELETE',
      ];
    },
    // ユーザーID検索用タグ追加処理
    addUserIdTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.userIdCondition.push(tag);
      this.userIdOptions.push(tag);
    },
    // リクエストパス検索用タグ追加処理
    addPathTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.pathCondition.push(tag);
      this.pathOptions.push(tag);
    },
    // IPアドレス検索用タグ追加処理
    addIpTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.ipCondition.push(tag);
      this.ipOptions.push(tag);
    },
    // 検索
    async search() {
      // 一覧取得用の検索条件を詰める
      this.setCondition();
      // 検索条件をURLに詰める
      common.setUrlParam('userId', this.userIdCondition, 0);
      common.setUrlParam('method', this.methodCondition, 0);
      common.setUrlParam('path', this.pathCondition, 0);
      common.setUrlParam('ip', this.ipCondition, 0);
      try {
        // ローディング表示
        this.getLodingFlg = true;
        this.currentPage = 1;
        this.inputPage = this.currentPage;
        // アクセスログ一覧取得API呼出
        await this.callGetAccessLogs();
      } catch (error) {
        throw error;
      } finally {
        // ローディング非表示
        this.getLodingFlg = false;
      }
    },
    // 一覧上部の検索条件を詰める
    setCondition() {
      this.rows = [];
      this.searchCondition = {};
      if (this.userIdCondition.length === 0 && this.pathCondition.length === 0 &&
          this.methodCondition.length === 0 && this.ipCondition.length === 0) {
        return;
      }

      // 検索条件：ユーザID(部分一致)
      if (this.userIdCondition.length !== 0) {
        this.searchCondition.userId = [];
        for (const userId of this.userIdCondition) {
          this.searchCondition.userId.push(userId.name);
        }
      }

      // 検索条件：リクエスト種別（選択）
      if (this.methodCondition.length !== 0) {
        this.searchCondition.method = this.methodCondition;
      }

      // 検索条件：リクエストパス(部分一致)
      if (this.pathCondition.length !== 0) {
        this.searchCondition.path = [];
        for (const path of this.pathCondition) {
          this.searchCondition.path.push(path.name);
        }
      }

      // 検索条件：IPアドレス(部分一致)
      if (this.ipCondition.length !== 0) {
        this.searchCondition.ip = [];
        for (const ip of this.ipCondition) {
          this.searchCondition.ip.push(ip.name);
        }
      }
    },
    // 日時値を日時（YYYY年MM月DD日）に変換
    callDateTimeFormat(value) {
      return common.dateTimeFormatLog(value);
    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      try {
        this.csvGetLodingFlg = true;
        window.addEventListener('beforeunload', common.confirmSave);
        // ローディングアニメ表示のために画面最下部を表示
        await this.$nextTick();
        await common.scrollBottom();
        // CSV取得処理（検索条件, ファイル名）
        await this.getCsv(
          this.csvLimit,
          this.searchCondition,
          this.sortCondition,
          'アクセスログ一覧'
        );
      } catch (error) {
        this.csvGetErrorMessage = error.message;
        this.csvGetErrorFlg = true;
      } finally {
        window.removeEventListener('beforeunload', common.confirmSave);
        this.csvGetLodingFlg = false;
      }
    },
    // ソート検索実行
    async onSortChange(params) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (params[0].type !== 'none') {
          this.sortCondition = params[0];
        } else {
          this.sortCondition = {};
        }
        // アクセスログ一覧取得API呼出
        await this.callGetAccessLogs();
      } catch (error) {
        throw error;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング処理
    async pagination(moveType) {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (moveType === 'next' && this.currentPage <= this.maxPage) {
          this.currentPage ++;
          this.inputPage = this.currentPage;
        } else if (moveType === 'back' && this.currentPage >= 1) {
          this.currentPage --;
          this.inputPage = this.currentPage;
        } else {
          return;
        }
        common.scrollTop();
        // アクセスログ一覧取得API呼出
        await this.callGetAccessLogs();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページング番号指定
    async paginationInput() {
      try {
        // ローディング表示
        this.lodingFlg = true;
        if (this.inputPage > this.maxPage || this.inputPage < 1) {
          this.inputPage = this.currentPage;
          return;
        }
        this.currentPage = this.inputPage;
        common.scrollTop();
        await this.callGetAccessLogs();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        // ローディング非表示
        this.lodingFlg = false;
      }
    },
    // ページングフォーカス外し時処理
    paginationBlue() {
      this.inputPage = this.currentPage;
    },

    // CSV出力用共通関数呼出
    // （検索条件,
    async getCsv(limit, searchCondition, sortCondition, pageName) {
      try {
        const restResourceService = new ApiClient();
        const result =  await restResourceService.getAccessLogsCsv(
          limit, searchCondition, sortCondition
        );
        // ファイル名用の現在日時種痘
        const currentDate = new Date().getTime();
        const date = common.getToday(currentDate);
        const columns = this.columns;
        const rows = result;
        const fileName = pageName + date;
        common.outputCsv(columns, rows, fileName);
      } catch (error) {
        throw error;
      }
    },
    // 期間指定カレンダー変更時処理
    // picker(searchRange){
    //   this.rangeChange(true);
    //   common.setUrlParam('searchRangeStart', moment(searchRange.start).format('YYYY-MM-DD'), 2);
    //   common.setUrlParam('searchRangeEnd', moment(searchRange.end).format('YYYY-MM-DD'), 2);
    // },
    // 期間指定の選択範囲切り替え時に呼び出される
    // async rangeChange(getApiFlg) {
    //   const startEnd = {
    //     startOf: moment(this.searchRange.start).format('YYYY-MM-DD'),
    //     endOf: moment(this.searchRange.end).format('YYYY-MM-DD'),
    //   };
    //   if(getApiFlg) {
    //     const getStartEnd = {
    //       startOf: new Date(startEnd.startOf).getTime(),
    //       endOf: new Date(startEnd.endOf).getTime(),
    //     };
    //     // await this.callGetEntrances(getStartEnd);
    //   }
    // },
  },
};
