











































































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';
import Modal from './BaseModal.vue';
import Detail from './NotificationEdit.vue';
import ApiClient from '../services/ApiClient';
import common from '../services/common';
import { VueLoading } from 'vue-loading-template';

// when使用のための処理 ------ start
type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: () => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: () => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) => match<T,  R | A>(val),
  otherwise: <A>(fn: () => A): A | R => val,
});

const chain = <T,  R>(val: T): ChainedWhen<T,  R> => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match(fn()) : chain<T,  A | R>(val),
  otherwise: <A>(fn: () => A) => fn(),
});

const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: () => A) =>
    pred(val) ? match<T,  A>(fn()) : chain<T,  A>(val),
});
// when使用のための処理 ------ end

export default {
  title: 'お知らせ一覧',
  name: 'branch',
  components: {
    VueGoodTable,
    Modal,
    Detail,
    VueLoading,
  },
  data() {
    return {
      detailModal: false,
      deleteModal: false,
      categoryOptions: this.setCategoryDropDown(),
      categoryCondition: [],
      notificationCategories: [],
      // ローディング
      getLodingFlg: false,    // データ取得時ローディング用フラグ
      getErrorFlg: false,     // データ取得時エラーフラグ
      deleteLodingFlg: false, // データ削除時ローディングフラグ
      deleteErrorFlg: false,// データ削除時エラーフラグ
      csvDlFlg: false,
      errorMessage: '',
      columns: [
        {
          label: '内部ID',
          field: 'id',
          sortable: true,
          hidden: true,
        },
        {
          label: 'カテゴリーId',
          field: 'notificationCategoryId',
          sortable: true,
          hidden: true,
        },
        {
          label: 'カテゴリー',
          field: 'notificationCategoryName',
          sortable: true,
          // hidden: this.roleCheck(2),
          width: '110px',
        },
        {
          label: 'タイトル',
          field: 'title',
          sortable: true,
          width: '200px',
        },
        {
          label: '本文',
          field: 'body',
          sortable: false,
          tdClass: 'customText',
          formatFn: this.textShortening,
          width: '335px',
        },
        {
          label: '有効期限',
          field: 'expiredAt',
          sortable: true,
          hidden: this.roleCheck(),
          width: '140px',
          formatFn: this.callDateFormat,
        },
        {
          label: '日付',
          field: 'createdAt',
          sortable: true,
          width: '140px',
          formatFn: this.callDateFormat,
        },
        {
          label: '最終更新日時',
          field: 'updatedAt',
          sortable: true,
          hidden: true,
          formatFn: this.callDateFormat,
        },
        {
          label: '',
          field: 'operation',
          sortable: false,
          hidden: this.roleCheck(2),
          width: '150px',
        },
      ],
      rows: [],
      conditionRow: [],
      // 選択行のデータを詰める
      selectRow: {},
      displayingModal: 0,
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // CSV取得上限
      csvLimit: 10000,
    };
  },
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // お知らせ一覧取得API呼出
      this.rows = await this.callGetNotificationList();
      // 初期テーブルデータをセット
      this.conditionRow = this.rows;
      // お知らせカテゴリ取得API呼出
      this.notificationCategories = await this.callGetNotificationCategories();
      // URL検索条件確認
      this.categoryCondition = common.getUrlParam('category', 0);
      // 検索処理呼出
      this.search();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // お知らせ一覧取得API呼び出し
    async callGetNotificationList() {
      try {
        const restResourceService = new ApiClient();
        const resultRows = await restResourceService.getNotificationList();
        // エポックミリ秒をYYYY-MM-DDに変換
        resultRows.forEach((notification, index) => {
          resultRows[index].createdAt = common.dateFormatHyphen(notification.createdAt),
          resultRows[index].expiredAt = common.dateFormatHyphen(notification.expiredAt);
        });
        return resultRows;
      } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
      throw error;
      }
    },
    // 日時値を日時（YYYY年MM月DD日）に変換
    callDateFormat(value) {
      return common.dateFormat(value);
    },
    // お知らせカテゴリ取得API呼び出し
    async callGetNotificationCategories() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getNotificationCategories();
      } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
      throw error;
      }
    },
    // カテゴリIDからカテゴリ名を返却  Nameが返却されるようになったため、不要か
    categoryConversion(value) {
      const result = this.notificationCategories.find((v) => v.id === value);
      if (result) {
        return result.name;
      }
    },
    // お知らせカテゴリーセット
    setCategoryDropDown() {
      const TAG_CODE = 'TAGCODE';

      return [
        'お知らせ',
        '重要',
        'メンテナンス',
        '障害情報',
      ];
    },
    // 権限によって表示列を非表示にする処理
    roleCheck() {
      // 運営管理者の場合は全て表示
      // computedの変数はこの時点では参照できないため、gettersで直接参照する
      if (this.$store.getters.session.role === 'admin') {
        return false;
      // 運営管理者以外には非表示にする
      } else {
        return true;
      }
    },
    // 検索ボタン押下時
    search() {
      this.setCondition(this.rows);
      // 検索条件をURLに詰める
      common.setUrlParam('category', this.categoryCondition, 0);
      this.csvDlFlg  = (this.conditionRow.length > 0) ? true : false;
    },
    // 一覧上部の検索条件を一覧へ反映
    setCondition(rows) {
      if (this.categoryCondition.length === 0) {
        // 検索条件がない場合、初期データを書き戻す
        this.conditionRow = rows;
        return;
      }
      let newRows = [];
      let tempRows = rows;
      this.conditionRow = [];

      // 検索条件：カテゴリーを検索
      if (this.categoryCondition.length !== 0) {
        newRows = tempRows.filter((v) =>
          this.categoryCondition.includes(v.notificationCategoryName),
        );
        if (newRows.length !== 0) {
          // Array.prototype.push.apply(this.conditionRow, newRows);
          this.conditionRow = newRows;
          tempRows = this.conditionRow;
        }
      }
    },
    // お知らせ新規登録画面へ遷移
    add() {
      this.$router.push('/notifications/add');
    },
    // お知らせ編集画面へ遷移
    edit(params) {
      this.selectRow = params;
      this.$router.push({
        name: 'NotificationEdit',
        params: {
          id: this.selectRow.id,
          mode: 'edit',
        },
      });
    },
    // 削除実行
    async deleteExecution(value) {
      try {
        this.deleteErrorFlg = false;
        this.deleteLodingFlg = true;
        const restResourceService = new ApiClient();
        // お知らせ削除API呼び出し
        await restResourceService.deleteNotification(value);
        // お知らせ一覧再取得
        const resGetList = await this.callGetNotificationList();
        this.rows = resGetList;
        this.conditionRow = this.rows;
      } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
      this.deleteErrorFlg = true;
      } finally {
        this.closeModal(1);
        this.deleteLodingFlg = false;
      }
    },
    // 詳細モーダルを開く
    openDetailModal(params) {
      this.displayingModal = 0;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      this.selectRow = params.row;
      this.detailModal = true;
    },
    // 削除モーダルを開く
    openDeleteModal(params) {
      this.displayingModal = 1;
      window.history.pushState(null, null, null);
      window.addEventListener('popstate', this.modalBack);
      this.selectRow = params;
      this.deleteModal = true;
    },
    // モーダルを閉じる
    closeModal(num) {
      window.removeEventListener('popstate', this.modalBack);
      when(num)
        .on((v) => v === 0, () => this.detailModal = false)   // 詳細
        .on((v) => v === 1, () => this.deleteModal = false);  // 削除
    },
    // モーダル表示中ブラウザバック処理
    modalBack() {
      this.closeModal(this.displayingModal);
    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      const columns = this.columns;
      // 参照元更新回避
      const rows = JSON.parse(JSON.stringify(this.conditionRow));
      let rowCount = 0;
      for(const row of rows) {
        rows[rowCount].body = '"' + row.body + '"';
        rowCount ++;
      }
      // ファイル名用の現在日時
      const currentDate = new Date().getTime();
      const date = await common.getToday(currentDate);
      const fileName = 'お知らせ一覧'+date;
      common.outputCsv(columns, rows, fileName);
    },
    // 本文短縮フォーマット
    textShortening(value) {
      if(value.length < 70) {
        return value;
      }
      let str = value.substr(0, 70);
      str += '...';
      return str;
    },
    // 現在ページ更新
    onPageChange(params) {
      this.currentPage = params.currentPage;
      common.scrollTop();
    },
  },
};
