






























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { validate, ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueGoodTable } from 'vue-good-table';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import fetch from 'node-fetch';
import common from '../services/common';

export default {
  title: '技能者管理-新規登録',
  name: 'SiteAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    VueLoading,
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      workerslistPath: '/workers', // 技能者一覧パス
      companyId: '', // ログイン事業者ID
      num: '',
      // テンプレートファイルパス
      templateFilePath: '/files/worker-bulk-template.csv',
      // テンプレートファイルダウンロード時のファイル名
      templateFileName: 'worker-bulk-template.csv',
      // 記入サンプルファイルパス
      sumpleFilePath: '/files/worker-bulk-sample.csv',
      // 記入サンプルファイルダウンロード時のファイル名
      sumpleFileName: 'worker-bulk-sample.csv',
      // アップロードファイル
      fileName: '',
      upFile: '',
      uploadUrl: '',
      csvData: {},
      // 選択した事業者用
      selectedCompanies: '',
      // 選択用事業者配列　事業者一覧取得APIが実装され次第そちらに変更
      optionCompanies: [],
      errorMessages: [],
      columns:  [
        {
          label: 'CCUS技能者ID',
          field: 'ccusWorkerId',
          width: '130px',
        },
        {
          label: 'CCUS本人確認番号',
          field: 'ccusSecurityCode',
          width: '100px',
        },
        {
          label: '生年月日',
          field: 'birthdate',
          width: '80px',
        },
        {
          label: '姓',
          field: 'lastName',
          width: '125px',
        },
        {
          label: '名',
          field: 'firstName',
          width: '125px',
        },
        {
          label: 'フリガナ（姓）',
          field: 'lastNameKana',
          width: '150px',
        },
        {
          label: 'フリガナ（名）',
          field: 'firstNameKana',
          width: '150px',
        },
        {
          label: '性別',
          field: 'gender',
          width: '125px',
        },
        {
          label: 'ログインID',
          field: 'loginId',
          hidden: true,
        },
        {
          label: 'メールアドレス',
          field: 'email',
          width: '125px',
        },
        {
          label: '電話番号',
          field: 'tel',
          width: '125px',
        },
        {
          label: 'パスワード',
          field: 'password',
          width: '125px',
        },
        {
          label: '郵便番号',
          field: 'postalCode',
          width: '125px',
        },
        {
          label: '都道府県',
          field: 'prefecture',
          width: '125px',
        },
        {
          label: '市区町村',
          field: 'city',
          width: '125px',
        },
        {
          label: '町・番地',
          field: 'town',
          width: '125px',
        },
        {
          label: '建物名/部屋番号',
          field: 'otherAddress',
          width: '150px',
        },
        {
          label: '事業者ID',
          field: 'companyId',
          width: '125px',
        },
      ],
      rows: [],
      // 登録後
      resultColumns:  [
        {
          label: '技能者名',
          field: 'workerName',
          width: '125px',
        },
        {
          label: 'ログインID',
          field: 'loginId',
          width: '125px',
        },
        {
          label: 'メールアドレス',
          field: 'email',
          width: '125px',
        },
        {
          label: 'パスワード',
          field: 'password',
          width: '125px',
        },
        {
          label: 'CCUS技能者ID',
          field: 'ccusId',
          width: '125px',
        },
        {
          label: 'CCUS本人確認番号',
          field: 'ccusSecurityCode',
          width: '80px',
        },
        {
          label: '住所',
          field: 'fullAddress',
          width: '200px',
        },
      ],
      resultRows: [],
      // ローディング
      getLodingFlg: false,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false,  // データ登録時エラーフラグ
      parseFlg: false,
      errorMessage: '',
      // TODO 性別IDリスト取得API要作成？
      optionGender: [
        {
          id: 'ge3138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: '男性',
        },
        {
          id: 'ge2138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: '女性',
        },
        {
          id: 'ge1138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: 'その他',
        },
      ],
    };
  },
  // 初期処理
  async mounted() {
    // セッション情報からログイン事業者IDを取得
    this.companyId = this.$store.getters.session.companyId;
    try {
      // ローディング表示
      this.getLodingFlg = true;
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // アップロードファイル取得
    loadCsvFile(event) {
      this.fileName = event.target.files[0].name;
      this.upFile = event.target.files[0];
      this.$refs.uploadFile.value = null;
      this.parseFlg = false;
    },
    // アップロードして確認ボタン押下時
    async csvExtract() {
      this.postLodingFlg = true;
      const errorMessages = new Array();
      let flg = false;
      try {
        // 抽出CSV配列とエラーメッセージ配列を空にする
        this.csvData.length = 0;
        if (!this.upFile) {
          errorMessages.push('ファイルを選択して下さい');
          return;
        }
        // 取り込んだCSVファイルをオブジェクト配列に変換
        this.$papa.parse(this.upFile, {
          encoding: 'UTF-8',
          header: true,
          complete: this.csvGet,
          error: function(err, file) {
            errorMessages.push('CSVの抽出に失敗しました。ファイルを再度選択して下さい');
            flg = true;
          }
        });
      } catch (error) {
        this.postLodingFlg = false;
        errorMessages.push('CSVの抽出に失敗しました。ファイル内のデータをご確認下さい');
      } finally {
        this.errorMessages = errorMessages;
        new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1);
        }).then(() => {
          this.parseFlg = flg;
          this.scrollBottom();
        });
      }
    },
    // CSVから抽出されたオブジェクト配列を加工
    async csvGet(results) {
      try {
        const errorMessages = new Array();
        // 事業者ID　completeの中では参照できないためローカルの変数に移す
        const selectedCompanies = this.selectedCompanies;
        const restResourceService = new ApiClient();
        this.csvData = results.data;
        if (this.csvData.length === 0) {
          errorMessages.push('登録データが存在しません。ファイル内のデータをご確認下さい。');
        }
        for (let i = 0; i < this.csvData.length; i++) {
          // 選択した事業者の事業者IDをプロパティとして追加
          this.csvData[i].companyId = selectedCompanies;
          // 必要情報が欠けた行が存在した場合、エラーメッセージを詰める
          if (!this.csvData[i].lastName && !this.csvData[i].firstName && !this.csvData[i].lastNameKana &&
          !this.csvData[i].firstNameKana && !this.csvData[i].gender && !this.csvData[i].nationality && !this.csvData[i].password) {
            errorMessages.push('必要な列情報がありません。フォーマットを確認して下さい。');
            break;
          }

          const ccusWorkerId = common.trimSpace(this.csvData[i].ccusWorkerId);
          await validate(ccusWorkerId, 'digits:14', {
            name: 'CCUS技能者ID（ccusWorkerId）',
            values: {
              ccusWorkerId,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const ccusSecurityCode = common.trimSpace(this.csvData[i].ccusSecurityCode);
          await validate(ccusSecurityCode, 'digits:4|required_CcusWorker_if:ccusWorkerId', {
            name: 'CCUS技能者本人確認番号（ccusSecurityCode）',
            values: {
              ccusWorkerId,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const birthdate = common.trimSpace(this.csvData[i].birthdate);
          await validate(birthdate, 'birthDay|required_CcusWorker_if:ccusWorkerId', {
            name: '生年月日（birthdate）',
            values: {
              ccusWorkerId,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const lastName = common.trimSpace(this.csvData[i].lastName);
          await validate(lastName, 'required|max:32|alpha', {
            name: '姓（lastName）',
            values: {
              lastName,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const firstName = common.trimSpace(this.csvData[i].firstName);
          await validate(firstName, 'required|max:32|alpha', {
            name: '名（firstName）',
            values: {
              firstName,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const lastNameKana = common.trimSpace(this.csvData[i].lastNameKana);
          await validate(lastNameKana, 'required|max:32|furigana:lastNameKana', {
            name: '姓(フリガナ)（lastNameKana）',
            values: {
              lastNameKana,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const firstNameKana = common.trimSpace(this.csvData[i].firstNameKana);
          await validate(firstNameKana, 'required|max:32|furigana:firstNameKana', {
            name: '名(フリガナ)（firstNameKana）',
            values: {
              firstNameKana,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          let gender = common.trimSpace(this.csvData[i].gender);
          await validate(gender, 'required', {
            name: '性別（password）',
            values: {
              gender,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });
          gender = this.optionGender.find((gender) => gender.name === common.trimSpace(this.csvData[i].gender));
          if (!gender) {
            errorMessages.push((i + 1) + '行目 性別は"男性","女性","その他"のいずれかで入力して下さい。');
            continue;
          }
          this.csvData[i].genderId = gender.id;

          const tel = common.trimSpace(this.csvData[i].tel);
          await validate(tel, 'min:10|max:14|numeric:tel', {
            name: '電話番号（tel）',
            values: {
              tel,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const email = common.trimSpace(this.csvData[i].email);
          await validate(email, 'max:255|emailVaridate', {
            name: 'メールアドレス（email）'
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const postalCode = common.trimSpace(this.csvData[i].postalCode);
          await validate(postalCode, 'required|digits:7|addressFlg:postalCode', {
            name: '郵便番号（postalCode）',
            values: {
              postalCode,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const prefecture = common.trimSpace(this.csvData[i].prefecture);
          await validate(prefecture, 'required', {
            name: '都道府県（prefecture）',
            values: {
              prefecture,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const city = common.trimSpace(this.csvData[i].city);
          await validate(city, 'required|max:255', {
            name: '市区町村（city）',
            values: {
              city,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const town = common.trimSpace(this.csvData[i].town);
          await validate(town, 'required|max:255', {
            name: '町・番地（town）',
            values: {
              town,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const otherAddress = this.csvData[i].otherAddress;
          await validate(otherAddress, 'max:255', {
            name: '建物名/部屋番号（otherAddress）',
            values: {
              otherAddress,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });


          const nationality = common.trimSpace(this.csvData[i].nationality);
          await validate(nationality, 'required', {
            name: '国籍（nationality）',
            values: {
              nationality,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });
          // 国籍チェック　日本：1 それ以外：2
          if (nationality === '日本') {
            this.csvData[i].nationality = 1;
          } else {
            this.csvData[i].nationality = 2;
          }
          // 国籍が日本の場合、姓名 それ以外の場合名姓となるようにフルネームを結合
          if (this.csvData[i].nationality === 1) {
            this.csvData[i].workerName = lastName + firstName;
          } else {
            this.csvData[i].workerName = firstName + lastName;
          }
          this.csvData[i].companyId = this.companyId;

          const password = common.trimSpace(this.csvData[i].password);
          await validate(password, 'required|min:8|max:64|password', {
            name: 'パスワード（password）',
            values: {
              password,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });
        }
        // エラーメッセージが詰められていた場合、処理を中断する
        if (errorMessages.length !== 0) {
          this.errorMessages = errorMessages;
          console.log(this.errorMessages);
          this.scrollBottom();
          return;
        }
        this.rows = this.csvData;
        this.scrollBottom();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
      }
    },
    // DOM更新待機後スクロール
    async scrollBottom() {
      await this.$nextTick();
      common.scrollBottom();
    },
    // ナンバリング取得
    async callGetNumbering() {
      // 採番取API
      const restResourceService = new ApiClient();
      this.num = await restResourceService.getNumbering('user');
    },
    // ログインID重複チェック
    // TODO suzuno 直前で取得しているのと、技能者は自由入力が無いことから不要？
    async callGetLoginIdcheck() {
      const restResourceService = new ApiClient();
      for (let i = 0; i < this.csvData.length; i++) {
        const result = await restResourceService.getLoginIdcheck(this.csvData[i].loginId);
        if(result) {
          await this.callGetNumbering();
          const number =  (('00000' + this.num).slice(-5));
          this.csvData[i].loginId = 'EP2-' + number;
        }
      }
    },
    // ナンバリング更新
    async callUpdateNumbering() {
      for (let i = 0; i < this.csvData.length; i++) {
        // 採番更新API
        const restResourceService = new ApiClient();
        await restResourceService.updateNumbering('user');
      }
    },
    // 登録ボタン押下時処理
    async confirm() {
      try {
        this.postLodingFlg = true;
        this.postErrorFlg = false;
        // 技能者一括登録API呼出
        common.scrollBottom();
        const restResourceService = new ApiClient();
        this.resultRows = await restResourceService.postWorkers(this.csvData);
        for (let i = 0; i < this.resultRows.length; i++) {
          this.resultRows[i].password = this.rows[i].password;
          this.resultRows[i].email = this.rows[i].email;
        }
        const csv = this.callCognitoCsv();
        // await this.callUpdateNumbering();
        // uplord用URLの取得API呼出
        const urlBody = {
           accountType: 'worker',
           fileName: csv.name,
        };
        const uploadUrl = await restResourceService.postAccountsUploadUrl(urlBody);
        // cognito一括登録実行
        fetch(uploadUrl.uploadUrl, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'text/csv',
            },
            body: csv.body,
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
        for (let i = 0; i < csv.length; i++) {
          if (csv[i].email != '') {
            // メール送信API呼出
            // メールに使用するTemplateData
            const templateData = {
              loginid: csv[i].loginId,
              password: csv[i].password,
              url: 'http://isb-sepas-front-dev-s3-front-bucket.s3-website-ap-northeast-1.amazonaws.com',
            };
            await restResourceService.workerSignUpMail(csv[i].email, templateData);
          }
        }
        // this.screenTransition(this.workerslistPath);
      } catch (error) {
        this.errorMessages[0] = error.message;
        this.postErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
        await this.$nextTick();
        this.scrollBottom();
      }
    },
    // ダウンロード処理（ファイルパス, DL時ファイル名）
    doDownload(url, filename) {
      common.doDownload(url, filename);
    },
    // CSV出力用共通関数呼出
    callOutputCsv() {
      const today = common.getToday();
      const fileName = '技能者一括登録データ' + today;
      common.outputCsv(this.resultColumns, this.resultRows, fileName);
    },
    // CSV出力用共通関数呼出
    callCognitoCsv() {
      const today = common.getToday();
      const fileName = this.upFile.name;
      return common.cognitoCsv(this.resultColumns, this.resultRows, fileName);
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
  },
};
