















































































































import { Component, Vue } from 'vue-property-decorator';
import '../style/vue-good-table.scss';
import { VueGoodTable } from 'vue-good-table';
import ApiClient from '../services/ApiClient';
import common from '../services/common';
import moment from 'moment';
import VCalendar from 'v-calendar';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'ログイン履歴',
  name: 'loginLog',
  components: {
    VueGoodTable,
    VueLoading,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      // 検索項目用
      ipAddressOptions: [],
      ipAddressCondition: [],

      columns: [
        {
          label: '内部ID',
          field: 'id',
          hidden: true,
        },
        {
          label: '種別',
          field: 'type',
          sortable: true,
          width: '90px',
        },
        {
          label: 'ユーザーID',
          field: 'userId',
          hidden: true,
        },
        {
          label: 'ユーザー',
          field: 'name',
          sortable: true,
          width: '280px',
        },
        {
          label: 'IPアドレス',
          field: 'ip',
          width: '120px',
          formatFn: common.ipAddressFormat,
        },
        {
          label: 'ユーザーエージェント',
          field: 'ua',
          sortable: false,
          width: '500px',
        },
        {
          label: '日時',
          field: 'created',
          sortable: true,
          formatFn: this.callDateTimeFormat,
          width: '140px',
        },
      ],
      rows: [],
      conditionRow: [],
      selectRow: {},
      // データ取得時ローディングフラグ
      getLodingFlg: false,
      // データ取得時エラーフラグ
      getErrorFlg: false,
      csvDlFlg: false,
      errorMessage: '',
      // 検索上限
      limit: 100,
      // 検索内容最大件数
      maxCount: 0,
      // 現在ページ
      currentPage: 1,
      // 現在ページ
      inputPage: 1,
      // 最大ページ数
      maxPage: 1,
      // CSV取得上限
      csvLimit: 10000,
    };
  },
  // 初期処理
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // ログイン履歴一覧取得API呼出
      const restResourceService = new ApiClient();
      if (this.id !== '-') {
        this.rows = await restResourceService.getLoginLog(this.id);
      } else {
        this.rows = await restResourceService.getLoginLogs();
      }
      // 初期テーブルデータをセット
      this.conditionRow = this.rows;
      // 検索条件を取得
      this.ipAddressCondition = common.getUrlParam('ipAddress', 0);
      // 検索処理呼出
      this.search();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // IPアドレス検索用タグ追加処理
    addIpAddressTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };

      this.ipAddressCondition.push(tag);
      this.ipAddressOptions.push(tag);
    },
    // 検索ボタン押下時
    search() {
      this.setCondition();
      // 検索条件をURLに詰める
      common.setUrlParam('ipAddress', this.ipAddressCondition, 0);
      // テーブルが空の場合CSVダウンロードを非活性
      this.csvDlFlg = (this.conditionRow.length > 0) ? true : false;
    },
    // 一覧上部の検索条件を一覧へ反映
    setCondition() {
      if (this.ipAddressCondition.length === 0) {
        // 検索条件がない場合、初期データを書き戻す
        this.conditionRow = this.rows;
        return;
      }
      let newRows = [];
      let tempRows = this.rows;
      this.conditionRow = [];

      // 検索条件：IPアドレス(部分一致)
      if (this.ipAddressCondition.length !== 0) {
        newRows = [];
        this.ipAddressCondition.forEach(
          (ip) => {
            const tempRow = tempRows.filter((v) =>
              v.ip.match(ip.name),
            );
            if (tempRow.length !== 0) {
              Array.prototype.push.apply(newRows, tempRow);
            }
          },
        );
        // 重複チェック
        this.conditionRow = Array.from(
          new Map(newRows.map((row) => [row.id, row])).values()
        );
        tempRows = this.conditionRow;
      }
      this.csvDlFlg  = (this.conditionRow.length > 0) ? true : false;
    },
    // 日時値を日時（YYYY年MM月DD日）に変換
    callDateTimeFormat(value) {
      return common.dateTimeFormatLog(value);
    },
    // CSV出力用共通関数呼出
    async callOutputCsv() {
      const columns = this.columns;
      const rows = this.conditionRow;
      // ファイル名用の現在日時
      const currentDate = new Date().getTime();
      const date = await common.getToday(currentDate);
      const fileName = 'ログインログ一覧'+date;
      common.outputCsv(columns, rows, fileName);
    },
    // 現在ページ更新
    onPageChange(params) {
      this.currentPage = params.currentPage;
      common.scrollTop();
    },
  },
};
