













































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { VueGoodTable } from 'vue-good-table';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

export default {
  title: '事業者詳細',
  name: 'BusinessEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    VueLoading,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      modal: false,
      detail: {
        // ユーザー情報
        loginId: '',
        email: '',      // メールアドレス
        companyName: '',   // 事業者名
        createdAt: '',     // 登録日
        termConsent: '',    // 利用規約同意日

        // 事業者情報
        ccusId: '',     // CCUSID
        corporationNo: '',          // 法人番号
        constructionNo: '',             // 建設業許可番号
        responsibleName: '',           // 代表者名
        personDivision: '',          // 担当者部署/役職
        personName: '',              // 担当者名
        personTel: '',           // 担当者連絡先
      },
      // ローディング
      getLodingFlg: true,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      errorMessage: '',
    };
  },
  // 初期処理
  async mounted() {
    try {
      // 事業者一意情報取得API呼出
      await this.callGetCompany();
      // await new Promise((resolve) => setTimeout(resolve, 300));
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 事業者一意情報取得API呼出
    async callGetCompany() {
      try {
        const restResourceService = new ApiClient();
        const result = await restResourceService.getCompany(this.companyId);
        result.personName = result.personFirstName + result.personLastName;
        result.createdAt = common.dateFormatHyphen(result.createdAt);
        result.termConsent = common.dateFormatHyphen(result.createdAt);
        this.detail = result;
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // 技能者一覧画面へ戻る
    businessList() {
      history.back();
    },
    // アクセスログ
    accessLog() {
      this.$router.push({
        name: 'AccessLog',
        params: {
          id: this.detail.loginId,
        },
      });
    },
    // ログイン履歴
    loginLog() {
      this.$router.push({
        name: 'LoginLog',
        params: {
          id: this.detail.loginId,
        },
      });
    },
    // CCUSID修正
    async ccusIdChange() {
      try {
        const restResourceService = new ApiClient();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
  },
};
