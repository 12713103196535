



























































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';

export default {
  title: '事業者登録',
  name: 'BusinessAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },

  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      num: '',
      idCheckFlg: false,
      // 登録用モデル
      detail: {
        loginId: '',
        email: '',
        constructionNo: '',
        password: '',
        confirm: '',
        companyName: '',
      },
      // ローディング
      getLodingFlg: false,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false,  // データ登録時エラーフラグ
      processing: false,
      errorMessage: '',
      loginIdCheckMessage: '',
    };
  },
  // 初期処理
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      await this.callGetNumbering();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    async callGetNumbering() {
      // 採番取得API
      const restResourceService = new ApiClient();
      this.num = await restResourceService.getNumbering('company');
      const number =  (('00000' + this.num).slice(-5));
      this.detail.loginId = 'EPCR2-' + number;
    },
    async callGetLoginIdcheck() {
      // 採番取API
      const restResourceService = new ApiClient();
      this.idCheckFlg = await restResourceService.getLoginIdcheck(this.detail.loginId);
    },
    async callUpdateNumbering() {
      // 採番更新API
      const restResourceService = new ApiClient();
      await restResourceService.updateNumbering('company');
    },
    // 登録ボタン押下時 登録処理
    async confirm(detail) {
      await this.startProcessing();
      // ローディング表示
      this.postLodingFlg = true;
      // エラーフラグ初期化
      this.postErrorFlg = false;
      // ====================入力項目空白削除====================
      // if(!detail.email){
      //   detail.email = 'example@example.jp';
      // }
      // 採番重複チェック
      try {
        // 採番重複チェック
        await this.callGetLoginIdcheck();
        // ログインIDが重複していた場合
        if (this.idCheckFlg) {
          // ログインIDが自動採番分の場合
          if(detail.loginId.match(/EPCR2-/)){
            await this.callGetNumbering('company');
            this.loginIdCheckMessage = '新しく取得したログインIDで再度ご登録をお願い致します．';
            throw('');
          // 任意入力の場合
          } else {
            this.loginIdCheckMessage = '別のログインIDをご入力下さい．';
            throw('');
          }
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.postLodingFlg = false;
        this.endProcessing();
        return;
      }
      detail.email = detail.email.trim();
      detail.companyName = detail.companyName.trim();
      try {
        // cognitoへ事業者権限アカウントを新規登録
        await this.$cognito.signUp(detail.loginId, detail.email, detail.password, '3');
        // ステータスを確認済みに変更
        await this.$cognito.setUserPassword(detail.loginId, detail.email, detail.password);
        // 事業者新規登録API呼出
        const body = {
          constructionNo: detail.constructionNo,
          loginId: detail.loginId,
          email: detail.email,
          password: detail.password,
          companyName: detail.companyName,
        };
        const restResourceService = new ApiClient();
        await restResourceService.postCompany(body);
        await restResourceService.postNumbering(body.loginId);
        await this.callUpdateNumbering();
        this.screenTransition();
      } catch (error) {
        this.errorMessage = error.message;
        this.postErrorFlg = true;
      } finally {
        // ローディング非表示
        this.postLodingFlg = false;
        this.endProcessing();
      }
    },
    trimIn() {
      this.detail.constructionNo = common.trimSpace(this.detail.constructionNo);
    },
    // 画面遷移
    screenTransition() {
      history.back();
    },
    // チャタリング防止
    async startProcessing() {
      this.processing = true;
    },
    async endProcessing() {
      this.processing = false;
    },
    async isProcessing() {
      return this.processing;
    },
  },
  filters: {
    filterZip(d) {
      const buf = ('0000000' + d).slice(-7);
      return buf;
      // return isNaN(buf) ? '1000000' : buf;
    },
  },
};
