import axios from 'axios';
import common from './common';

const ZIP_API = 'https://api.zipaddress.net/';
// 検証環境
// const REST_BASE = 'https://syq09mpp2e.execute-api.ap-northeast-1.amazonaws.com/latest/api';
// const OPENREST_BASE = 'https://syq09mpp2e.execute-api.ap-northeast-1.amazonaws.com/latest/open';
// 認定試験環境
// const REST_BASE = 'https://7oil318wva.execute-api.ap-northeast-1.amazonaws.com/latest/api';
// const OPENREST_BASE = 'https://x5lbbmh0lj.execute-api.ap-northeast-1.amazonaws.com/latest/open';
// 本番環境
const REST_BASE = 'https://1ul1rw0kuc.execute-api.ap-northeast-1.amazonaws.com/latest/api';
const OPENREST_BASE = 'https://pbaynltjrj.execute-api.ap-northeast-1.amazonaws.com/latest/open';
// const REST_BASE = 'http://localhost:3000/api';
// const OPENREST_BASE = 'http://localhost:3000/open';

export default class ApiClient {

  public branches: [];

  /**
   * 郵便番号から住所等の詳細を取得する
   * @param postalCode 郵便番号
   * @return 住所等を含むオブジェクト
   */
  public getAddress(postalCode) {
    const params = {
      params: {
        zipcode: postalCode,
      },
    };
    return axios
      .get(ZIP_API, params)
      .then((res) => {
        console.log('=== getAddress: ', res);
        if (res.data.code !== 200) {
          return res.data.code;
        }
        return res.data.data;
      })
      .catch((error) => {
        console.log('=== getAddress: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // お知らせ機能API
  // =========================================================

  /**
   * RESTAPI お知らせ一覧を取得
   * @return お知らせ一覧（配列）
   */
  public getNotificationList() {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/notifications', {headers})
      .then((res) => {
        console.log('=== getNotificationList: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.notifications;
      })
      .catch((error) => {
        console.log('=== getNotificationList: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI お知らせ一意情報を取得
   * @return お知らせ一意情報（オブジェクト）
   */
  public getNotification(id) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/notifications/' + id, {headers})
      .then((res) => {
        console.log('=== getNotification: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.notification;
      })
      .catch((error) => {
        console.log('=== getNotification: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI お知らせを登録
   * @obj お知らせ情報（オブジェクト）
   * @return 登録結果（オブジェクト）
   */
  public postNotification(obj) {
    const headers = common.getHeaders();
    const body = {
      notificationCategoryId: obj.notificationCategoryId,
      title: obj.title,
      body: obj.body,
      expiredAt: obj.expiredAt,
    };
    return axios
      .post(REST_BASE + '/notifications', body, {headers})
      .then((res) => {
        console.log('=== postNotification: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postNotification: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI お知らせを更新
   * @obj お知らせ情報（オブジェクト）
   * @return 更新結果（オブジェクト）
   */
  public putNotification(obj) {
    const headers = common.getHeaders();
    const body = {
      notificationCategoryId: obj.notificationCategoryId,
      title: obj.title,
      body: obj.body,
      expiredAt: obj.expiredAt,
    };
    return axios
      .put(REST_BASE + '/notifications/' + obj.id, body, {headers})
      .then((res) => {
        console.log('=== putNotification: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== putNotification: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI お知らせを削除
   * @id     お知らせID
   * @return
   */
  public deleteNotification(id) {
    const headers = common.getHeaders();
    return axios
      .delete(REST_BASE + '/notifications/' + id, {headers})
      .then((res) => {
        console.log('=== deleteNotification: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== deleteNotification: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI お知らせカテゴリを取得
   * @return お知らせカテゴリ（配列）
   */
  public getNotificationCategories() {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/notificationCategories', {headers})
      .then((res) => {
        console.log('=== getNotificationCategories: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.categories;
      })
      .catch((error) => {
        console.log('=== getNotificationCategories: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // カードリーダー管理機能API
  // =========================================================

  /**
   * RESTAPI カードリーダー一覧を取得
   * @return カードリーダー一覧（配列）
   */
  public getCardReadersList() {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/cardReaders', {headers})
      .then((res) => {
        console.log('=== getCardReadersList: res', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.cardReaders;
      })
      .catch((error) => {
        console.log('=== getCardReadersList: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI カードリーダー一意情報を取得
   * @return カードリーダー一意情報（オブジェクト）
   */
  public getCardReader(id) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/cardReaders/' + id, {headers})
      .then((res) => {
        console.log('=== getCardReader: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.cardReader;
      })
      .catch((error) => {
        console.log('=== getCardReader: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI カードリーダーを登録
   * @obj カードリーダー情報（オブジェクト）
   * @return 登録結果（オブジェクト）
   */
  public postCardReader(objs) {
    const bodies = [];
    for (const obj of objs) {
      const body = {
        deviceId: obj.deviceId,
        companyId: obj.companyId,
        ccusSiteId: obj.ccusSiteId,
        modeId: obj.modeId,
      };
      bodies.push(body);
    }
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/cardReaders', { cardReaders: bodies }, {headers})
      .then((res) => {
        console.log('=== postCardReader: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postCardReader: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI カードリーダーを更新
   * @obj カードリーダー情報（オブジェクト）
   * @return 更新結果（オブジェクト）
   */
  public putCardReader(obj) {
    const body = {
      deviceId: obj.deviceId,
      companyId: obj.companyId,
      ccusSiteId: obj.ccusSiteId,
      modeId: obj.modeId,
    };
    const headers = common.getHeaders();
    return axios
      .put(REST_BASE + '/cardReaders/' + obj.id, body, {headers})
      .then((res) => {
        console.log('=== putCardReader: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== putCardReader: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI カードリーダーを削除
   * @id     カードリーダーID
   * @return
   */
  public deleteCardReader(id) {
    const headers = common.getHeaders();
    return axios
      .delete(REST_BASE + '/cardReaders/' + id, {headers})
      .then((res) => {
        console.log('=== deleteCardReader: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== deleteCardReader: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI カードリーダ一括削除
   * @id     カードリーダIDList
   * @return　カードリーダIDList
   */
  public deleteCardReaderList(obj) {
    const headers = common.getHeaders();
    const body = {
      cardReaders: obj,
    };
    return axios
      .patch(REST_BASE + '/cardReaders/delete', body, {headers})
      .then((res) => {
        console.log('=== deleteCardReader: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res;
      })
      .catch((error) => {
        console.log('=== deleteCardReader: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 入退場モード一覧を取得
   * @return 入退場モード一覧（配列）
   */
  public getModeCategories() {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/modeCategories', {headers})
      .then((res) => {
        console.log('=== getModeCategories: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.categories;
      })
      .catch((error) => {
        console.log('=== getModeCategories: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // 支店管理機能API
  // =========================================================

  /**
   * RESTAPI 支店名リストを取得
   * @return 支店名リスト（配列）
   */
  public getBranchesNameList(companyId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/branches/nameList/' + companyId, {headers})
      .then((res) => {
        console.log('=== getBranchesNameList: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.branches;
      })
      .catch((error) => {
        console.log('=== getBranchesNameList: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 支店一覧を取得
   * @return 支店一覧（配列）
   */
  public getBranches(companyId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/branches/list/' + companyId, {headers})
      .then((res) => {
        console.log('=== getBranches: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.branches;
      })
      .catch((error) => {
        console.log('=== getBranches: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 支店を取得
   * @id 支店ID
   * @return 支店（一意）
   */
  public getBranch(id) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/branches/' + id, {headers})
      .then((res) => {
        console.log('=== getBranch: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.branch;
      })
      .catch((error) => {
        console.log('=== getBranch: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }
  /**
   * RESTAPI 支店を登録
   * @objs 支店情報（オブジェクト配列）
   * @return 登録結果（オブジェクト）
   */
  public postBranch(objs) {
    const bodies = [];
    for (const obj of objs) {
      const body = {
        companyId: obj.companyId,
        branchName: obj.branchName,
        loginId: obj.loginId,
        email: obj.email,
        password: obj.password,
      };
      bodies.push(body);
    }
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/branches', {branches: bodies}, {headers})
      .then((res) => {
        console.log('=== postBranch: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data.branches;
      })
      .catch((error) => {
        console.log('=== postBranch: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 支店を更新
   * @objs 支店情報（オブジェクト配列）
   * @return 登録結果（オブジェクト）
   */
  public putBranch(obj) {
    const body = {
      id: obj.id,
      branchName: obj.branchName,
      companyId: obj.companyId,
      loginId: obj.loginId,
      email: obj.email,
      // password: obj.password
    };

    const headers = common.getHeaders();
    return axios
      .put(REST_BASE + '/branches/' + body.id, body, {headers})
      .then((res) => {
        console.log('=== putBranch: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== putBranch: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 支店を削除
   * @id     支店ID
   * @return
   */
  public deleteBranch(id) {
    const headers = common.getHeaders();
    return axios
      .delete(REST_BASE + '/branches/' + id, {headers})
      .then((res) => {
        console.log('=== deleteBranch: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== deleteBranch: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // 現場管理機能API
  // =========================================================
  /**
   * RESTAPI 現場名リストを取得
   * @return 現場名リスト（配列）
   */
  public getSitesNameList(companyId, branchId, role) {
    const body = {
        companyId,
        branchId,
        role,
    };
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/sites/nameList', body, {headers})
      .then((res) => {
        console.log('=== getSitesNameList: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.sites;
      })
      .catch((error) => {
        console.log('=== getSites: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 現場一覧を取得
   * @return 現場一覧（配列）
   */
  public getSitesList(limit, page, search, sort) {
    const headers = common.getHeaders();
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/sites/list',
        {
          params: {
            limit: limit,
            page: page,
            search: searchJson,
            sort: sortJson,
          },
          headers
        }
      )
      .then((res) => {
        console.log('=== getSitesList: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== getSitesList: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 現場一覧を取得
   * @return 現場一覧（配列）
   */
  public getSitesCsv(limit, search, sort) {
    const headers = common.getHeaders();
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/sites/csv',
        {
          params: {
            limit: limit,
            search: searchJson,
            sort: sortJson,
          },
          headers
        }
      )
      .then((res) => {
        console.log('=== getSitesCsv: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.sites;
      })
      .catch((error) => {
        console.log('=== getSitesCsv: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 現場一意情報を取得
   * @id 現場ID
   * @return 現場一意情報（オブジェクト）
   */
  public getSite(id) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/sites/' + id, {headers})
      .then((res) => {
        console.log('=== getSite: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.site;
      })
      .catch((error) => {
        console.log('=== getSite: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
    });
  }

  /**
   * RESTAPI 本日の入退場者一覧を取得
   * @return 支店一覧（配列）
   */
  public getTodayIntoList(ccusSiteId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/sites/todayInto/' + ccusSiteId, {headers})
      .then((res) => {
        console.log('=== getTodayIntoList: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.todayIntolist;
      })
      .catch((error) => {
        console.log('=== getTodayIntoList: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 本日の入場者一覧を取得
   * @return 支店一覧（配列）
   */
  public getSiteIn(ccusSiteId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/sites/in/' + ccusSiteId, {headers})
      .then((res) => {
        console.log('=== getSiteIn: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.todayIntolist;
      })
      .catch((error) => {
        console.log('=== getSiteIn: ', error);
        throw error;
      });
  }

  /**
   * RESTAPI 現場を登録
   * @obj 現場情報（オブジェクト）
   * @return 登録結果（オブジェクト）
   */
  public postSite(objs) {
    const bodies = [];
    for (const obj of objs) {
      const body = {
        ccusId: obj.ccusId,
        siteName: obj.siteName,
        companyId: obj.companyId,
        parentCompanyId: obj.parentCompanyId,
        rank: obj.rank,
        branchId: obj.branchId,
        postalCode: obj.postalCode,
        prefecture: obj.prefecture,
        city: obj.city,
        town: obj.town,
        otherAddress: obj.otherAddress,
        fullAddress: obj.fullAddress,
        tel: obj.tel,
        supervisorId: obj.supervisorId,
        approve: obj.approve,
        startDate: obj.startDate,
        endDate: obj.endDate,
      };
      bodies.push(body);
    }
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/sites', {sites: bodies}, {headers})
      .then((res) => {
        console.log('=== postSite: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postSite: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 現場を更新
   * @obj 現場情報（オブジェクト）
   * @return 更新結果（オブジェクト）
   */
  public putSite(obj) {
    const body = {
      ccusId: obj.ccusId,
      siteName: obj.siteName,
      companyId: obj.companyId,
      branchId: obj.branchId,
      postalCode: obj.postalCode,
      prefecture: obj.prefecture,
      city: obj.city,
      town: obj.town,
      otherAddress: obj.otherAddress,
      fullAddress: obj.fullAddress,
      tel: obj.tel,
      supervisorId: obj.supervisorId,
      startDate: obj.startDate,
      endDate: obj.endDate,
      // approve: obj.approve,
    };

    const headers = common.getHeaders();
    return axios
      .put(REST_BASE + '/sites/' + obj.siteId, body, {headers})
      .then((res) => {
        console.log('=== putSite: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== putSite: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 現場支店を更新
   * @obj 現場支店情報（オブジェクト）
   * @return 更新結果（オブジェクト）
   */
  public updateSiteBranch(obj) {
    const body = {
      branchId:  obj.branchId,
    };
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/sites/branch/' + obj.id, body, {headers})
      .then((res) => {
        console.log('=== updateSiteBranch: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== updateSiteBranch: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 現場を削除
   * @id     現場ID
   * @return
   */
  public deleteSite(id) {
    // return axios
    //   .delete(REST_BASE + '/sites/' + id)
    //   .then((res) => {
    //     console.log('=== deleteSite: ', res);
    //     if (res.status !== 200) {
    //       return {};
    //     }
    //     return res.data;
    //   })
    //   .catch((error) => {
    //     console.log('=== deleteSite: ', error);
    //     throw error.response.data;
    //   });
}
    /**
     * RESTAPI 現場・関連テーブルを一括削除
     * @id     現場IDList
     * @return
     */
  public deleteSiteList(siteIdList) {
    const headers = common.getHeaders();
    const body = {
      sites: siteIdList,
    };
    return axios
      .patch(REST_BASE + '/sites/delete', body, {headers})
      .then((res) => {
        console.log('=== deleteSite: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== deleteSite: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }


  // =========================================================
  // 入退場管理機能API
  // =========================================================
  /**
   * RESTAPI 入退場一覧を取得
   * @return 入退場一覧（オブジェクト）
   */
  public getEntrances(limit, page, search, sort) {
    const headers = common.getHeaders();
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/entrances/list',
       {
         params: {
           limit: limit,
           search: searchJson,
           sort: sortJson,
         },
         headers
       }
      )
      .then((res) => {
        console.log('=== getEntrance: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== getEntrance: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }
  /**
   * RESTAPI CSV用入退場一覧を取得
   * @return CSV用入退場一覧（オブジェクト配列）
   */
  public getEntrancesCsv(limit, search, sort) {
    const headers = common.getHeaders();
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/entrances/csv',
       {
         params: {
           limit: limit,
           search: searchJson,
           sort: sortJson,
         },
         headers
       }
      )
      .then((res) => {
        console.log('=== getEntrancesCsv: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.entrances;
      })
      .catch((error) => {
        console.log('=== getEntrancesCsv: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 入退場情報を取得
   * @id 事業者ID、現場ID
   * @return 入退場情報（オブジェクト）
   */
  public getEntrance(id) {
    // return axios
    //   .get(REST_BASE + '/entrances/' + id)
    //   .then((res) => {
    //     console.log('=== getEntrance: ', res);
    //     if (res.status !== 200) {
    //       return {};
    //     }
    //     return res.data.entrances;
    //   })
    //   .catch((error) => {
    //     console.log('=== getEntrance: ', error);
    //     throw error.response.data;
    //   });
  }

  /**
   * RESTAPI CCUS未送信承認更新
   * @return CCUS未送信入退場履歴ID（配列文字列）
   */
  public patchEntranceUnsent(entranceIds) {
    const body = entranceIds;
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/entrances/approve', body, {headers})
      .then((res) => {
        console.log('=== patchEntranceUnsent: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchEntranceUnsent: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI CCUSエラー再送信更新
   * @return CCUSエラー入退場履歴ID（配列文字列）
   */
  public patchEntranceError(entranceIds) {
    const body = entranceIds;
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/entrances/error', body, {headers})
      .then((res) => {
        console.log('=== patchEntranceError: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchEntranceError: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // 技能者管理機能API
  // =========================================================
  /**
   * RESTAPI 技能者一覧を取得
   * @return 技能者一覧（配列）
   */
  public getWorkersList(limit, page, search, sort) {
    const headers = common.getHeaders();
    console.log('getWorkersList_param',
      {
        params: {
          limit: limit,
          page: page,
          search: search,
          sort: sort,
        },
        headers
      }
    );
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/workers/list',
        {
          params: {
            limit: limit,
            page: page,
            search: searchJson,
            sort: sortJson,
          },
          headers
        }
      )
      .then((res) => {
        console.log('=== getWorkerList: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== getWorkerList: ', error);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者一覧CSVを取得
   * @return 技能者一覧（配列）
   */
  public getWorkersCsv(limit, search, sort) {
    const headers = common.getHeaders();
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/workers/csv',
        {
          params: {
            limit: limit,
            search: searchJson,
            sort: sortJson,
          },
          headers
        }
      )
      .then((res) => {
        console.log('=== getWorkersCsv: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.workers;
      })
      .catch((error) => {
        console.log('=== getWorkersCsv: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者詳細を取得
   * @return 技能者詳細（オブジェクト）
   */
  public getWorker(workerId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/workers/' + workerId, {headers})
      .then((res) => {
        console.log('=== getWorker: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.worker;
      })
      .catch((error) => {
        console.log('=== getWorker: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者支店変更
   * @return 技能者所属支店情報（オブジェクト）
   */
  public patchWorkerBranch(workerId, branchId) {
    const body = {
      branchId,
    };
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/workers/branch/' + workerId, body, {headers})
      .then((res) => {
        console.log('=== patchWorkerBranch: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchWorkerBranch: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI CCUS技能者情報変更
   * @return CCUS技能者情報（オブジェクト）
   */
  public patchWorkerCcus(obj) {
    const body = {
      ccusWorkerId: obj.newCcusId,
      ccusSecurityCode: obj.ccusSecurityCode,
      birthdate: obj.birthdate,
    };
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/workers/ccus/' + obj.workerId, body, {headers})
      .then((res) => {
        console.log('=== patchWorkerCcus: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchWorkerCcus: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者email情報変更
   * @return 技能者email情報（オブジェクト）
   */
  public patchWorkerEmail(workerId, body) {
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/workers/email/' + workerId, body, {headers})
      .then((res) => {
        console.log('=== patchWorkerEmail: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchWorkerEmail: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error;
      });
  }

  /**
   * RESTAPI 技能者事業者所属情報一覧取得
   * @return 登録結果（オブジェクト）
   */
  public getWorkerCompanies(workerId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/workers/company/' + workerId, {headers})
      .then((res) => {
        console.log('=== getWorkerCompaniesList: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.workerCompanies;
      })
      .catch((error) => {
        console.log('=== getWorkerCompaniesList: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者加入保険情報を取得
   * @return 技能者加入保険情報（オブジェクト配列）
   */
  public getWorkerInsurances(workerId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/workers/insurances/' + workerId, {headers})
      .then((res) => {
        console.log('=== getWorkerInsurances: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== getWorkerInsurances: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者保有資格情報を取得
   * @return 技能者保有資格情報（オブジェクト配列）
   */
  public getWorkerLicenses(workerId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/workers/licenses/' + workerId, {headers})
      .then((res) => {
        console.log('=== getWorkerLicenses: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.licenses;
      })
      .catch((error) => {
        console.log('=== getWorkerLicenses: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者新規登録
   * @obj 技能者情報（オブジェクト）
   * @return 登録結果（オブジェクト）
   */
  public postWorkers(obj) {
    const body = {
      workers: obj,
    };
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/workers', body, {headers})
      .then((res) => {
        console.log('=== postWorkers: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data.workers;
      })
      .catch((error) => {
        console.log('=== postWorkers: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**

   * RESTAPI 技能者事業者所属情報新規登録
   * @body 技能者事業者所属情報（オブジェクト）
   * @return 登録結果（オブジェクト）
   */
  public postWorkerCompanies(body) {
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/workers/company', body, {headers})
      .then((res) => {
        console.log('=== postWorkerCompanies: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postWorkerCompanies: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者情報を更新
   * @obj 技能者情報（オブジェクト）
   * @return 技能者結果（オブジェクト）
   */
  public putWorker(obj) {
    const body = {
      lastName: obj.lastName,
      firstName: obj.firstName,
      lastNameKana: obj.lastNameKana,
      firstNameKana: obj.firstNameKana,
      genderId: obj.genderId,
      loginId: obj.loginId,
      email: obj.email,
      tel: obj.tel,
      workerName: obj.workerName,
      postalCode: obj.postalCode,
      prefecture: obj.prefecture,
      city: obj.city,
      town: obj.address,
      otherAddress: obj.otherAddress,
      fullAddress: obj.fullAddress,
    };
    const headers = common.getHeaders();
    return axios
      .put(REST_BASE + '/workers/' + obj.id, body, {headers})
      .then((res) => {
        console.log('=== putWorker: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== putWorker: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者を削除
   * @workers 技能者IDリスト
   * @return
   */
  public deleteWorkers(bodies) {
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/workers/delete' , bodies, {headers})
      .then((res) => {
        console.log('=== deleteWorkers: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== deleteWorkers: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者事業者所属情報削除
   * @body 技能者事業者所属情報ID（オブジェクト）
   * @return 登録結果（String）
   */
  public deleteWorkerCompany(body) {
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/workers/companies/delete', body, {headers})
      .then((res) => {
        console.log('=== deleteWorkerCompanies: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== deleteWorkerCompanies: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 技能者顔写真取得
   * @obj ファイル名
   * @return S3署名済みURL
   */
  public getWorkerFacePhotoUrl(fileName) {
    const obj = {
      fileName,
    };
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/workers/' + 'face_photo_url', obj, {headers})
      .then((res) => {
        console.log('=== getWorkerFacePhotoUrl: ', res);
        if (res.status !== 200) {
          return {};
        }
        // TODO suzuno パスが妥当か確認し、妥当でない場合エラーを投げる処理は必要？
        return res.data.url;
      })
      .catch((error) => {
        console.log('=== getWorkerFacePhotoUrl: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // 事業者管理機能API
  // =========================================================

  /**
   * RESTAPI 事業者一覧を取得
   * @return 事業者一覧（配列）
   */
  public getCompaniesList() {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/businesses/list', {headers})
      .then((res) => {
        console.log('=== getCompanies: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.companies;
      })
      .catch((error) => {
        console.log('=== getCompanies: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 事業者情報を取得
   * @id 事業者ID
   * @return 事業者情報（一意）
   */
  public getCompany(companyId) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/businesses/' + companyId, {headers})
      .then((res) => {
        console.log('=== getCompany: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.company;
      })
      .catch((error) => {
        console.log('=== getCompany: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 事業者リストを取得
   * @return 事業者リスト（配列）
   */
  public getCompaniesNameList() {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/businesses/nameList', {headers})
      .then((res) => {
        console.log('=== getCompanyNames: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.companies;
      })
      .catch((error) => {
        console.log('=== getCompanyNames: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 事業者を登録
   * @obj 事業者情報
   * @return 登録結果（オブジェクト）
   */
  public postCompany(obj) {
    const body = {
      constructionNo: obj.constructionNo,
      loginId: obj.loginId,
      email: obj.email,
      password: obj.password,
      companyName: obj.companyName,
    };
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/businesses', body, {headers})
      .then((res) => {
        console.log('=== postCompany: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postCompany: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 事業者所属現場リストを取得
   * @return 事業者所属現場リスト（配列）
   */
  public getCompaniesSiteList(companyId) {
    const headers = common.getHeaders(companyId);
    return axios
      .get(REST_BASE + '/businesses/' + companyId + '/sites', {headers})
      .then((res) => {
        console.log('=== getCompaniesSites: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.sites;
      })
      .catch((error) => {
        console.log('=== getCompaniesSites: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 事業者を削除
   * @id     事業者ID
   * @return
   */
  public deleteCompany(companyId) {
    const headers = common.getHeaders(companyId);
    return axios
      .delete(REST_BASE + '/businesses/' + companyId, {headers})
      .then((res) => {
        console.log('=== deleteCompany: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== deleteCompany: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // 登録情報管理機能API
  // =========================================================
  /**
   * RESTAPI 登録情報を取得
   * @id 登録者ID
   * @return 登録情報（一意）
   */
  public getAccount(companyId, workerId) {
    const body = {
      companyId,
      workerId,
    };
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/accounts/me', body, {headers})
      .then((res) => {
        console.log('=== getAccount: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.account;
      })
      .catch((error) => {
        console.log('=== getAccount: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI ユーザーID採番情報を取得
   * @return ユーザーID採番情報
   */
  public getNumbering(key) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/numbering/' + key, {headers})
      .then((res) => {
        console.log('=== getNumbering: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.numbering.value;
      })
      .catch((error) => {
        console.log('=== getNumbering: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error;
      });
  }

  /**
   * RESTAPI 事業者ユーザーID採番情報を登録
   * @return 登録結果
   */
  public postNumbering(loginId) {
    const headers = common.getHeaders();
    const body = {
      key: loginId,
      mark: loginId + '支店ID',
    };
    return axios
      .post(REST_BASE + '/numbering', body, {headers})
      .then((res) => {
        console.log('=== postNumbering: ', res);
        if (res.status !== 201) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postNumbering: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error;
      });
  }

  /**
   * RESTAPI ユーザーID採番情報を更新
   * @return ユーザーID採番情報
   */
  public updateNumbering(key) {
    const headers = common.getHeaders();
    const body = {};
    return axios
      .put(REST_BASE + '/numbering/' + key, body, {headers})
      .then((res) => {
        console.log('=== updateNumbering: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== updateNumbering: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error;
      });
  }

  /**
   * RESTAPI ユーザーID重複確認
   * @id
   * @return
   */
  public getLoginIdcheck(id) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/users/' + id, {headers})
      .then((res) => {
        console.log('=== getLoginIdCheck: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== getLoginIdCheck: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error;
      });
  }

  /**
   * RESTAPI メールアドレス（メールアドレス）を更新
   * @oldEmail 現在のメールアドレス（メールアドレス）
   * @newEmail 新しいメールアドレス（メールアドレス）
   * @role ユーザー権限
   * @return 更新情報（オブジェクト）
   */
  public patchAccountEmail(body) {
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/accounts/email', body, {headers})
      .then((res) => {
        console.log('=== patchAccountEmaill: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchAccountEmail: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error;
      });
  }

  /**
   * RESTAPI CCUS IDを更新
   * @body CCUS ID,CCUSパスワード,事業者内部ID
   * @return 更新情報（オブジェクト）
   */
  public patchAccountCcus(body) {
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/accounts/ccus_id', body, {headers})
      .then((res) => {
        console.log('=== patchAccountCcus: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchAccountCcus: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI アカウント事業者情報を更新
   * @email 事業者ID
   * @body 事業者名, 代表者名, 郵便番号, 住所, 都道府県, 市区町村, 町・番地, 建物名/部屋番号
   * @return 更新情報（オブジェクト）
   */
  public patchAccountCompany(companyId, body) {
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/accounts/company/' + companyId, body, {headers})
      .then((res) => {
        console.log('=== patchAccountCompany: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchAccountCompany: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI アカウント事業者担当者情報を更新
   * @email 事業者ID
   * @body 部署, 役職, 管理者姓, 管理者名, 連絡先
   * @return 更新情報（オブジェクト）
   */
  public patchAccountManager(companyId, body) {
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/accounts/manager/' + companyId, body, {headers})
      .then((res) => {
        console.log('=== patchAccountManager: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchAccountManager: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI アカウント技能者情報を更新
   * @email 事業者ID
   * @body 事業者名, 代表者名, 郵便番号, 住所, 都道府県, 市区町村, 町・番地, 建物名/部屋番号
   * @return 更新情報（オブジェクト）
   */
  public patchAccountWorker(workerId, body) {
    const headers = common.getHeaders();
    return axios
      .patch(REST_BASE + '/accounts/worker/' + workerId, body, {headers})
      .then((res) => {
        console.log('=== patchAccountWorker: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== patchAccountWorker: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }


  /**
   * RESTAPI 登録情報を登録（初回登録時）
   * @obj 登録情報（オブジェクト）
   * @return 登録結果（オブジェクト）
   */
  public postAccount(obj) {
    // let body = {
    // };
    // return axios
    //   .post(REST_BASE + '/account', body)
    //   .then((res) => {
    //     console.log('=== postAccount: ', res);
    //     if (res.status !== 201) {
    //       return {};
    //     }
    //     return res.data;
    //   })
    //   .catch((error) => {
    //     console.log('=== postAccount: ', error);
    //     throw error.response.data;
    //   });
  }

  /**
   * RESTAPI 登録情報を登録（初回登録時）
   * @obj 登録情報（オブジェクト）
   * @return 登録結果（オブジェクト）
   */
  public postAccountsUploadUrl(obj) {
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/accounts/' + 'upload_url', obj, {headers})
      .then((res) => {
        console.log('=== postAccountsUploadUrl: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postAccountsUploadUrl: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 登録情報を更新
   * @obj 登録情報（オブジェクト）
   * @return 更新結果（オブジェクト）
   */
  public putAccount(obj) {
    // const body = {
    // };
    // return axios
    //   .put(REST_BASE + '/account/' + body.id, body)
    //   .then((res) => {
    //     console.log('=== putAccount: ', res);
    //     if (res.status !== 201) {
    //       return {};
    //     }
    //     return res.data;
    //   })
    //   .catch((error) => {
    //     console.log('=== putAccount: ', error);
    //     throw error.response.data;
    //   });
  }

  // =========================================================
  // パスワード機能API
  // =========================================================
  /**
   * RESTAPI 現在のパスワードを確認
   * @obj パスワード情報（オブジェクト）
   * @return 更新結果（オブジェクト）
   * DBでPasswordは保持せずcognito側で保存するため不要な認識
   */
  public postPassword(obj) {
    // let body = {
    //   id: obj.id,
    //   password: obj.nowPass,
    // };
    // return axios
    //   .post(REST_BASE + '/password', body)
    //   .then((res) => {
    //     console.log('=== postPassword: ', res);
    //     if (res.status !== 201) {
    //       return {};
    //     }
    //     return res.data;
    //   })
    //   .catch((error) => {
    //     console.log('=== postPassword: ', error);
    //     throw error.response.data;
    //   });
  }

  /**
   * RESTAPI パスワードを更新
   * @obj パスワード情報（オブジェクト）
   * @return 更新結果（オブジェクト）
   * DBでPasswordは保持せずcognito側で保存するため不要な認識
   */
  // patchPassword(obj) {
  //   const body = {
  //     'password': obj.password,
  //     'role': obj.role,
  //   };
  //   return axios
  //     .put(REST_BASE + '/password/' + body.id, body)
  //     .then((res) => {
  //       console.log('=== patchPassword: ', res);
  //       if (res.status !== 201) {
  //         return {};
  //       }
  //       return res.data;
  //     })
  //     .catch((error) => {
  //       console.log('=== patchPassword: ', error);
  //       throw error.response.data;
  //     });
  // }

  // =========================================================
  // アクセスログ管理機能API
  // =========================================================
  /**
   * RESTAPI アクセスログ一覧を取得
   * @limit 検索上限数
   * @page ページ数
   * @search 検索条件
   * @return アクセスログ一覧（オブジェクト配列）
   */
  public getAccessLogs(limit, page, search, sort) {
    const headers = common.getHeaders();
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/accessLogs',
        {
          params: {
            limit: limit,
            page: page,
            search: searchJson,
            sort: sortJson,
          },
          headers
        }
      )
      .then((res) => {
        console.log('=== getAccessLogs: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== getAccessLogs: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI アクセスログ一覧CSV取得用URLを取得
   * @search 検索条件
   * @return アクセスログ一覧（オブジェクト配列）
   */
  public getAccessLogsCsv(limit, search, sort) {
    const headers = common.getHeaders();
    const searchJson = JSON.stringify(search);
    const sortJson = JSON.stringify(sort);
    return axios
      .get(REST_BASE + '/accessLogs/csv',
       {
         params: {
           limit: limit,
           search: searchJson,
           sort: sortJson,
         },
         headers
       }
      )
      .then((res) => {
        console.log('=== getAccessLogsCsv: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.accessLogs;
      })
      .catch((error) => {
        console.log('=== getAccessLogsCsv: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // ログイン履歴管理機能API
  // =========================================================
  /**
   * RESTAPI ログイン履歴一覧を取得
   * @return ログイン履歴一覧（オブジェクト配列）
   */
  public getLoginLogs() {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/loginLog', {headers})
      .then((res) => {
        console.log('=== getLoginLogs: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.loginLogs;
      })
      .catch((error) => {
        console.log('=== getLoginLogs: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI 特定ユーザーのログイン履歴一覧を取得
   * @id ユーザーの一意ID
   * @return 特定ユーザーのログイン履歴一覧（オブジェクト配列）
   */
  public getLoginLog(id) {
    const headers = common.getHeaders();
    return axios
      .get(REST_BASE + '/loginLog/' + id, {headers})
      .then((res) => {
        console.log('=== getLoginLog: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.loginLogs;
      })
      .catch((error) => {
        console.log('=== getLoginLog: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }
  /**
   * RESTAPI ログイン履歴登録
   * @body クライアントIP,ユーザーエージェント,ログインタイプ(0:ログアウト,1:ログイン)
   * @return ログイン履歴情報
   */
  public postLoginLogs(body) {
    const headers = common.getHeaders();
    return axios
      .post(REST_BASE + '/loginLog', body, {headers})
      .then((res) => {
        console.log('=== postLoginLogs: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== postLoginLogs: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  // =========================================================
  // ユーザー管理機能API
  // =========================================================
  /**
   * RESTAPI ログインユーザー情報を取得
   * @return ログインユーザー情報（オブジェクト）
   */
  public getUser(role, loginId, auth) {
    const headers = {
      'Content-Type': 'application/json',
      'x-user-id': loginId,
      'x-user-role': role,
      'Authorization': auth,
    };

    return axios
      .get(REST_BASE + '/users/me', {headers})
      .then((res) => {
        console.log('=== getUser: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data;
      })
      .catch((error) => {
        console.log('=== getUser: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
   * RESTAPI ログインID取得
   * @return ログインID（文字列）
   */
  public getLoginId(email) {
    // const headers = common.getHeaders();
    // console.log('###headers###',headers);
    const headers = {
      'Content-Type': 'application/json',
      'x-user-id': 'user',
      'x-user-role': 'user',
    };
    return axios
      .get(OPENREST_BASE + '/' + email + '/getLoginId', { headers })
      .then((res) => {
        console.log('=== getLoginId: ', res);
        if (res.status !== 200) {
          return {};
        }
        return res.data.loginIds;
      })
      .catch((error) => {
        console.log('=== getLoginId: ', error);
        if (!error.response) throw common.othersError(error.message);
        throw error.response.data;
      });
  }

  /**
  * RESTAPI SES メール送信 ログインID忘れ用
  * @return messageId : string
  */
 public loginIdForgetMail(toAddress_, templateData_) {
   const body = {
     toAddress: [
       toAddress_,
     ] ,
     templateData: templateData_,
   };
   const headers = {
     'Content-Type': 'application/json',
     'x-user-id': 'user',
     'x-user-role': 'user'
   };

   return axios
     .post(OPENREST_BASE + '/loginIdForget', body, {headers})
     .then((res) => {
       console.log('=== loginIdForgetMail: ', res);
       if (res.status !== 201) {
         return {};
       } else if (res.data.id == null) {
         throw new Error('error');
       }
       return res.data;
     })
     .catch((error) => {
       console.log('=== loginIdForgetMail: ', error);
       if (!error.response) throw common.othersError(error.message);
       throw error.response.data;
     });
 }

  /**
  * RESTAPI SES メール送信 メールアドレス更新
  * @return messageId : string
  */
 public changeMail(toAddress_, templateData_) {
   const body = {
     toAddress: [
       toAddress_,
     ] ,
     templateData: templateData_,
   };

   const headers = common.getHeaders();

   return axios
     .post(OPENREST_BASE + '/changeMail', body, {headers})
     .then((res) => {
       console.log('=== changeMail: ', res);
       if (res.status !== 201) {
         return {};
       } else if (res.data.id == null) {
         throw new Error('error');
       }
       return res.data;
     })
     .catch((error) => {
       console.log('=== changeMail: ', error);
       if (!error.response) throw common.othersError(error.message);
       throw error.response.data;
     });
 }
 /**
 * RESTAPI SES メール送信 技能者新規登録時にメールアドレスの登録がある場合
 * @return messageId : string
 */
public workerSignUpMail(toAddress_, templateData_) {
  const body = {
    toAddress: [
      toAddress_,
    ] ,
    templateData: templateData_,
  };

  const headers = common.getHeaders();
  return axios
    .post(REST_BASE + '/workers/workerSignUpMail', body, {headers})
    .then((res) => {
      console.log('=== workerSignUpMail: ', res);
      if (res.status !== 201) {
        return {};
      } else if (res.data.id == null) {
        throw new Error('error');
      }
      return res.data;
    })
    .catch((error) => {
      console.log('=== workerSignUpMail: ', error);
      if (!error.response) throw common.othersError(error.message);
      throw error.response.data;
    });
}
  // =========================================================
  // ログイン・ログアウト機能API
  // =========================================================
  /**
   * RESTAPI ログイン処理
   * @obj ログイン情報（オブジェクト）
   * @return 処理結果（オブジェクト）
   */
  public postLogin(obj) {
    // let body = {
    //   id: obj.id,
    //   password: obj.password,
    // };
    // return axios
    //   .post(REST_BASE + '/login', body)
    //   .then((res) => {
    //     console.log('=== postLogin: ', res);
    //     if (res.status !== 201) {
    //       return {};
    //     }
    //     return res.data;
    //   })
    //   .catch((error) => {
    //     console.log('=== postLogin: ', error);
    //     throw error.response.data;
    //   });
  }

  /**
   * RESTAPI ログアウト処理
   * @obj ログイン情報（オブジェクト）
   * @return 処理結果（オブジェクト）
   */
  public postLogout(obj) {
      // let body = {
      //   id: obj.id,
      // };
      // return axios
      //   .post(REST_BASE + '/logout', body)
      //   .then((res) => {
      //     console.log('=== postLogout: ', res);
      //     if (res.status !== 201) {
      //       return {};
      //     }
      //     return res.data;
      //   })
      //   .catch((error) => {
      //     console.log('=== postLogout: ', error);
      //     throw error.response.data;
      //   });
    }

    // =========================================================
    // CCUS連携機能API
    // =========================================================

    // CCUS現場情報取得
    public getCcusSite(id) {
      const headers = common.getHeaders();
      return axios
        .get(REST_BASE + '/ccus/sites/' + id, {headers})
        .then((res) => {
          console.log('=== getCcusSite: ', res);
          if (res.status !== 200) {
            return {};
          }
          return res.data.ccusies;
        })
        .catch((error) => {
          console.log('=== getCcusSite: ', error);
          if (!error.response) throw common.othersError(error.message);
          throw error.response.data;
      });
    }

    // CCUS現場情報削除
    public deleteCcusSite(id) {
      const headers = common.getHeaders();
      // const body = {
      //   ccus_site_id: id
      // };
      return axios
        .delete(REST_BASE + '/ccus/site/' + id,  {headers})
        .then((res) => {
          console.log('=== deleteCcusSite: ', res);
          if (res.status !== 200) {
            return {};
          }
          return res.data;
        })
        .catch((error) => {
          console.log('=== deleteCcusSite: ',  error);
          if (!error.response) throw common.othersError(error.message);
          throw error.response.data;
      });
    }


    // CCUS現場情報登録
    public postCcusSite(obj) {
      const body = {
        ccusies: obj,
      };
      const headers = common.getHeaders();
      return axios
        .post(REST_BASE + '/ccus/site', body, {headers})
        .then((res) => {
          console.log('=== postCcusSite: ', res);
          if (res.status !== 201) {
            return {};
          }
          return res.data;
        })
        .catch((error) => {
          console.log('=== postCcusSite: ', error);
          if (!error.response) throw common.othersError(error.message);
          throw error.response.data;
        });
    }

    // // CCUS技能者情報登録
    // postCcusWorker(obj) {
    //   const body = {
    //     "ccusies": obj
    //   };
    //   const headers = common.getHeaders();
    //   return axios
    //     .post(REST_BASE + '/ccus/worker', body, {headers: headers})
    //     .then((res) => {
    //       console.log('=== postCcusWorker: ', res);
    //       if (res.status !== 201) {
    //         return {};
    //       }
    //       return res.data;
    //     })
    //     .catch((error) => {
    //       console.log('=== postCcusWorker: ', error);
    //       throw error.response.data;
    //     });
    // }
}
