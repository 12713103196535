





















































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min, email } from 'vee-validate/dist/rules';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'パスワード再発行準備',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  data() {
    return {
      loginId: '',
      // ローディング
      sendLodingFlg: false,  // メール送信ローディングフラグ
      sendErrorFlg: false,   // メール送信時エラーフラグ
      loginIdErrorFlg: false,   // メール送信時エラーフラグ
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 変更ボタン押下時処理
    async confirm() {
      try {
        this.sendErrorFlg = false;
        this.sendLodingFlg = true;
        // メールアドレスへ確認コードを送信
        await this.$cognito.forgetPassword(this.loginId)
        .then((result) => {
          // パスワードリセット画面へ遷移
          this.$router.push({
            name: 'PasswordReset',
            params: {
              loginId: this.loginId,
            },
          });
        })
        .catch((err) => {
          this.loginIdErrorFlg = true;
        });
      } catch (error) {
        this.sendErrorFlg = true;
      } finally {
        this.sendLodingFlg = false;
      }
    },
    // ログイン画面へ遷移
    screenTransition() {
      this.$router.push('/');
    },
    // パスワード忘れ再発行画面へ遷移
    forgotLoginId() {
      this.$router.push('/users/forgot-login-id');
    },
  },
};
