























































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: 'カードリーダー編集',
  name: 'CurdReaderAdd',

  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      curdReaderslistPath: '/cardReader', // カードリーダー一覧パス
      // 更新用モデル
      detail: {
        id: '',
        deviceId: '',
        companyId: '未登録',
        companyName: '未登録',
        ccusSiteId: '',
        siteName: '未登録',
        modeId: '',
        modeName: '',
      },
      // プレースホルダー用元値
      before: {
        id: '',
        deviceId: '',
        companyId: '',
        companyName: '',
        ccusSiteId: '',
        siteName: '',
        modeId: '',
        modeName: '',
      },
      selectedMode: '', // 選択した入退場モード
      optionMode: [], // 選択用入退場モード配列
      // ローディング
      getLodingFlg: true, // データ取得時ローディング用フラグ
      getErrorFlg: false, // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false, // データ登録時エラーフラグ
      errorMessage: '',
    };
  },
  // 初期処理
  async mounted() {
    try {
      // 入退場モード一覧取得API呼出
      this.optionMode = await this.callGetModeCategories();
      // カードリーダー一意情報取得API呼出
      this.detail = await this.callGetCardReader();
      // 入退場モードを現在の設定値で表示する
      this.selectedMode = this.detail.modeId;
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 入退場モード一覧取得API呼出
    async callGetModeCategories() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getModeCategories();
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // カードリーダー一意情報取得API呼出
    async callGetCardReader() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getCardReader(this.id);
      } catch (error) {
      this.errorMessage = error.message;
      throw error;
      }
    },
    // 変更ボタン押下時処理
    async confirm() {
      try {
        // エラーフラグ初期化
        this.postErrorFlg = false;
        // ローディング表示
        this.postLodingFlg = true;
        // カードリーダー更新API呼出
        const body = {
          id: this.id,
          deviceId: this.detail.deviceId,
          companyId: this.detail.companyId,
          ccusSiteId: this.detail.ccusSiteId,
          modeId: this.selectedMode,
        };
        const restResourceService = new ApiClient();
        await restResourceService.putCardReader(body);
        // カードリーダー一覧画面に戻る
        this.screenTransition(this.curdReaderslistPath);
      } catch (error) {
      this.errorMessage = error.message;
      this.postErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
      }
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
  },
};
