












import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  components: {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
  },
  props: {
    size: {
      type: String,
      default: 'logout',
      required: true,
    },
  },
  mounted() {
    const modal = document.querySelector('.modal');
    disableBodyScroll(modal);
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
};
