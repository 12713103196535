







import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue'; // @ is an alias to /src
import Content from '@/components/SiteEdit.vue'; // @ is an alias to /src

@Component({
  components: {
    Sidebar,
    Content,
  },
})
export default class SiteEdit extends Vue {}
