






































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';

export default {
  title: '技能者編集',
  name: 'WorkerEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueLoading,
  },
  props: {
    id: {
      type: String,
      default: '0',
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      workerslistPath: '/workers', // 技能者一覧パス
      // 登録用モデル
      detail: {
        // 氏名【必須】
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        nationality: '',
        workerName: '',
        // 基本情報【任意】
        genderId: '',
        tel: '',
        email: '',
        // 住所【任意】
        postalCode: '',
        prefecture: '',
        city: '',
        town: '',
        otherAddress: '',
        fullAddress: '',
        // CCUS-GWアカウント情報 用途不明
        loginId: '',
        contactEmail: '',
        nationalityObj: {},
        // CCUS登録情報　用途不明
        ccusId: '',
        ccusNo: '',
      },
      // プレースホルダー用元値
      before: {
        // 氏名【必須】
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        // 基本情報【任意】
        genderId: '未設定',
        tel: '未設定',
        email: '未設定',
        // 住所【任意】
        postalCode: '未設定',
        prefecture: '未設定',
        city: '未設定',
        town: '未設定',
        otherAddress: '未設定',
        fullAddress: '',
        // CCUS-GWアカウント情報
        loginId: '',
        contactEmail: '',
        nationalityObj: {},
      },
      // ドロップダウンリスト
      // 選択した性別用
      selectedgender: '',
      // 選択用性別配列
      // TODO 性別IDリスト取得API要作成？
      optionGender: [
        {
          id: 'ge3138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: '男性',
        },
        {
          id: 'ge2138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: '女性',
        },
        {
          id: 'ge1138a7-ccdd-48ea-ad39-fc455bcfe134',
          name: 'その他',
        },
      ],
      // 国籍（日本：１　海外：２）
      nationalityList: [
        {
          id: 1,
          name: '日本',
        },
        {
          id: 2,
          name: '海外',
        },
      ],
      selectedprefs: '', // 選択した都道府県用
      optionprefs: [],   // 選択用都道府県配列
      // ローディング
      getLodingFlg: true,     // データ取得時ローディング用フラグ
      getErrorFlg: false,      // データ取得時エラーフラグ
      postLodingFlg: false,    // データ登録時ローディングフラグ
      putErrorFlg: false,     // データ登録時エラーフラグ
      // 入力チェック
      validationErrFlg: false, // 入力チェックエラーフラグ
      processing: false,
      validationErrMsg: [],    // 入力チェックエラーメッセージ用配列
      errorMessage: '',
    };
  },
  async mounted() {
    try {
      // 技能者一意情報取得API呼出
      await this.setWorker(this.id, this.detail, this.before, this.nationalityList);
      // await new Promise((resolve) => setTimeout(resolve, 300));
    } catch (error) {
      this.errorMessage = error.message;

      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 郵便番号を元に住所取得
    async getAddress(detail) {
      const restResourceService = new ApiClient();
      await restResourceService.getAddress(detail.postalCode)
        .then((res) => {
          this.detail.prefecture = res.pref;
          this.detail.city = res.city;
          this.detail.town = res.town;
          this.detail.fullAddress = res.fullAddress;
        });
    },
    // // 都道府県リスト取得API呼出
    // async callGetprefs() {
    //   try {
    //     const restResourceService = new ApiClient();
    //     return await restResourceService.getprefs();
    //   } catch (error) {
    //     throw error;
    //   }
    // },
    // // 都道府県名からキーバリューを設定
    // prefConversion(value, list) {
    //   const result = list.find((v) => v.name === value);
    //   if (result) {
    //     return result.name;
    //   }
    // },
    // 技能者一意情報取得API呼出
    async setWorker(id, detail, before, nationalityList) {
      try {
        const restResourceService = new ApiClient();
        await restResourceService.getWorker(id)
          .then((res) => {
            detail.workerName = res.workerName;
            detail.lastName = res.lastName;
            detail.firstName = res.firstName;
            detail.lastNameKana = res.lastNameKana;
            detail.firstNameKana = res.firstNameKana;
            detail.genderId = res.genderId;
            detail.nationality = res.nationality;
            detail.email = res.email;
            detail.tel = res.tel;
            detail.postalCode = res.postalCode;
            detail.prefecture = res.prefecture;
            detail.city = res.city;
            detail.town = res.town;
            detail.otherAddress = res.otherAddress;
            detail.fullAddress = res.fullAddress;
            detail.nationalityObj = nationalityList.find((v) => v.id === detail.nationality);
            // this.getAddress(detail);
            // before
            before.workerName = res.workerName;
            before.lastName = res.lastName;
            before.firstName = res.firstName;
            before.lastNameKana = res.lastNameKana;
            before.firstNameKana = res.firstNameKana;
            before.genderId = res.genderId;
            before.nationality = res.nationality;
            before.email = res.email;
            before.tel = res.tel;
            before.postalCode = res.postalCode;
            before.prefecture = res.prefecture;
            before.city = res.city;
            before.town = res.town;
            before.otherAddress = res.otherAddress;
            before.fullAddress = res.fullAddress;
          });
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 変更ボタン押下時 更新処理
    async confirm() {
      // ボタン非活性化
      await this.startProcessing();
      // ====================入力項目空白削除====================
      this.detail.lastName = this.detail.lastName.trim();
      this.detail.firstName = this.detail.firstName.trim();
      this.detail.lastNameKana = this.detail.lastNameKana.trim();
      this.detail.firstNameKana = this.detail.firstNameKana.trim();
      if (this.detail.town) {
        this.detail.town = this.detail.town.trim();
      }
      if (this.detail.otherAddress) {
        this.detail.otherAddress = this.detail.otherAddress.trim();
      }
      // ====================入力チェック====================
      // 入力チェックメッセージ初期化
      this.validationErrMsg = [];
      // 郵便番号と住所の妥当性チェック(郵便番号,都道府県,市区町村)
      if (this.detail.postalCode) {
        if (!this.detail.prefecture || !this.detail.city) {
          this.validationErrMsg.push('郵便番号が不正です');
        }
        if (this.detail.prefecture && this.detail.city && !this.detail.town) {
          this.validationErrMsg.push('住所を登録する場合、町・番地を入力して下さい');
        }
      }
      if (this.detail.nationality === 1) {
        this.detail.workerName = this.detail.lastName + this.detail.firstName;
      } else if (this.detail.nationality === 2) {
        this.detail.workerName = this.detail.firstName + this.detail.lastName;
      } else {
        this.validationErrMsg.push('国籍選択が不正です');
      }
      if (this.validationErrMsg.length !== 0) {
        return;
      }
      try {
        // フラグ初期化
        this.putErrorFlg = false;
        // ローディング表示
        this.postLodingFlg = true;
        this.detail.loginId = this.$route.params.loginId;
        this.detail.fullAddress = this.detail.prefecture + this.detail.city + this.detail.town + this.detail.otherAddress;
        // 技能者更新API呼出
        const body = {
          id: this.id,
          lastName: this.detail.lastName,
          firstName: this.detail.firstName,
          lastNameKana: this.detail.lastNameKana,
          firstNameKana: this.detail.firstNameKana,
          workerName: this.detail.workerName,
          genderId: this.detail.genderId,
          loginId: this.detail.loginId,
          email: this.detail.email,
          tel: this.detail.tel,
          postalCode: this.detail.postalCode,
          prefecture: this.detail.prefecture,
          city: this.detail.city,
          address: this.detail.town,
          otherAddress: this.detail.otherAddress,
          fullAddress: this.detail.fullAddress,
        };
        const restResourceService = new ApiClient();
        if (this.before.email !== this.detail.email) {
          // メールに使用するTemplateData
          const templateData = {
            oldMail: this.before.email,
            newMail: this.detail.email
          };
          await restResourceService.changeMail(this.detail.email, templateData);
        }

        await restResourceService.putWorker(body);
        history.back();
      } catch (error) {
      this.errorMessage = error.message;
      this.putErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
      }
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
    // チャタリング防止
    async startProcessing() {
      this.processing = true;
    },
    async endProcessing() {
      this.processing = false;
    },
    async isProcessing() {
      return this.processing;
    },
  },
};
