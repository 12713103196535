














































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { validate, ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueGoodTable } from 'vue-good-table';
import ApiClient from '../services/ApiClient';
import { VueLoading } from 'vue-loading-template';
import common from '../services/common';
import constants from "../constants/constants";

export default {
  title: '現場一括登録',
  name: 'SiteAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    VueLoading,
  },
  // setup() {
  //   return {
  //   },
  // },
  data() {
    return {
      // 現場一覧パス
      siteslistPath: '/sites',
      // テンプレートファイルパス
      templateFilePath: '/files/site-bulk-template.csv',
      // テンプレートファイルダウンロード時のファイル名
      templateFileName: 'site-bulk-template.csv',
      // 記入サンプルファイルパス
      sumpleFilePath: '/files/site-bulk-sample.csv',
      // 記入サンプルファイルダウンロード時のファイル名
      sumpleFileName: 'site-bulk-sample.csv',
      // アップロードファイル
      fileName: '',
      upFile: '',
      csvData: {},
      // 選択した事業者用
      selectedCompanies: '',
      // 選択した支店用
      selectBranches: '',
      // 選択用事業者配列　事業者一覧取得APIが実装され次第そちらに変更
      optionCompanies: [],
      // 選択用支店配列
      optionBranches: [],
      errorMessages: [],
      columns:  [
        {
          label: 'CCUS現場ID',
          field: 'ccusId',
          width: '90px',
        },
        {
          label: '事業者ID',
          field: 'companyId',
          width: '125px',
          // TODO suzuno formatFn: を置き、IDに一致する事業者名を取得予定
        },
        {
          label: '支店ID',
          field: 'branchId',
          width: '125px',
          // TODO suzuno formatFn: を置き、IDに一致する支店名を取得予定
        },
        {
          label: '現場名',
          field: 'siteName',
          width: '100px',
          // TODO suzuno formatFn: を置き、IDに一致する現場名を取得予定
        },
        {
          label: '工期（開始）',
          field: 'startDate',
          width: '125px',
        },
        {
          label: '工期（終了）',
          field: 'endDate',
          width: '125px',
        },
        {
          label: '郵便番号',
          field: 'postalCode',
          width: '125px',
        },
        {
          label: '都道府県',
          field: 'prefecture',
          width: '125px',
        },
        {
          label: '市区町村',
          field: 'city',
          width: '125px',
        },
        {
          label: '町・番地',
          field: 'town',
          width: '125px',
        },
        {
          label: '建物名/部屋番号',
          field: 'otherAddress',
          width: '125px',
        },
        {
          label: '現場管理者ID',
          field: 'supervisorId',
          width: '100px',
        },
        {
          label: '電話番号',
          field: 'tel',
          width: '100px',
        },
        {
          label: '自動承認',
          field: 'approve',
          hidden: true,
        },
      ],
      rows: [],
      workers: [],
      // ローディング
      getLodingFlg: false,  // データ取得時ローディング用フラグ
      getErrorFlg: false,   // データ取得時エラーフラグ
      postLodingFlg: false, // データ登録時ローディングフラグ
      postErrorFlg: false,  // データ登録時エラーフラグ
      parseFlg: false,
      errorMessage: '',
      searchCondition: {},
    };
  },
  // 初期処理
  async mounted() {
    try {
      // ローディング表示
      this.getLodingFlg = true;
      // 事業者リスト取得API呼出
      this.optionCompanies = await this.callGetCompaniesList();
    } catch (error) {
      this.errorMessage = error.message;
      this.getErrorFlg = true;
    } finally {
      // ローディング非表示
      this.getLodingFlg = false;
    }
  },
  computed: {
    role() {
      return this.$store.getters.session.role;
    },
    isSidebarShow() {
      return this.$store.getters.session.isSidebarShow;
    },
  },
  watch: {
    role() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
    isSidebarShow() {
      this.$nextTick(() => {
        // 必要であれば処理を書く 現状必要なロジックはない
      });
    },
  },
  methods: {
    // 事業者リスト取得API呼び出し
    async callGetCompaniesList() {
      try {
        const restResourceService = new ApiClient();
        return await restResourceService.getCompaniesNameList();
      } catch (error) {
        this.errorMessage = error.message;
        throw error;
      }
    },
    // 支店名リスト取得API呼び出し
    async callGetBranchsNameList() {
      try {
        const restResourceService = new ApiClient();
        this.optionBranches = await restResourceService.getBranchesNameList(this.selectedCompanies);
        return  this.optionBranches;
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
        throw error;
      }
    },
    // アップロードファイル取得
    loadCsvFile(event) {
      this.fileName = event.target.files[0].name;
      this.upFile = event.target.files[0];
      this.$refs.uploadFile.value = null;
      this.parseFlg = false;
    },
    // アップロードして確認ボタン押下時
    async csvExtract() {
      this.postLodingFlg = true;
      const errorMessages = new Array();
      let flg = false;
      try {
        // 抽出CSV配列とエラーメッセージ配列を空にする
        this.csvData.length = 0;
        if (!this.upFile) {
          errorMessages.push('ファイルを選択して下さい。');
          return;
        }
        // 取り込んだCSVファイルをオブジェクト配列に変換
        this.$papa.parse(this.upFile, {
          encoding: 'Shift-JIS',
          header: true,
          complete: this.csvGet,
          error: function(err, file) {
            errorMessages.push('CSVの抽出に失敗しました。ファイルを再度選択して下さい');
            flg = true;
          }
        });
      } catch (error) {
        this.postLodingFlg = false;
        errorMessages.push('CSVの抽出に失敗しました。ファイル内のデータをご確認下さい');
      } finally {
        this.errorMessages = errorMessages;
        new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1);
        }).then(() => {
          this.parseFlg = flg;
          this.scrollBottom();
        });
      }
    },
    // CSVから抽出されたオブジェクト配列を加工
    async csvGet(results) {
      try {
        const errorMessages = new Array();
        // 事業者ID　completeの中では参照できないためローカルの変数に移す
        const selectedCompanies = this.selectedCompanies;
        const selectBranches = this.selectBranches;
        this.csvData = results.data;
        this.searchCondition['companyId'] = this.companyId;
        this.searchCondition['branchId'] = this.branchId;
        const restResourceService = new ApiClient();
        // TODO: 取得人数
        const result =await restResourceService.getWorkersList(constants.LIMIT, constants.PAGE, this.searchCondition,{})
        this.workers = result.workers;
        if (this.csvData.length === 0) {
          errorMessages.push('登録データが存在しません。ファイル内のデータをご確認下さい。');
        }
        for (let i = 0; i < this.csvData.length; i++) {
          // 選択した事業者の事業者IDをプロパティとして追加
          this.csvData[i].companyId = selectedCompanies;
          this.csvData[i].branchId = selectBranches;
          this.csvData[i].parentCompanyId = ''; // TODO 親会社IDを取得　選択欄追加
          this.csvData[i].rank = 1; // TODO 何次受けかを入力もしくは取得　方法不明
          this.csvData[i].fullAddress = this.csvData[i].prefecture + this.csvData[i].city + this.csvData[i].town;
          // 現場管理者確認
          if (this.csvData[i].supervisorId) {
            const supervisor = this.workers.find((v) => v.workerId === this.csvData[i].supervisorId);
            if (supervisor) {
              this.csvData[i].supervisorId = supervisor.workerId;
              this.csvData[i].tel = supervisor.tel;
            } else {
              errorMessages.push((i + 1) + '行目 現場管理者のID指定に誤りがあります。IDをご確認下さい。');
            }
          }
          this.csvData[i].approve = 1;
          // 必要情報が欠けた行が存在した場合、エラーメッセージを詰める
          if (!this.csvData[i].ccusId && !this.csvData[i].siteName &&
            !this.csvData[i].startDate && !this.csvData[i].endDate &&
            !this.csvData[i].prefecture && !this.csvData[i].city &&
            !this.csvData[i].town && !this.csvData[i].approve) {
            // errorMessages.push('必要な列情報がありません。フォーマットを確認して下さい。');
            // 空行の場合処理を終了する
            this.csvData.splice(i);
            break;
          }

          const ccusId = common.trimSpace(this.csvData[i].ccusId);
          await validate(ccusId, 'digits:14', {
            name: 'CCUS現場ID（ccusId）',
            values: {
              ccusId,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });
          //
          // if (!this.csvData[i].siteName) {
          //   errorMessages.push((i + 1) + '行目 現場名（siteName）が設定されていません。');
          // }
          const siteName = common.trimSpace(this.csvData[i].siteName);
          await validate(siteName, 'required', {
            name: '現場名（siteName）',
            values: {
              siteName,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const startDate = common.trimSpace(this.csvData[i].startDate);
          await validate(startDate, 'required', {
            name: '工期開始日（startDate）',
            values: {
              startDate,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const endDate = common.trimSpace(this.csvData[i].endDate);
          await validate(endDate, 'required|futureDay|periodValidityBulk:startDate', {
            name: '工期終了日（endDate）',
            values: {
              startDate,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const postalCode = common.trimSpace(this.csvData[i].postalCode);
          await validate(postalCode, 'required|digits:7|addressFlg', {
            name: '郵便番号（postalCode）',
            values: {
              postalCode,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const prefecture = common.trimSpace(this.csvData[i].prefecture);
          await validate(prefecture, 'required|checkPrefecture:postalCode', {
            name: '都道府県（prefecture）',
            values: {
              postalCode,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const city = common.trimSpace(this.csvData[i].city);
          await validate(city, 'required|max:128', {
            name: '市区町村（city）',
            values: {
              postalCode,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const town = common.trimSpace(this.csvData[i].town);
          await validate(town, 'required|max:128', {
            name: '町・番地（town）',
            values: {
              town,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          const otherAddress = this.csvData[i].otherAddress;
          await validate(otherAddress, 'max:128', {
            name: '建物名/部屋番号（otherAddress）',
            values: {
              otherAddress,
            },
          }).then((result) => {
            if (!result.valid) {
              errorMessages.push((i + 1) + '行目' + result.errors[0]);
            }
          });

          this.csvData[i].approve = true;
        }
        // エラーメッセージが詰められていた場合、処理を中断する
        if (errorMessages.length !== 0) {
          this.errorMessages = errorMessages;
          this.scrollBottom();
          return;
        }
        this.rows = this.csvData;
        this.scrollBottom();
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
      }
    },
    // DOM更新待機後スクロール
    async scrollBottom() {
      await this.$nextTick();
      common.scrollBottom();
    },
    // 登録ボタン押下時処理
    async confirm() {
      try {
        this.header =  false;
        this.postLodingFlg = true;
        this.postErrorFlg = false;
        for (let i = 0; i < this.csvData.length; i++) {
          this.csvData[i].startDate = common.unixTimeFormat(this.csvData[i].startDate);
          this.csvData[i].endDate = common.unixTimeFormat(this.csvData[i].endDate);
        }
        // 一括登録API呼出
        const restResourceService = new ApiClient();
        // 一括登録実行
        await restResourceService.postSite(this.csvData);
        this.screenTransition(this.siteslistPath);
      } catch (error) {
        this.errorMessage = error.message;
        this.getErrorFlg = true;
      } finally {
        this.postLodingFlg = false;
        await this.$nextTick();
        common.scrollBottom();
      }
    },
    // ダウンロード処理（ファイルパス, DL時ファイル名）
    doDownload(url, filename) {
      common.doDownload(url, filename);
    },
    // 画面遷移
    screenTransition(path) {
      history.back();
    },
  },
};
