






import { Component, Vue } from 'vue-property-decorator';
import Content from '@/components/PasswordForgot.vue';

@Component({
  components: {
    Content,
  },
})
export default class PasswordForgot extends Vue {}
