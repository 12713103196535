import common from './common';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  confirmed,
  required,
  required_if,
  max,
  min,
  ext,
  length,
  digits,
  regex,
  alpha,
  numeric
} from 'vee-validate/dist/rules';

// =======================================
// バリデーションルール
// =======================================
// 必須
extend('required', {...required, message: '{_field_}は必須項目です' });
// 条件必須
extend('required_if', {...required_if,
  params: [
    {
      name: 'target',
      isTarget: true,
    }
  ],
  message: '{target}を入力する場合、{_field_}も入力して下さい' });
// 条件必須
extend('required_CcusWorker_if', {...required_if,
  params: [
    {
      name: 'target',
      isTarget: true,
    }
  ],
  message: 'CCUS技能者ID（ccusWorkerId）を入力する場合、{_field_}も入力して下さい' });
// 同一確認
extend('confirmed',{...confirmed,　message: '{_field_}は同じ値を入力して下さい'});
// メールアドレス
extend('emailVaridate', {
  message: '{_field_}の形式が不正です',
  validate(value) {
    const regex = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    return regex.test(value);
  },
});
// 最大文字数
extend('max', {...max, message: '{_field_}は{length}文字以内で入力して下さい'});
// 最小文字数
extend('min', {...min, message: '{_field_}は{length}文字以上で入力してください'});
// 拡張子指定
extend('ext', {...ext, message: '{_field_}が有効なファイル形式ではありません'});
// 文字数指定
extend('length', {...length, message: '{_field_}は{length}文字でなければなりません'});
// 数字桁数指定
extend('digits', {...digits, message: '{_field_}は{length}桁の半角数字で入力して下さい'});
// 文字のみ（記号数字不可）
extend('alpha', {...alpha, message: '{_field_}には数字記号を入力しないでください'});
// 数字のみ
extend('numeric', {...numeric, message: '{_field_}は数字で入力してください'});
// 本日以降（過去日付不可）
extend('futureDay', {
  message: '{_field_}は本日以降の日付を設定してください',
  validate(value) {
    return common.futureDayCheck(value);
  },
});
// 本日以前（未来日付不可）
extend('pastDay', {
  message: '{_field_}の設定が不正です',
  validate(value) {
    return common.pastDayCheck(value);
  },
});
// カタカナのみ
extend('furigana', {
  message: '{_field_}はカタカナで入力して下さい',
  validate(value) {
    const regex = /^[\u30A0-\u30FF]+$/;
    return regex.test(value);
  },
});
// 期間妥当性（開始が終了より前日付であること）
extend('periodValidity', {
  params: [
    {
      name: 'startDate',
      isTarget: true,
    }
  ],
  // message: '{_field_}は工期開始日（開始）以降の日付を設定して下さい',
  message: '{_field_}は{startDate}以降の日付を設定して下さい',
  validate(value, {startDate}) {
    return value >= startDate;
  },
});
// 期間妥当性（開始が終了より前日付であること）
extend('periodValidityBulk', {
  params: [
    {
      name: 'startDate',
      isTarget: true,
    }
  ],
  // message: '{_field_}は工期開始日（開始）以降の日付を設定して下さい',
  message: '{_field_}は開始日({startDate})以降の日付を設定して下さい',
  validate(value, {startDate}) {
    return value >= startDate;
  },
});
// 半角英数（大文字）のみ
extend('deviceId', {
  message: '{_field_}は半角英数(大文字)で入力して下さい',
  validate(value) {
    const regex = /^[0-9A-Z]+$/;
    return regex.test(value);
  },
});
// 半角のみ
extend('half', {
  message: '{_field_}は半角英数または記号で入力して下さい',
  validate(value) {
    const regex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/;
    return regex.test(value);
  },
});
extend('moveInCode', {
  message: '{_field_}は32文字の半角英数及びハイフンで入力して下さい',
  validate(value) {
    const regex = /^([0-9a-z!-]{36})+$/;
    return regex.test(value);
  },
});
//生年月日制限
extend('birthDay',{
  message:'{_field_}が不正です',
  validate(value){
    return common.birthDayCheck(value);
  },
});
// 郵便番号の有無確認
extend('addressFlg',{
  message: '{_field_}が不正です。実在する{_field_}を入力して下さい',
  validate(value) {
    return common.addressFlg(value);
  },
});

// 郵便番号との整合性確認
extend('checkPrefecture', {
  params: [
    {
      name: 'postalCode',
      isTarget: true,
    }
  ],
  message: '{_field_}が郵便番号（postalCode）と違います',
  validate(value, {postalCode}) {
    return common.checkPrefecture(value, postalCode);
  },
});

// 郵便番号との整合性確認
extend('checkCity',{
  params: [
    {
      name: 'postalCode',
      isTarget: true,
    }
  ],
  message: '{_field_}が郵便番号（postalCode）と違います',
  validate(value, {postalCode}) {
    return common.checkCity(value, postalCode);
  },
});

// ログインIDポリシー半角英数(大文字,小文字)、記号必須　字数制限は別途適用
extend('loginId', {
  message: '{_field_}は半角英数字と記号が使えます。',
  validate(value) {
    const regex = /^[a-zA-Z0-9@\.!#\$%&'\*\+\/=\?\^_`\{\|\}\~\-]+$/;
    return regex.test(value);
  },
});

// パスワードポリシー半角英数(大文字,小文字)、記号必須 字数制限は別途適用
extend('password', {
  message: '{_field_}は半角英数字と記号が使えます。'+'\n'+' 小文字、大文字、数字、記号を必ず含んでいる必要があります',
  validate(value) {
    // TODO: 「/」の指定方法
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;\|_~`])[a-zA-Z0-9\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;\|_~`]+$/;
    // const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[a-zA-Z0-9^$*.[]{}()?-"!@#%&/\,><':;|_~`]{8,64}$/;
    return regex.test(value);
  },
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
}
